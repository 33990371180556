
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'

const { REACT_APP_API_URL } = process.env

const graphql = ({
  token = '',
  uri = `${REACT_APP_API_URL}/api/graphql`,
}) => {
  return new ApolloClient({
    link: createHttpLink({
      uri,
      credentials: 'include',
      headers: { authorization: token?.length ? `Bearer ${token}` : '' },
    }),
    cache: new InMemoryCache(),
  })
}

export const client = {
  graphql,
}