import React, { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import CustomField from './customField'
import css from './styles.module.scss'

export default function CompanyRequirementForm({
  company,
  onSave
}) {
  const {
    register, control, handleSubmit, watch, setValue, formState: { errors },
  } = useForm()

  useEffect(() => {
    company?.requirements?.edges?.forEach((field) => {
      setValue(`${field.id}`, field.metadata?.value || null)
    })
  }, [company, setValue])

  return (
    <Form 
      key={`company${company.id}RequirementForm`}
      onSubmit={handleSubmit(onSave)}
    >
      {company?.requirements?.edges?.map((requirement) => (
        <Controller
          control={control}
          key={requirement.id}
          name={`${requirement.id}`}
          rules={{
            required: { value: requirement.required, message: 'Este campo é obrigatório' }
          }}
          render={({ field: { onChange, value } }) => (
            <CustomField 
              required={requirement.required}
              id={requirement.id}
              title={requirement.title}
              type={requirement.type}
              options={requirement.options}
              errors={errors[requirement.id]}
              onChange={onChange}
              value={value}
            />
          )}
        />
      ))}
      <Button type="submit">Salvar</Button>
    </Form>
  )
}