import React, {useEffect, useState} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../../layouts/default'
import { useParams, Link } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import { getCourseEnrolled } from '../../../http/course'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import Collapse from '../../../components/common/Collapse'
import CourseHeader from '../../../components/Course/Header'
import Partnerships from '../../../components/Partnerships'
import Day from '../../../components/Schedule/Day'
import { BsChevronRight } from 'react-icons/bs'
import { DateTime } from 'luxon'

const CourseContent = () => {
  const [course, setCourse ] = useState(null)
  const [loading, setLoading ] = useState(true)
  const params = useParams()
  
  if (!params.courseId)
    window.location.href = '/'

  const courseId = params.courseId
  const companyPermaLink = params.companyPermaLink

  const fetchCourse = () => {
    getCourseEnrolled(courseId).then(e => {
      setCourse(e)
      document.title = `${e.name} - Conteúdo do curso - Alissem`
      document.getElementsByTagName("META")[1].content=document.title
      setLoading(false)
    }).catch(e => {
      const { status } = e.response
      if (status === 401) {
        window.location.href = `/curso/${companyPermaLink}/${courseId}`
        return 
      }

      NotificationManager.error("Ocorreu um erro ao buscar o curso. Tente novamente")
    })
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  return (
    <LayoutDefault loading={loading} openTicket={true} companyId={course?.companyId} >
      {!loading && course && (
        <div className={css['cp-course-content']}>
          <CourseHeader course={{...course, name: `Conteúdo - ${course.name}`}}/>
          { (['pending', 'denied']).indexOf(course.documentApproved) > -1 ? 
            <div>
              {course.documentApproved === 'pending' &&
                <p className={css['cp-course-content__alert']}>
                  O seu documento ainda não foi aprovado. Aguarde liberação ou entre em contato.
                </p>
              }
              {course.documentApproved === 'denied' &&
                <p className={css['cp-course-content__error']}>
                  O seu documento não foi aprovado. Entraremos em contato.
                </p>
              }
            </div>
          : 
            <div>
              <p className={css['cp-course-content__buyed']}>Você está inscrito neste evento!</p>
              <Container>
                      
                <div className={css["collapsables"]}>
                  {course.certificate ? 
                    <Collapse title="Certificado" startOpen={course.certificate}>
                      <a href={course.certificate} target="blank">
                        Baixar certificado
                      </a>
                    </Collapse> 
                  : null}
                  {course.lessons?.length ?
                    <Collapse title="Aulas" startOpen={course.lessons}>
                      <ul className={css["cp-lessons"]}>
                        {course.lessons.map(lesson => {
                          let blocked = new Date(lesson.date) > new Date()
                          return (
                            <li key={lesson.id} className={`${css["lesson__item"]} ${css["blocked"]}`}>
                              { blocked ? 
                                <p>
                                  {lesson.name} 
                                  <span className={`${css["lesson__item__unblock"]}`}>
                                    Libera em {DateTime.fromISO(lesson.date).toFormat("dd/MM/yyyy 'às' HH:mm")}
                                  </span>
                                </p> :
                                <Link to={`/aula/${course.company.permalink}/${lesson.id}`}>
                                  {lesson.name} 
                                </Link>
                              }
                              <BsChevronRight />
                            </li>
                          )
                        })}
                      </ul>
                    </Collapse> : null
                  }	
                  {course.aditionalContent?.length ?
                    <Collapse title="Material Complementar" startOpen={course.aditionalContent}>
                      <ul className={css["cp-aditional-content"]}>
                        {course.aditionalContent.map(content => {
                          let blocked = new Date(content.date) > new Date()
                          return (
                            <li key={content.id} className={`${css["content__item"]} ${css["blocked"]}`}>
                              { blocked ? 
                                <p>
                                  {content.name} 
                                  <span className={`${css["content__item__unblock"]}`}>
                                    Libera em {DateTime.fromISO(content.date).toFormat("dd/MM/yyyy 'às' HH:mm")}
                                  </span>
                                </p> :
                                <Link to={`/complementar/${course.company.permalink}/${content.id}`}>
                                  {content.name} 
                                </Link>
                              }
                              <BsChevronRight />
                            </li>
                          )
                        })}
                      </ul>
                    </Collapse> : null
                  }
                </div>
              </Container>
            </div>
          }
          {course.speakers?.length ? 
            <div className={css["cp-schedule"]}>
              <span className={css["cp-schedule__decorator"]}></span>
              <Container>
                <h1>Programação</h1>
                { course.speakers.map((day, index) => <Day key={index} day={day} />) }
              </Container>
            </div>
          : null }
          {course.partnerships?.length ?
            <div>
              <Partnerships partnerships={course.partnerships} />
            </div>
            : null
          }	
        </div>
      )}
    </LayoutDefault>
  )
}

export default CourseContent