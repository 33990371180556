import React, { useState, useEffect } from 'react'
import LayoutDefault from '../../layouts/default'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { useForm } from 'react-hook-form'
import { validateDocs } from '../../http/enroll'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { FaDownload } from 'react-icons/fa'
import css from './styles.module.scss'

const Validate = () => {
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(null)

  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm()

  useEffect(() => {
    document.title = `Validar documentos - Alissem`
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const onSubmit = (data) => {
    setLoading(true)
    validateDocs(data).then(e => {
      setIsValid(e)
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
		<LayoutDefault loading={loading}>
      <div className={css['pg-validate']}>
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Control
                {...register('type', {
                  required: { value: true, message: 'O código é obrigatório' },
                })}
                isInvalid={errors.type}
                as="select"
              > 
                <option value="certificate">Certificado</option>
                <option value="recorrent">Associação</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.type?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                {...register('code', {
                  required: { value: true, message: 'O código é obrigatório' },
                  minLength: { value: 3, message: 'O código deve ter pelo menos 3 caracteres' },
                  maxLength: { value: 255, message: 'O código não pode ser maior que 255 caracteres' },
                })}
                placeholder="Digite a chave do documento"
                isInvalid={errors.code}
              />
              <Form.Control.Feedback type="invalid">{errors.code?.message}</Form.Control.Feedback>
            </Form.Group>
            <Button type="sumbit">Verificar</Button>
          </Form>
          {isValid ? 
            <div>
              {isValid.valid ? 
              <div className={`${css['cp-feedback']} ${css['cp-feedback--success']}`}>
                <FiCheckCircle />
                <div className={`${css['cp-feedback__content']}`}>
                  <h2>
                    { watch('type') === 'certificate' ? 
                      "Certificado válido" : 
                      "Associação válida"
                    }
                  </h2>
                  <p>Este documento está válido no nosso sistema e pode ser baixado para verificação</p>
                  <a href={isValid.link} target="_blank" rel="noreferrer">
                    Baixar agora <FaDownload />
                  </a>
                </div>
              </div>
              : 
              <div className={`${css['cp-feedback']}`}>
                <FiXCircle />
                <div className={`${css['cp-feedback__content']}`}>
                  <h2>{watch('type') === 'certificate' ? "Certificado não válido" : "Associação não válida"}</h2>
                  <p>Este documento não é reconhecido pelo sistema.</p>
                </div>
              </div>
              }
            </div>
            : null
          }
        </Container>
      </div>
    </LayoutDefault>
  )
}


export default Validate
