import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import CheckoutInfo from './Info'
import Boleto from './Boleto'
import Pix from './Pix'
import CreditCard from './CreditCard'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null

export default function Step3({ 
  product, 
  PagPayment,
  proccessPayment,
  setVoucher,
  documents,
  setLoading,
  infos,
  proccessFree,
  isSubscription,
  paymentGateway
}) {
  let paymentMethods = paymentGateway.paymentCompanyGatewayMethods
  const hasCreditCardOption = paymentMethods?.find(({paymentMethodId}) => paymentMethodId === 2)
  const hasBoletoOption = paymentMethods?.find(({paymentMethodId}) => paymentMethodId === 1) 
  const hasPixOption = paymentMethods?.find(({paymentMethodId}) => paymentMethodId === 3) 
  const [paymentMethod, setPaymentMethod] = useState(hasCreditCardOption ? 'creditCard' : ( hasBoletoOption ? 'boleto' : 'pix' ))
  const [installment, setInstallment] = useState(null)
  const [price, setPrice] = useState(null)

  useEffect(() => {
    setPrice(Number(isSubscription ? infos.plan.price : ( product.voucher?.value || product.price )))
  }, [isSubscription, infos, product])

  if (!product && !infos)
    return <></>  

  const handleOnClick = () => {
    proccessFree(isSubscription ? infos : product, documents)
  }

  let isRecorrent
  if (isSubscription) {
    isRecorrent = infos.plan.recorrent
  }

  return (
      <div className={css['cp-pay']}>
        <Row>
          <Col md={4} className="order-md-2">
            <CheckoutInfo 
              product={isSubscription ? infos : product} 
              installment={installment}
              setVoucher={setVoucher}
              logo={isSubscription ? infos.logo : `${server}${product.company.logoUrl}`}
              isSubscription={isSubscription}
              paymentMethod={
                isSubscription ? 
                  infos?.plan?.paymentCompanyGateway?.paymentGateway?.name : 
                  product?.company?.paymentCompanyGateways?.[0]?.paymentGateway?.name
              }
            />
          </Col>
          { price !== 0.00 ?
            <Col md={8} className="order-md-1">
              <div className={css['cp-pay__switcher']}>
                {hasCreditCardOption && (
                  <div 
                    className={`${css['cp-pay__switcher__option']} ${paymentMethod === 'creditCard' ? css['cp-pay__switcher__option--active'] : ''}`} 
                    onClick={() => setPaymentMethod('creditCard')}
                  >
                    <p>Cartão de crédito</p>
                  </div>
                )}
                {hasBoletoOption && !isRecorrent &&
                  <div
                    className={`${css['cp-pay__switcher__option']} ${paymentMethod === 'boleto' ? css['cp-pay__switcher__option--active'] : ''}`} 
                    onClick={() => setPaymentMethod('boleto')}
                  >
                    <p>Boleto</p>
                  </div>
                }
                {hasPixOption && !isRecorrent && (
                  <div 
                    className={`${css['cp-pay__switcher__option']} ${paymentMethod === 'pix' ? css['cp-pay__switcher__option--active'] : ''}`} 
                    onClick={() => setPaymentMethod('pix')}
                  >
                    <p>PIX</p>
                  </div>
                )}
              </div>
              <div className={css['cp-pay__method']}>
                {hasCreditCardOption && paymentMethod === 'creditCard' ? 
                  <CreditCard 
                    PagPayment={PagPayment}
                    price={price}
                    maxInstallmentNoInterest={isSubscription ? 0 : product.maxInstallmentNoInterest}
                    proccessPayment={proccessPayment}
                    setInstallment={setInstallment}
                    hasInstallment={!isRecorrent}
                    installmentsFromServer={isSubscription ? infos?.plan?.installments : product?.installments}
                    setLoading={setLoading}
                    paymentGateway={paymentGateway}
                  /> : null}
                  {hasBoletoOption && paymentMethod === 'boleto' ? <Boleto proccessPayment={proccessPayment}/> : null}
                  {hasPixOption && paymentMethod === 'pix' ? <Pix proccessPayment={proccessPayment}/> : null}
              </div>
            </Col> : 
            <Col md={8} className="order-md-1">
              <div className={css['cp-pay__method']}>
                <Button type="submit" variant="success" onClick={handleOnClick}>Finalizar inscrição</Button>
              </div>
            </Col>
          }
        </Row>
      </div>
  )
}