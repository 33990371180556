import React, {useEffect, useState} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../layouts/default'
import Container from 'react-bootstrap/Container'

const Home = () => {
	const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.title = `Termos e serviços - Allisem`
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

	return (
		<LayoutDefault loading={loading}>
			{!loading && (
				<div className={css['cp-terms']}>
					<Container>
              <h1>Política de Privacidade e Termos de uso</h1>

              <p>A plataforma Alissem é gerenciada por ALISSEM COMUNICAÇÃO E PLANEJAMENTOS LTDA. Inscrita no CNPJ sob o número 12.152.133/0001-62 e tem por finalidade a gestão de cursos e eventos de empresas REALIZADORAS, contratantes do serviços de gestão de cursos. </p>

              <h2>Termos de Uso</h2>

              <h3>1. Contas</h3>
              <p>&Eacute; necess&aacute;rio ter uma conta para realizar a maioria das atividades em nossa plataforma, inclusive para matricular-se em um curso. Seu cadastro d&aacute; acesso ao conte&uacute;do gratuito das empresas REALIZADORAS contratantes da plataforma, sendo necess&aacute;ria a aquisi&ccedil;&atilde;o dos conte&uacute;dos pagos para a sua libera&ccedil;&atilde;o. </p>
              <p>O usu&aacute;rio dever&aacute; manter a senha em lugar seguro, pois ela &eacute; respons&aacute;vel por todas as atividades associadas &agrave; sua conta. Caso suspeite que algu&eacute;m esteja usando sua conta, cabe ao usu&aacute;rio nos informar ou entrar em contato com a equipe de suporte.</p>
              <p>Para configurar e para manter a conta, o usu&aacute;rio dever&aacute; fornecer e continuar fornecendo informa&ccedil;&otilde;es corretas e completas, inclusive um endere&ccedil;o de e-mail v&aacute;lido. O usu&aacute;rio &eacute; totalmente respons&aacute;vel por sua conta e por tudo o que nela acontecer, inclusive por quaisquer preju&iacute;zos ou danos (&agrave; ALISSEM ou empresas REALIZADORAS contratantes ou a qualquer outra pessoa) causados por algu&eacute;m que use a conta sem sua permiss&atilde;o. Isto significa que cabe ao usu&aacute;rio ter cuidado com sua pr&oacute;pria senha. &Eacute; proibido transferir a conta para outra pessoa e usar a conta de outra pessoa sem a sua permiss&atilde;o. Ao entrar em contato conosco para solicitar acesso a uma conta, tal acesso s&oacute; ser&aacute; concedido se fornecidas as informa&ccedil;&otilde;es das credenciais de login da conta em quest&atilde;o. Em caso de falecimento do usu&aacute;rio, a conta ser&aacute; encerrada.</p>

              <h3>2. Inscri&ccedil;&atilde;o em cursos e Associa&ccedil;&otilde;es</h3>
              <p>Ao inscrever-se em um curso ou associar-se a uma das empresas REALIZADORAS contratante da plataforma, o usu&aacute;rio receber&aacute; um contato da ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA. por email. </p>
              <p>&Eacute; proibido transferir ou revender cursos por qualquer meio.</p>
              <p>Em termos legais e mais abrangentes, a ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA concede ao usu&aacute;rio (como aluno) um acesso limitado, n&atilde;o exclusivo e intransfer&iacute;vel para acessar, visualizar ou participar dos cursos e conte&uacute;dos associados cujas taxas, quando necess&aacute;rias, tenham sido pagas.</p>
              <p>&Eacute; proibido reproduzir, redistribuir, transmitir, ceder, vender, transmitir por r&aacute;dio ou televis&atilde;o, alugar, compartilhar, emprestar, modificar, adaptar, editar, criar obras derivadas, sublicenciar ou, de alguma forma, transferir ou usar qualquer curso, salvo mediante permiss&atilde;o expl&iacute;cita, em acordo escrito, assinado por um representante autorizado da ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA. Esta condi&ccedil;&atilde;o se aplica tamb&eacute;m a qualquer conte&uacute;do que possa ser acessado por meio de qualquer uma de nossas plataformas.</p>


              <h3>3. Pagamentos, cr&eacute;ditos e reembolsos</h3>
              <p>Os pre&ccedil;os dos cursos s&atilde;o determinados pelas empresas contratantes e n&atilde;o cabe &agrave; ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA. responsabilidade sobre valores, conte&uacute;dos, prazos e cancelamentos. </p>
              <p>Os valores praticados pelas EMPRESAS REALIZADORAS a um curso ou evento ser&aacute; o pre&ccedil;o no momento em que o usu&aacute;rio concluir a compra (na finaliza&ccedil;&atilde;o da compra). Qualquer pre&ccedil;o oferecido por determinado curso/evento poder&aacute; tamb&eacute;m ser diferente, quando o usu&aacute;rio estiver conectado &agrave; sua conta, do pre&ccedil;o dispon&iacute;vel para usu&aacute;rios que n&atilde;o s&atilde;o cadastrados ou n&atilde;o est&atilde;o conectados, pois algumas promo&ccedil;&otilde;es est&atilde;o dispon&iacute;veis apenas para usu&aacute;rios espec&iacute;ficos a serem definidos pelas empresas REALIZADORAS contratantes dos servi&ccedil;os de gest&atilde;o de cursos e associa&ccedil;&otilde;es.</p>
              
              <h4>3.2 Pagamentos</h4>
              <p>A plataforma ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA &eacute; &uacute;nica e exclusivamente para gest&atilde;o e exibi&ccedil;&atilde;o de cursos e eventos, n&atilde;o sendo respons&aacute;vel pelo recebimento das transa&ccedil;&otilde;es banc&aacute;rias. </p>
              <p>Os pagamentos dos cursos e eventos na plataforma da ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA s&atilde;o feitos diretamente no ambiente virtual da plataforma do PAGSEGURO, n&atilde;o passando portanto por esta plataforma dados banc&aacute;rios, de cart&otilde;es e outras formas de pagamento. </p>
              <p>Problemas com rela&ccedil;&atilde;o aos pagamentos ou quaisquer falhas de seguran&ccedil;a em transa&ccedil;&otilde;es financeiras s&atilde;o de responsabilidade da plataforma de pagamento e dever&atilde;o ser relatadas diretamente ao PAGSEGURO, &uacute;nica respons&aacute;vel pela transa&ccedil;&atilde;o financeira. </p>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA trabalha com parceiros de processamento de pagamentos terceirizados para oferecer ao usu&aacute;rio os m&eacute;todos de pagamento mais convenientes poss&iacute;veis no pa&iacute;s em que residem e para manter a seguran&ccedil;a das informa&ccedil;&otilde;es de pagamento do usu&aacute;rio. Consulte nossa Pol&iacute;tica de Privacidade para obter mais detalhes.</p>
              <p>Se o m&eacute;todo de pagamento falhar e, mesmo assim, o usu&aacute;rio conseguir acessar o curso no qual est&aacute; se inscrevendo, o usu&aacute;rio concorda em nos pagar as taxas correspondentes no prazo de 30 (trinta) dias a contar do recebimento de nossa notifica&ccedil;&atilde;o. Reservamo-nos o direito de desativar ou bloquear o acesso a qualquer curso cujo pagamento n&atilde;o tenha sido devidamente efetuado.</p>
              
              <h4>3.3 Reembolsos e cr&eacute;ditos</h4>
              <p>Os reembolsos e cr&eacute;ditos em rela&ccedil;&atilde;o &agrave; aquisi&ccedil;&atilde;o de qualquer curso ou evento que esteja na plataforma da ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA, seguir&aacute; a pol&iacute;tica de cancelamento adotada pela empresa REALIZADORA contratante da plataforma. </p>


              <h3>4. Regras sobre conte&uacute;do e comportamento</h3>
              <p>&Eacute; proibido acessar ou usar os Servi&ccedil;os ou criar uma conta para fins il&iacute;citos. O uso dos Servi&ccedil;os e o comportamento em nossa plataforma ou local f&iacute;sico do curso, por parte do usu&aacute;rio, deve estar em conformidade com as leis ou regulamenta&ccedil;&otilde;es locais ou nacionais aplic&aacute;veis do pa&iacute;s em que reside. O usu&aacute;rio &eacute; exclusivamente respons&aacute;vel pelo conhecimento e cumprimento das leis e regulamenta&ccedil;&otilde;es que lhe sejam aplic&aacute;veis.</p>
              <p>&Eacute; proibido ao usu&aacute;rio publicar ou enviar conte&uacute;do que n&atilde;o lhe perten&ccedil;a.</p>
              <br />
              <br />

              <h2>Pol&iacute;tica de Privacidade</h2>
              <p>Obrigado por participar da plataforma de cursos e eventos da ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA.&nbsp; A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA respeita a privacidade dos usu&aacute;rios e deseja que todos entendam como s&atilde;o obtidos, utilizados e compartilhados os dados dos usu&aacute;rios. Esta Pol&iacute;tica de Privacidade abrange nossas pr&aacute;ticas de coleta de dados e descreve os direitos do usu&aacute;rio de acessar, corrigir ou restringir o uso de seus dados pessoais por parte da Empresa Alissem e empresa REALIZADORA contratante da plataforma.</p>
              <p><strong>Ao usar os Servi&ccedil;os, o usu&aacute;rio concorda com os termos desta Pol&iacute;tica de Privacidade.</strong>&nbsp;N&atilde;o use os Servi&ccedil;os caso n&atilde;o concorde com esta Pol&iacute;tica de Privacidade ou qualquer outro acordo que reja o uso dos Servi&ccedil;os por parte do usu&aacute;rio.</p>
              <p>Para fins desta Pol&iacute;tica de Privacidade, &ldquo;USU&Aacute;RIOS&rdquo; s&atilde;o todos os indiv&iacute;duos que de qualquer forma utilizam a plataforma.</p>


              <h3>1. Tipos de informa&ccedil;&otilde;es coletadas, finalidade da coleta e prazo de armazenamento</h3>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA poder&aacute; coletar as seguintes informa&ccedil;&otilde;es b&aacute;sicas para identifica&ccedil;&atilde;o dos USU&Aacute;RIOS (&ldquo;Dados Pessoais&rdquo;): nome, CPF, idade, endere&ccedil;o, endere&ccedil;o de e-mail, telefone e CRMV (quando possuir). </p>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA apenas coleta as informa&ccedil;&otilde;es fornecidas pelos USU&Aacute;RIOS para a execu&ccedil;&atilde;o dos seus servi&ccedil;os ou conforme estabelecido nesta Pol&iacute;tica de Privacidade. De modo geral, utilizamos os seus Dados Pessoais para disponibilizar conte&uacute;do relevante ao USU&Aacute;RIO e enviar:</p>
              <ul>
                <li>
                  <p>Divulga&ccedil;&atilde;o de cursos/eventos</p>
                </li>
                <li>
                  <p>Comunicados de renova&ccedil;&atilde;o de anuidades</p>
                </li>
                <li>
                  <p>Pesquisas de feedback de clientes</p>
                </li>
                <li>
                  <p>Notifica&ccedil;&otilde;es do sistema</p>
                </li>
              </ul>
              <p>Periodicamente, poderemos solicitar a atualiza&ccedil;&atilde;o de seus dados de cadastro em nossa base de relacionamento, incluindo dados como nome, e-mail, telefone, e endere&ccedil;o f&iacute;sico.</p>
              <p>Exceto nos casos em que (i) formos obrigados por Lei, (ii) seja necess&aacute;rio para fins de prestar um servi&ccedil;o ao USU&Aacute;RIO ou (iii) seja necess&aacute;rio para exercer ou defender direitos da ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA, a ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA n&atilde;o utilizar&aacute; seus Dados Pessoais sem antes obter o seu consentimento. Ademais, a ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA n&atilde;o comercializa as informa&ccedil;&otilde;es coletadas em sua plataforma.</p>
              <p>Entretanto, os dados ser&atilde;o de acesso apenas da empresa REALIZADORA do evento contratante da plataforma, ao qual o usu&aacute;rio adquiriu com o objetivo de executar as finalidades acima descritas. </p>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA n&atilde;o divulga a quaisquer terceiros as informa&ccedil;&otilde;es pessoais fornecidas pelo USU&Aacute;RIO. No entanto, caso seja solicitado por uma ordem expedida por autoridade competente no cumprimento de suas atribui&ccedil;&otilde;es legais, ou em caso de viola&ccedil;&otilde;es ou suspeita de viola&ccedil;&otilde;es desta Pol&iacute;tica de Privacidade ou da Lei, poder&aacute; ser requerido &agrave; ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA disponibilizar as informa&ccedil;&otilde;es pessoais que estiverem armazenadas.</p>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA, por&eacute;m, se compromete a revelar as informa&ccedil;&otilde;es se limitando ao m&iacute;nimo necess&aacute;rio para atingir as finalidades exigidas.</p>
              <p>As informa&ccedil;&otilde;es pessoais coletadas pela ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA ser&atilde;o mantidas por per&iacute;odo indeterminado at&eacute; que atingida a finalidade pela qual os dados foram coletados, respeitado o exerc&iacute;cio ao direito de exclus&atilde;o do USU&Aacute;RIO previsto no item 5.</p>

              <h3>2. Dados de navega&ccedil;&atilde;o e cookies</h3>
              <p>O acesso &agrave; plataforma implica coleta de informa&ccedil;&otilde;es que podem identificar direta ou indiretamente o USU&Aacute;RIO, tais como o endere&ccedil;o de IP utilizado, sua localiza&ccedil;&atilde;o geogr&aacute;fica aproximada, tempo de visita e outras informa&ccedil;&otilde;es agregadas.</p>
              <p>Cookies s&atilde;o pequenos trechos de texto usados para armazenar informa&ccedil;&otilde;es (por exemplo, seu idioma de prefer&ecirc;ncia) em navegadores da Internet, podendo estas informa&ccedil;&otilde;es serem retransmitidas &agrave; ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA em sua pr&oacute;xima visita &agrave; plataforma.</p>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA utiliza cookies para ajudar a identificar o USU&Aacute;RIO ap&oacute;s o seu primeiro acesso &agrave; plataforma. Em geral, os navegadores ou dispositivos utilizados para acessar a plataforma possibilitam modificar a configura&ccedil;&atilde;o para que se opte pela defini&ccedil;&atilde;o de cookies ou n&atilde;o, bem como pela exclus&atilde;o dos cookies existentes, sendo essas configura&ccedil;&otilde;es uma faculdade do USU&Aacute;RIO.</p>

              <h3>3. Google Analytics</h3>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA utiliza os servi&ccedil;os de an&aacute;lise web da ferramenta Google Analytics, desenvolvida por Google Inc. (&ldquo;Google&rdquo;).</p>
              <p>O Google Analytics analisa o uso do SITE por meio de cookies, coletando informa&ccedil;&otilde;es como o URL de refer&ecirc;ncia, o tipo de navegador do USU&Aacute;RIO, as configura&ccedil;&otilde;es de idioma, o sistema operacional, a resolu&ccedil;&atilde;o da tela etc. Tais informa&ccedil;&otilde;es s&atilde;o transmitidas a um servidor do Google nos EUA, onde ser&atilde;o armazenados e analisados. Os resultados dessa an&aacute;lise ser&atilde;o disponibilizados a ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA, por&eacute;m, de forma anonimizada, ou seja, sem que se torne poss&iacute;vel a identifica&ccedil;&atilde;o do USU&Aacute;RIO. A Google &eacute; certificada sob o Privacy Shield UE-EUA, que garante um n&iacute;vel adequado de prote&ccedil;&atilde;o de dados em rela&ccedil;&atilde;o ao processamento de dados pela Google nos EUA.</p>
              <p>Para mais informa&ccedil;&otilde;es sobre o Google Analytics, recomenda-se que o USU&Aacute;RIO acesse os Termos de Uso do Google Analytics, as Diretrizes de Privacidade e Prote&ccedil;&atilde;o de Dados do Google Analytics e a Pol&iacute;tica de Privacidade do Google.</p>
              <p>&Eacute; poss&iacute;vel revogar o seu consentimento para o uso dos servi&ccedil;os de an&aacute;lise da web a qualquer momento, baixando e instalando o Plugin do navegador da Google.</p>

              <h3>4. Transfer&ecirc;ncia de dados</h3>
              <p>Os Dados Pessoais coletados pela ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA ser&atilde;o armazenados em um servi&ccedil;o de nuvem vinculado Servidor Vultr da empresa The Constant Company, LLC. Em atendimento ao art. 33 da Lei n&ordm; 13.709/2018, ressalta-se que a Vultr est&aacute; sujeita aos seus Termos dos Servi&ccedil;os Online, oferecendo padr&otilde;es de seguran&ccedil;a similares aos aplicados pela ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA em rela&ccedil;&atilde;o aos Dados Pessoais.</p>

              <h3>5. Corre&ccedil;&atilde;o, atualiza&ccedil;&atilde;o e exclus&atilde;o de dados pessoais</h3>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA possibilita ao USU&Aacute;RIO a garantia de que os seus Dados Pessoais estejam atualizados e precisos.&nbsp;<strong>Conforme permitido pela legisla&ccedil;&atilde;o aplic&aacute;vel, o USU&Aacute;RIO poder&aacute; solicitar acesso, corre&ccedil;&atilde;o, atualiza&ccedil;&atilde;o ou exclus&atilde;o, a qualquer tempo, de seus Dados Pessoais por meio de envio de e-mail ao endere&ccedil;o contato@alissem.com.br que ser&aacute; analisado e cumprido.</strong></p>
              <p>Caso opte por n&atilde;o mais receber e-mails peri&oacute;dicos a respeito dos eventos das empresas REALIZADORAS contratantes da plataforma da ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA, o USU&Aacute;RIO tamb&eacute;m pode utilizar o endere&ccedil;o&nbsp;<strong>contato@alissem.com.br</strong>&nbsp;para enviar um e-mail informando o desejo de interromper o recebimento de tais informa&ccedil;&otilde;es. A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA atender&aacute; o pedido e n&atilde;o mais enviar&aacute; ao USU&Aacute;RIO tais comunica&ccedil;&otilde;es, sendo poss&iacute;vel modificar esta sua op&ccedil;&atilde;o a qualquer tempo. Nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel, e mediante solicita&ccedil;&atilde;o enviada ao endere&ccedil;o&nbsp;<strong>contato@alissem.com.br&nbsp;</strong>a ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA poder&aacute; fornecer ao USU&Aacute;RIO uma c&oacute;pia de todos os Dados Pessoais que temos em nosso controle.</p>

              <h3>6. Seguran&ccedil;a</h3>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA se compromete a manter seus Dados Pessoais em seguran&ccedil;a, empregando sistemas de seguran&ccedil;a e procedimentos t&eacute;cnicos, f&iacute;sicos e gerenciais usualmente adotados pelo mercado para tanto, tais como o certificado de seguran&ccedil;a SSL, a fim de impedir acessos n&atilde;o autorizados por terceiros.</p>

              <h3>7. Sites de terceiros</h3>
              <p>Com o intuito de oferecer um recurso adicional ao USU&Aacute;RIO, a ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA poder&aacute; fornecer links para outros sites na Internet. A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA n&atilde;o se responsabiliza por esses sites e conte&uacute;dos e n&atilde;o compartilha, subscreve, monitora, v&aacute;lida ou aceita a forma como esses sites ou ferramentas de armazenamento de conte&uacute;do coletam, processam e transferem suas informa&ccedil;&otilde;es pessoais e privadas. Recomendamos que o USU&Aacute;RIO consulte as respectivas pol&iacute;ticas de privacidade de tais sites para se informar adequadamente a respeito do uso de suas informa&ccedil;&otilde;es pessoais por outros sites ou outras ferramentas.</p>
  
              <h3>8. Altera&ccedil;&otilde;es a esta Pol&iacute;tica de Privacidade</h3>
              <p>A ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA pode modificar essa Pol&iacute;tica de Privacidade, especialmente considerando que os requisitos de prote&ccedil;&atilde;o de informa&ccedil;&otilde;es pessoais podem evoluir, assim como os nossos procedimentos internos. O USU&Aacute;RIO dever&aacute; checar essa p&aacute;gina periodicamente para garantir que concorda com as eventuais modifica&ccedil;&otilde;es. Ao continuar a usar a plataforma, o USU&Aacute;RIO concorda em estar vinculado a esta Pol&iacute;tica de Privacidade conforme alterada. Caso as altera&ccedil;&otilde;es a essa Pol&iacute;tica de Privacidade sejam significativas, poderemos solicitar um consentimento adicional. Em nenhum caso utilizaremos suas informa&ccedil;&otilde;es de forma inconsistente com seu consentimento.</p>
              
              <h3>9. Consentimento</h3>
              <p><strong>A utiliza&ccedil;&atilde;o deste SITE e o fornecimento das informa&ccedil;&otilde;es especificadas na cl&aacute;usula 1 acima &eacute; feita mediante o CONSENTIMENTO DO USU&Aacute;RIO aos termos da presente Pol&iacute;tica de Privacidade</strong>. Especificamente quanto ao tratamento dos Dados Pessoais coletados, o consentimento se dar&aacute; por meio de confirma&ccedil;&atilde;o do usu&aacute;rio no momento do fornecimento desses dados. O consentimento n&atilde;o poder&aacute; ser feito por menores de 18 anos, caso em que ser&aacute; considerado inv&aacute;lido at&eacute; confirma&ccedil;&atilde;o pelo respectivo respons&aacute;vel legal.</p>
              
              <h3>10. Prote&ccedil;&atilde;o dos dados pessoais coletados</h3>
              <p>Caso o USU&Aacute;RIO tenha d&uacute;vidas ou queira maiores informa&ccedil;&otilde;es sobre os dados pessoais coletados, ALISSEM COMUNICA&Ccedil;&Atilde;O E PLANEJAMENTOS LTDA estar&aacute; pronta a atend&ecirc;-lo por meio do e-mail&nbsp;<u><a href="mailto:contato@alissem.com.br"><strong>contato@alissem.com.br</strong></a></u><strong> ou telefones (15) 3353-5735 / (15) 99833-3633</strong></p>
              <p>Essa Pol&iacute;tica de Privacidade entra em vigor em 01 de janeiro de 2022. </p>
					</Container>
				</div>				
			)}
		</LayoutDefault>
	)
}

export default Home