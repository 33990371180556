import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { updateEmail } from '../../../../http/email'
import css from './styles.module.scss'
import EmailEditor from 'react-email-editor'


export default function ModalEmail({ show, handleClose, currentEmail, setLoading }) {
  const {
    register, handleSubmit, setValue, reset, formState: { errors },
  } = useForm()

  const [loaded, setLoaded] = useState(false)

  const emailEditorRef = useRef(null)

  useEffect(() => {
    const fields = ['subject']
    if (currentEmail.id) {
      fields.forEach((field) => setValue(field, currentEmail[field]))
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [currentEmail, setValue])

  const verifyVariables = (html) => {
    const variablePattern = new RegExp(/\{\{.*?\}\}/gi)
    const matches = html.matchAll(variablePattern)
    const variables = JSON.parse(currentEmail.variables)

    for (const match of matches) {
      let variable = match[0].replace("{{", "").replace("}}", "")
      if (!variables){
        NotificationManager.error(`Variável {{${variable}}} não suportada`)
        setLoading(false)
        return 'error';
      }

      let found = variables.findIndex(({name}) => name === variable)
      if (found === -1) {
        NotificationManager.error(`Variável {{${variable}}} não suportada`)
        setLoading(false)
        return 'error';
      }
      variables[found].hasInBody = true
    }

    return variables.map(({name, required, hasInBody}) => {
      if (required && !hasInBody)
        return `{{${name}}}`
      return null
    }).filter(item => item)
  }

  const exportHtml = (callback = () => {}, dataForm = null) => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      const final = verifyVariables(html)
      if (final === 'error') return 
      if(!final || final.length === 0)
        return callback(dataForm, design, html)
      setLoading(false)
      NotificationManager.error(`${final.join(",")} não presente no corpo do email`)
    })
  }

  const onLoad = () => {
    setTimeout(() => {
      emailEditorRef.current.editor.loadDesign(JSON.parse(currentEmail.body))
      setLoaded(true)
    }, 1000)
  }

  const onSubmit = async (data, design, html) => {
    setLoading(true)
    let result = null

    if (currentEmail.id) result = updateEmail(currentEmail.id, {
      subject: data.subject,
      body: JSON.stringify(design),
      html: html
    })
    //else result = saveEmail(formData)

    result.then(e => {
      NotificationManager.success('O email foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }).finally(e => {
      setLoading(false)
    })
  }


  const handleSave = (data) => {
    setLoading(true)
    exportHtml(onSubmit, data)    
  }

  const close = () => {
    reset()
    handleClose()
  }

  return (
    <Modal
      show={true}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['modal-dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar email</Modal.Title>
      </Modal.Header>
      <div className={css["onlyMobile"]}>
        <Modal.Body>
          Não suportado nessa resolução.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Fechar
          </Button>
        </Modal.Footer>
      </div>
      <Form onSubmit={handleSubmit(handleSave)} className={css["onlyDesktop"]}>
        <Modal.Body>
        <Form.Group className="mb-3">
            <Form.Control
              {...register('subject', {
                required: { value: true, message: 'O assunto é obrigatório' },
                minLength: { value: 3, message: 'O assunto deve ter pelo menos 3 caracteres' },
                maxLength: { value: 255, message: 'O assunto não pode ser maior que 255 caracteres' },
              })}
              placeholder="Assunto"
              isInvalid={errors.subject}
            />
            <Form.Control.Feedback type="invalid">{errors.subject?.message}</Form.Control.Feedback>
          </Form.Group>
          {currentEmail.variables &&
            <div className={css["body-variables"]}>
              <h3>Variáveis para o conteúdo do email</h3>
              <table className={css["body-variables__content"]} >
                {JSON.parse(currentEmail.variables)?.map(variable => (
                  <tr className={css["variable"]}>
                    <td className={css["variable__var"]}>
                      {`{{${variable.name}}}`} 
                      {variable.required ? <span  className={css["variable--required"]}>*</span> : ''}
                    </td>
                    <td className={css["variable__description"]}>{variable.description}</td>
                  </tr>
                ))}
              </table>
            </div>
          }
          <EmailEditor ref={emailEditorRef} onLoad={onLoad} options={{locale: 'pt-BR'}} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">Salvar</Button>
        </Modal.Footer>
      </Form>

    </Modal>
  )
}
