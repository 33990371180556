import React, { useEffect, useState, useCallback } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { isAuthenticated } from '../../../services/auth'
import { NotificationManager } from 'react-notifications'
import LoadLibrary from '../../../util/LoadLibrary'
import Payment from '../Payment'
import PaymentResult from '../PaymentResult'
import css from '../styles.module.scss'
import Loader from '../../../components/common/Loader'
import { proccessSubscription } from '../../../http/payment'
import SendDocument from '../SendDocument'
import { getMyRequirements } from '../../../http/requirement'
import CompleteMetaData from '../CompleteMetaData'


export default function ModalCheckoutSubscription({ show, handleClose, plan, sessionId, paymentGateway }) {
  const logged = isAuthenticated()
  const [loading, setLoading] = useState(false)
  const [paymentLoaded, setPaymentLoaded] = useState(false)
  const [PagPayment, setPagPayment] = useState(null)
  const [incompleteFields, setIncompleteFields] = useState([])
  const [step, setStep] = useState(1)
  const [response, setResponse] = useState(null)
  const [documents, setDocuments] = useState(null)


	if (!logged){
    // redirect
  }


  const proccessPayment = async (hash, data) => {
    try {      
      let formData = new FormData()
      for ( let key in data ) {
        formData.append(key, data[key]);
      }
      formData.append("planId", plan.plan.id)
      formData.append("companyId", plan.company.id)
      formData.append("sessionId", hash)
      
      if (plan.plan.sendDocument)
        formData.append("documentFile", documents)
      
      let proccessResult = await proccessSubscription(formData)

      setResponse(proccessResult)
      setStep(3)
    } catch (error) {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)

  }

  const getPaymentMethods = useCallback(() => {
    const amount = Number(plan.price).toFixed(2)
    if (amount > 0) 
      return new Promise ((resolve, reject) =>
        PagPayment.getPaymentMethods({
          amount,
          success: response => resolve(response),
          error: response => { 
            NotificationManager.error('Ocorreu um erro na comunicação com o PagSeguro. Tente novamente!')
            reject(response)
          }
        })
      )
  }, [plan, PagPayment])

  
  useEffect(() => {
    if (paymentGateway?.paymentGatewayId && paymentLoaded && !PagPayment) {
      // eslint-disable-next-line no-undef
      setPagPayment(PagSeguroDirectPayment)
    }
    if (paymentGateway?.paymentGatewayId && paymentLoaded && sessionId && PagPayment) {
      PagPayment.setSessionId(sessionId)
    }
  }, [paymentLoaded, PagPayment, sessionId])

  useEffect(() => {
    if (show) {
      fetchIncompleteRequirements()
      if (sessionId && paymentGateway && paymentGateway.paymentGatewayId === 1) {
        getPaymentMethods()
      }
    }
  }, [show, sessionId, getPaymentMethods, paymentGateway])

  const proccessFree = async (infos) => {
    try {
      let formData = new FormData()
      formData.append("planId", infos.plan.id)
      formData.append("companyId", infos.company.id)
      
      if (infos?.plan?.sendDocument)
        formData.append("documentFile", documents)
      
      await proccessSubscription(formData)
      window.location.reload()
      return
    } catch (e) {
      console.error(e)
      NotificationManager.error("Ocorreu um erro ao processar a sua associação. Tente novamente")
    }
  }

  const sendPayment = async (data) => {
    if (paymentGateway.paymentGatewayId === 1){
      if (!PagPayment){
        NotificationManager.error('PagSeguro não iniciado. Tente novamente!')
        return false
      }
      setLoading(true)
      PagPayment.onSenderHashReady(async function(response){
        if(response.status === 'error') {
          NotificationManager.error('Ocorreu um erro na comunicação com o PagSeguro. Tente novamente!')
          setLoading(false)
          return false;
        }
        var hash = response.senderHash; //Hash estará disponível nesta variável.
        proccessPayment(hash, {...data, ...plan})
      })
    } else {
      proccessPayment(null, {...data, ...plan})
    }
  }

  const close = () => {
    if (step === 3){
      window.location.reload()
      return
    }
    handleClose()
  }

  const fetchIncompleteRequirements = async () => {
    try{
      setLoading(true)
      const incompleteRequirements = await getMyRequirements(plan.company.id, true)
      if (!incompleteRequirements?.incompleteFields?.length) {
        setStep(plan?.plan?.sendDocument ? 1 : 2)
        setLoading(false)
        return
      }
      setIncompleteFields(incompleteRequirements)
      setStep(0)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor ao buscar requisitos', 'Ocorreu um erro')
    }
  }

  const nextStep = () => {
    setStep((prevStep) => {
      if(!plan?.plan?.sendDocument && prevStep == 0){
        return 2
      } 
      return prevStep + 1
    })
  }
  
  return (
    <LoadLibrary setPayment={setPaymentLoaded}>
      
      {loading ?
        <Loader /> : null
      }
        <Modal
          show={show}
          onHide={close}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          dialogClassName={css['dialog']}
        >
          <Modal.Header closeButton>
            <Modal.Title>Finalizar compra</Modal.Title>
          </Modal.Header>
          {//<Form onSubmit={handleSubmit(onSubmit)}>
          }
            <Modal.Body>
              {step === 0 &&
                <CompleteMetaData 
                  setLoading={setLoading}
                  companyIncomplete={incompleteFields}
                  nextStep={nextStep}
                />
              }
              {step === 1 && 
                <SendDocument 
                  description={plan?.plan?.documentDescription}
                  setDocuments={setDocuments}
                  documents={documents}
                  nextStep={nextStep}
                />
              }
              {step === 2 && 
                <Payment
                  paymentGateway={paymentGateway}
                  PagPayment={PagPayment}
                  infos={plan}
                  proccessPayment={sendPayment}
                  setLoading={setLoading}
                  isSubscription={true}
                  proccessFree={proccessFree}
                />
              }
              {step === 3 && 
                <PaymentResult infos={plan} response={response}
              />}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                { step === 3 ? "Fechar" : "Cancelar"}
              </Button>
              { step < 0 && <Button type="submit" variant="success">Próximo</Button> }
            </Modal.Footer>
          {//</Form>
          }
        </Modal>
      
    </LoadLibrary>
  )
}
