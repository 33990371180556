import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useForm } from 'react-hook-form'
import { createAccount } from '../../../http/auth'
import { login as serviceLogin, redirect } from '../../../services/auth'
import SHA256 from "crypto-js/sha256"
import css from './styles.module.scss'
import { useLocation, Link } from "react-router-dom"
import UserDataForm from '../../common/UserDataForm'

const SignIn = ({ setLoading, setOpen }) => {
  const {
    register, handleSubmit, setError, control, watch, formState: { errors },
  } = useForm()
  const { search } = useLocation()

  const onSubmit = (data) => {
    setLoading(true)
    createAccount({
      ...data,
      cpf: data.cpf?.replace(/(\.|-)/g, ''),
      postalCode: data.cep.replace(/-/g, ''),
      cellphone: data.cellphone.replace(/(\(|\)|-|\s)/g, ''),
      password: SHA256(data.password).toString(),
    })
    .then( res => {
      if(res.data && res.data.auth){
        const {token, user} = res.data
        serviceLogin(token, user)
        redirect(search, user.role)
      }
    }).catch( e => {
      if (e.response?.status === 422){
        if (e.response?.data?.error?.errCode === 'user_exists'){
          setError('cpf', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
          setError('email', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: false })
        }
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className={css["cp-signin"]}>
      <h2>Novo Cadastro</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UserDataForm
          register={register}
          errors={errors}
          control={control}
          watch={watch} 
        />
        <p>
          Ao clicar em Enviar você concorda com os nossos 
          <Link to={{ pathname:`/termos` }}>
            Termos e Condições
          </Link>
        </p>
        <div className={`d-block d-md-none ${css["button"]}`} onClick={setOpen}>
          <p>Voltar</p>
        </div>
        <Button type="submit">Enviar</Button>
      </Form>
    </div>
  )
}

export default SignIn
