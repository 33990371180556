import React, { useEffect, useState } from 'react'
import css from './styles.module.scss'
import Header from '../components/common/Portal/Header'
import Footer from '../components/common/Portal/Footer'
import Loader from '../components/common/Loader'
import OpenTicket from '../components/common/OpenTicket'
import { isAuthenticated, getUser } from '../services/auth'
import {NotificationContainer} from 'react-notifications'
import CompleteDataModal from '../components/Modal/CompleteData'
import CompleteMetaDataModal from '../components/Modal/CompleteMetaDataModal'
import { useLocation } from 'react-router-dom'
import { getMyRequirements } from '../http/requirement'

const LayoutDefault = ({children, loading = true, openTicket = false, companyId = null}) => {

  const user = getUser()
  const [loadingInternal, setLoadingInternal] = useState(loading)
  const [openCompleteModal, setOpenCompleteModal] = useState(false)
  const [incompleteFields, setIncompleteFields] = useState([])
  const [openCompleteModalCustom, setOpenCompleteModalCustom] = useState(false)
  const location = useLocation()

  const fetchIncompleteRequirements = async () => {
    try{
      const incompleteRequirements = await getMyRequirements(companyId)
      if (!incompleteRequirements?.incompleteFields?.length)
        return
      setOpenCompleteModalCustom(true)
      setIncompleteFields(incompleteRequirements)
      //setData(incompleteRequirements)
      //setLoading(false)
    }catch(e){
      console.log(e)
      //NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    if (companyId && !openCompleteModal) {
      fetchIncompleteRequirements()
    }
  }, [companyId, openCompleteModal])

  useEffect(() => {
    const requiredFields = [
      'name', 
      'email', 
      'address', 
      'addressNumber', 
      'addressNeighborhood', 
      'cep',
      'city',
      'cellphone',
      'birthDate',
      //'isForeign',
    ]

    if (isAuthenticated && user && location.pathname !== '/perfil') {
      if (user.isForeign) {
        requiredFields.push('country')
        requiredFields.push('foreignDocument')
      } else {
        requiredFields.push('state')
        requiredFields.push('cpf')
      }
      setOpenCompleteModal(!requiredFields.reduce(
        (result, requiredField) => 
          (user[requiredField] && user[requiredField] !== '') && result, true))
    }
  }, [user])

  useEffect(() => {
    setLoadingInternal(loading)
  }, [loading, setLoadingInternal])

  return (
    <div className={css['cp-layout']}>
      {loadingInternal ? <div className={css['cp-layout-loading']}>
        <Loader />
      </div> : null}
      <Header disableNav={location.pathname === '/termos'} />
      { children }
      <Footer />
      {openTicket && isAuthenticated() ? <OpenTicket companyId={companyId} /> : null }
      <NotificationContainer/>
      {
        location.pathname !== '/termos' && 
        <CompleteDataModal
          setLoading={setLoadingInternal}
          setOpen={true}
          basicInformation={openCompleteModal}
          customInformations={openCompleteModalCustom}
          user={user}
          incompleteFields={incompleteFields}
        />
      }
      {
        openCompleteModalCustom &&
        <CompleteMetaDataModal 
          setLoading={setLoadingInternal}
          customInformations={openCompleteModalCustom}
          user={user}
          companyIncomplete={incompleteFields}
        />
      }
    </div>
  )
}

export default LayoutDefault