import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import { format } from 'date-fns'
import { useForm, Controller } from 'react-hook-form'
//import css from './styles.module.scss'


export default function ViewStatsModal({ show, handleClose, getData }) {
  const {
    handleSubmit, control, formState: { errors }, setValue, watch
  } = useForm()

  const close = () => {
    handleClose()
  }


  useEffect(() => {
    setValue('startDate', null)
    setValue('endDate', null)
  }, [])

  const buildFile = (data) => {
    const resultFile = [['userId', 'name', 'date'].join(';')+"\n"]
    data.forEach((viewData) => {
      resultFile.push([
        viewData.userId, 
        viewData.user.name, 
        format(new Date(viewData.createdAt), 'dd/MM/yyyy HH:mm:ss')
      ].join(';')+"\n")
    })
    return new Blob(resultFile, {type: 'text/plain'});
  }

  const handleClick = (data) => {
    getData(data, buildFile)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        //dialogClassName={css['dialog']}
      >
        <Modal.Header closeButton>
          <Modal.Title>Estatíticas de vizualização</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleClick)}>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'A data inicial é obrigatória' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Data de inicial"
                      value={value}
                      onChange={onChange}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true}                   
                      invalidDateMessage="Data inválida"
                      maxDate={new Date()}
                      error={errors.startDate}
                      InputLabelProps={{ shrink: !!value }} 
                      helperText={errors.startDate?.message || ''}
                    />
                  )}
                />
              </Col>
              <Col md={6}>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'A data final é obrigatória' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Data final"
                      value={value}
                      onChange={onChange}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true}                   
                      invalidDateMessage="Data inválida"
                      minDate={watch('startDate')}
                      maxDate={new Date()}
                      error={errors.endDate}
                      InputLabelProps={{ shrink: !!value }} 
                      helperText={errors.endDate?.message || ''}
                    />
                  )}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Fechar
            </Button>
            <Button type="submit" variant="success">Gerar relatório</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}
