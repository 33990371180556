/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getSubscriptions, subscriptionStatus } from '../../../../http/subscription'
import ModalSubscription from '../Modal'
import ModalReport from '../../../../components/ModalReport'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import DateFnsUtils from '@date-io/date-fns'
import ConfirmationModal from '../ConfirmationModal'
import { FaEdit, FaDownload, FaSearch, FaTimesCircle } from "react-icons/fa"
import { RiRefund2Line } from "react-icons/ri";
import { useParams } from "react-router-dom"
import { NotificationManager } from 'react-notifications'
import { getUser, AllowedRoles } from '../../../../services/auth'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import FormControl from '@material-ui/core/FormControl';
import { getSubscriptionsReport } from '../../../../http/subscription'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
const server = process.env.REACT_APP_API_URL || null

const SubscriberList = () => {
  
  const size = 10
  const [currentSubscription, setCurrentSubscription] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalConfirmationOpen, setConfirmationOpen] = useState(false)
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [startField, setStartField] = useState(null)
  const [endField, setEndField] = useState(null)
  const [status, setStatus] = useState(null)
  const [page, setPage] = useState(0)
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const companyId = params.companyId || getUser()?.companyId || getUser()?.companyId

  const fetchSubscriptions = async () => {
    try{
      setLoading(true)
      const subscriptions = await getSubscriptions({
        companyId,
        ...( searchable ? { searchable } : {}),
        ...( startField ? { startField } : {}),
        ...( endField ? { endField } : {}),
        ...( status ? { status: status.id } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(subscriptions)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  useEffect(() => {
    document.title = "Associados - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])
  
  useEffect(() => {
    fetchSubscriptions()
  }, [page])

  const editSubscription = (data) => {
    setCurrentSubscription(data)
    setModalOpen(true)
  }

  const cancelSubscription = (data) => {
    setCurrentSubscription(data)
    setConfirmationOpen(true)
  }
  const closeConfirmationModal = () => {
    setSearchable('')
    fetchSubscriptions()
    setCurrentSubscription({})
    setConfirmationOpen(false)
  }

  const closeModal = () => {
    fetchSubscriptions({})
    setModalOpen(false)
    setSearchable('')
    setCurrentSubscription({})
  }

  const closeReportModal = () => {
    setReportModalOpen(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Cursos",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { 
            Header: 'Associado', 
            accessor: d => d.user?.name || '',
            sortType: 'basic'
          }, 
          { 
            Header: 'Status', 
            accessor: data => {
              return subscriptionStatus[data.completeStatus.toLowerCase()] + 
              (subscriptionStatus[data.completeStatus.toLowerCase()] === "Ativo" && data.canceled_recurrence ? ` (Recorrência Cancelada ${
                data.history?.length > 0 ? `por ${data.history[0].user.name}` : ''
              } )` : '')
            }, 
            sortType: 'basic'
          }, 
          { 
            Header: 'Plano', 
            accessor: d => d.plan?.name || null,
            sortType: 'basic'
          }, 
          { Header: 'Ações', 
            Cell: ({row}) =>  {
              let isCreditCardPagarmeTransaction = row.original?.transactions?.filter(t => t.gatewayId === 2 && t.method === "creditCard").length > 0
              return (
              <div>
                {getUser()?.role !== AllowedRoles.TEACHER ? 
                  <Button 
                    variant='link'
                    onClick={() => editSubscription(row.original)}
                  >
                    <FaEdit />
                  </Button>
                : null }
                {getUser()?.role !== AllowedRoles.TEACHER && row.original.status === 'active' && ((row.original.plan.recorrent && !row.original.canceled_recurrence) || (!row.original.plan.recorrent && isCreditCardPagarmeTransaction)) && (
                  <Button variant="link" onClick={() => cancelSubscription(row.original)}>
                    {row.original.plan.recorrent ? <FaTimesCircle /> : <RiRefund2Line />}
                  </Button>
                )}
              </div>
           )}
          }
        ]
      }
    ], []
  );

  const breadcrumbs = !companyId ? [
    {name: 'Início', url: '/dashboard'},
    {name: 'Associados', url: '/dashboard/associacao/associados'}
  ]: [
    {name: 'Início', url: '/dashboard'},
    {name: "Associados", url: `/dashboard/empresa/${companyId}/associacao/associados`}
  ]

  const { document_not_sended, ...subscriptionStatusFiltred } = subscriptionStatus
  const options = Object.keys(subscriptionStatusFiltred).map(key => ({name: subscriptionStatus[key], id: key}))
  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-subscription">
        <div className={css['pg-subscription__filter']}>
          <Row>
            <Col>
              <TextField
                label='Busque por associado ou CPF' 
                variant="outlined"
                value={searchable || ''}
                onChange={e => setSearchable(e.target.value)}
                fullWidth
              />
            </Col>
            <Col>
              <FormControl fullWidth variant='outlined'>
                <Autocomplete 
                  autoComplete={true}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name || ''}
                  options={options}
                  onChange={(e, value) => setStatus(value)}
                  defaultValue={status || null}
                  value={status || null}
                  filterOptions={(data, { inputValue }) => data.filter(({name}) => name?.toLowerCase().includes(inputValue.toLowerCase()) )} 
                  renderInput={(props) => {
                    return (
                      <TextField
                        {...props}
                        label="Status"
                        variant="outlined"
                        fullWidth
                      />
                    )
                  }}
                />
              </FormControl>
            </Col>
          </Row>
          <Row>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Col md={4}>
                <KeyboardDateTimePicker
                  variant="inline"
                  ampm={false}
                  label="Incio da associação desde "
                  value={startField}
                  onChange={(e) => {if (e?.toString() !== "Invalid Date"){ setStartField(e)}}}
                  format="dd/MM/yyyy HH:mm"
                  fullWidth={true}
                  inputVariant="outlined"
                  autoOk={true}                   
                  invalidDateMessage="Data inválida"
                />
              </Col>
              <Col md={4}>
                <KeyboardDateTimePicker
                  variant="inline"
                  ampm={false}
                  label="Inicio da associação até"
                  value={endField || null}
                  onChange={(e) => {if (e?.toString() !== "Invalid Date"){ setEndField(e)}}}
                  minDate={startField || null}
                  format="dd/MM/yyyy HH:mm"
                  fullWidth={true}
                  inputVariant="outlined"
                  autoOk={true} 
                  invalidDateMessage="Data inválida"
                  minDateMessage="A data final deve ser mais que a data final"
                />
              </Col>
            </MuiPickersUtilsProvider>
            <Col md={4} >
              <Button 
                onClick={fetchSubscriptions}
                target="_blank"
                className={css['pg-subscription__filter__button']}
              >
                <FaSearch />
              </Button>
              <Button 
                //href={`${server}/api/subscription/generateReport/${companyId}`} 
                //target="_blank"
                className={css['pg-subscription__filter__button']}
                onClick={() => { setReportModalOpen(true) }}
              >
                <FaDownload /> Relatório
              </Button>
            </Col>
          </Row>
        </div>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchSubscriptions} 
          size={size}
          className={css["pg-subscription__table"]}
          setPage={setPage}
        />
      </div>
      <ModalSubscription 
        show={modalOpen}
        handleClose={closeModal}
        subscription={currentSubscription}
        companyId={companyId}
        setLoading={setLoading}
      />
      <ConfirmationModal 
        show={modalConfirmationOpen}
        handleClose={closeConfirmationModal}
        subscription={currentSubscription}
        companyId={companyId}
        setLoading={setLoading}
      />
      {
        reportModalOpen ? 
          <ModalReport 
            show={reportModalOpen}
            handleClose={closeReportModal}
            setLoading={setLoading}
            getData={getSubscriptionsReport}
            userId={getUser()?.id}
            companyId={companyId}
            extraFields={[{
              title: "Campos relativos ao plano",
              fields: [
                {field: "plan.name", label: "Nome do plano"},
                {field: "plan.recorrent", label: "Cobrança recorrente?"},
                {field: "plan.sendDocument", label: "Envio de documento?"},
                {field: "plan.documentDescription", label: "Descrição do documento"}
              ]
            }, {
              title: "Campos relativos à associação",
              fields: [
                {field: "startDate", label: "Início"},
                {field: "endDate", label: "Final"},
                {field: "completeStatus", label: "Status"},
                {field: "transactionValue", label: "Valor pago"},
                {field: "transactionGateway", label: "Gateway"},
                {field: "transactionPaymentMethod", label: "Método de pagamento"},
                {field: "updatedAt", label: "Última atualização"},
                {field: "documentSent", label: "Documento enviado pelo usuário?"},
                {field: "file", label: "Carteirinha gerada?"},
              ]
            }]}
            filters={{
              companyId,
              ...( searchable ? { searchable } : {}),
              ...( startField ? { startField } : {}),
              ...( endField ? { endField } : {}),
              ...( status ? { status: status.id } : {}),
              // size,
              // start: ( page || 0 ) * size
            }}
          />
        : null
      }
    </DashboradLayout>
  )
}

export default SubscriberList