import axios from './api'

const getSpeakers = async ( params ) => {
  try {
    const speakers =  await axios.get(`api/speakers`, { params })
    return speakers.data
  } catch (error) {
    return error;
  }
}

const getSpeaker = async ( id ) => {
  const speakers =  await axios.get(`api/speakers`, { params: { id } })
  return speakers.data.rows[0]
}

const saveSpeaker = async ( data ) => {
  const speaker =  await axios.post(`api/speaker`, data )
  return speaker.data
}

const updateSpeaker = async ( id, data ) => {
  const speaker =  await axios.put(`api/speaker/${id}`, data )
  return speaker.data
}

const deleteSpeaker = async ( id ) => {
  const voucher =  await axios.delete(`api/speaker/${id}`)
  return voucher.data
}

export { 
  getSpeakers, 
  saveSpeaker, 
  updateSpeaker, 
  getSpeaker,
  deleteSpeaker
}