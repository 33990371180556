import React from 'react'
import { DateTime } from 'luxon'
import { BsTable, BsClock } from 'react-icons/bs'
import css from './styles.module.scss'
import Speaker from '../Speaker'
const server = process.env.REACT_APP_API_URL || null

const Lecture = ({ day, lecture }) => {
  return (
    <div className={css["lecture"]}>
      <div className={css["lecture__time"]}>
        <p><BsTable /> {day}</p>
        <p><BsClock /> {DateTime.fromJSDate(new Date(lecture[0].date)).toFormat("HH:mm")} -
        {DateTime.fromJSDate(new Date(lecture[0].endDate)).toFormat("HH:mm")}
        </p>
      </div>
      <div className={css["lecture__info"]}>
        <h2 className={css["lecture__title"]}>{lecture[0].lecture}</h2>
        {
          lecture.map(speaker => (
            <Speaker 
              key={speaker.id}
              speaker={speaker}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Lecture