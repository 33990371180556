import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { useForm } from 'react-hook-form'
import { RiLockPasswordLine } from 'react-icons/ri'
import css from './styles.module.scss'
import SHA256 from "crypto-js/sha256"
import { recoverPassword } from '../../http/auth'
import { NotificationManager } from 'react-notifications'
import queryString from 'query-string'
import { useLocation, useHistory } from "react-router-dom"
import {NotificationContainer} from 'react-notifications'
import Loader from '../../components/common/Loader'

const RecoverPassword = () => {
  const { search } = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const {register, watch, formState: { errors }, handleSubmit, reset} = useForm()
  const { token } = queryString.parse(search)

  useEffect(() => {
    document.title = `Recuperar senha`
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const onSubmit = (data) => {
    if (!token) {
      NotificationManager.error('Token inválido')
      return 
    }
    setLoading(true)
    recoverPassword({
      token: token,
      newPassword: SHA256(data.password).toString(),
    })
    .then(res => {
      reset()
      alert("Senha alterada com sucesso!")
      history.replace('/login')
    }).catch(e => {
      let message = "Ocorreu um erro"
      if (e.response?.data?.error)
        message = e.response.data.error.message
      NotificationManager.error(message, 'Ocorreu um erro')
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className={css["pg-recover"]}>
      {loading ? <div className={css['cp-layout-loading']}>
				<Loader />
			</div> : null}
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className={css["cp-recover"]}>
              <h2>Recuperar senha</h2>
              <Form key={"update"} onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                  <RiLockPasswordLine />
                  <Form.Control
                    {...register('password', {
                      required: { value: true, message: 'A nova senha é obrigatória' },
                      minLength: { value: 6, message: 'O nova senha deve ter pelo menos 6 caracteres' },
                      maxLength: { value: 255, message: 'O nova senha não pode ser maior que 255 caracteres' },
                    })}
                    placeholder="Nova senha"
                    type="password"
                    isInvalid={errors.password}
                  />
                  <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <RiLockPasswordLine />
                  <Form.Control
                    {...register('passwordConfirm', {
                      required: { value: true, message: 'A confirmação de senha é obrigatória' },
                      validate: { value: value => value === watch('password') || "As senhas não são iguais" }
                    })}
                    placeholder="Confirmar senha"
                    type="password"
                    isInvalid={errors.passwordConfirm}
                  />
                  <Form.Control.Feedback type="invalid">{errors.passwordConfirm?.message}</Form.Control.Feedback>
                </Form.Group>
                <Button type="submit">Alterar senha</Button>
              </Form>
            </div>
          </Col>
        </Row>
        <NotificationContainer />
      </Container>
    </div>
  )
}

export default RecoverPassword