
import css from './styles.module.scss'
import image from '../../../assets/lessons.png'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

const CompanyContent = styled.div `
  background: linear-gradient(90deg, ${props => props.primaryColor ? props.primaryColor : "#808080"} 70%, ${props => props.secondaryColor ? props.secondaryColor : "#292928"} 30%);
`

const CompanyMyContents = ({company}) => {
  return (
    <CompanyContent 
      primaryColor={company.secondaryColor}
      secondaryColor={company.primaryColor}
      className={css['cp-company-content']}
    >
      <div className={css['cp-company-content__title']}>
        <h2>Meus Conteúdos</h2>
      </div>
      {//<img src={image} alt=""/>
      }
      <div className={css['cp-company-content__text']}>
        <p>Confira seus materiais disponíveis!</p>
        <a 
          href="/meus-cursos"
          className={css['cp-company-content__button']}
          rel="noreferrer"
        >
          Ir para meus eventos
        </a><br/>
        <Link
          to="/materiais-assinaturas"
          className={css['cp-company-content__button']}
          rel="noreferrer"
        >
          Materiais de Associado
        </Link>
      </div>
    </CompanyContent>
  )
}
export default CompanyMyContents