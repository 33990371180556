import React, { useEffect, useState } from 'react'
import css from './styles.module.scss'
import Login from '../../components/common/Login'
import SignIn from '../../components/common/SignIn'
import { getUser } from '../../services/auth'
import LayoutDefault from '../../layouts/default'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { animateScroll } from "react-scroll";


const Auth = () => {
	const [ loading, setLoading ] = useState(false)
	const [ isLogin, setIsLogin ] = useState(true)

	const changeView = (value) => {
		animateScroll.scrollToTop()
		setIsLogin(value)
	}

	useEffect(() => {
		if (getUser()) {
			window.location.href = '/perfil';
			return null
		}
	}, [])

	

	return (
		<LayoutDefault loading={loading}>
			<div>
				<div className={css['pg-auth-header']}>
					<Container>
						<Row>
							<Col md={6} className={isLogin ? "d-block" : "d-none d-md-block"}>
								<h2 className={css['login']}>Já sou cadastrado</h2>
							</Col>
							<Col md={6} className={!isLogin ? "d-block" : "d-none d-md-block"}>
								<h2 className={css['signup']}>Quero me cadastrar</h2>
							</Col>
						</Row>
					</Container>
				</div>
				<div className={css['pg-auth']}>
					<Container>
						<Row>
							<Col md={6} className={isLogin ? "d-block" : "d-none d-md-block"}>
								<Login setLoading={setLoading} setOpen={() => changeView(false)} />
							</Col>
							<Col md={6} className={!isLogin ? "d-block" : "d-none d-md-block"}>
								<SignIn setLoading={setLoading} setOpen={() => changeView(true)}  />
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</LayoutDefault>
	)
}

export default Auth