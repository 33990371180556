import { axios } from './api'

const getEmails = async ( params ) => {
  try {
    const emails =  await axios.get(`api/emails`, { params })
    return emails.data
  } catch (error) {
    return error;
  }
}

const updateEmail = async ( id, data ) => {
  const email =  await axios.put(`api/email/${id}`, {data} )
  return email.data
}

const sendNow = async ( data ) => {
  const email =  await axios.post(`api/sendEmail`, {data} )
  return email.data
}

export { updateEmail, getEmails, sendNow }