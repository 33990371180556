/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getCourses, deleteCourse } from '../../../../http/course'
import ModalCourse from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaArrowRight, FaPlus, FaTrash } from "react-icons/fa"
import { useParams, Link } from "react-router-dom"
import Moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { getUser, AllowedRoles } from '../../../../services/auth'
import ModalImportEnroll from '../ModalImport'

const CourseList = () => {

  const size = 10
  const [currentCourse, setCurrentCourse] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const [openModalImport, setOpenModalImport] = useState(false)
  const [courseToImport, setCourseToImport] = useState(null)
  const companyId = params.companyId || getUser()?.companyId

  const fetchCourses = async () => {
    try{
      const courses = await getCourses({
        ...( searchable ? { name: searchable } : {}),
        ...(companyId ? { companyId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(courses)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  const handleOpenImport = (courseId) => {
    setOpenModalImport(true)
    setCourseToImport(courseId)
  }

  const closeModalImport = () => {
    setOpenModalImport(false)
    setCourseToImport(null)
  }
  
  useEffect(() => {
    fetchCourses()
  }, [searchable, page])

  useEffect(() => {
    document.title = "Cursos - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const editCourse = (data) => {
    setCurrentCourse(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    fetchCourses()
    setModalOpen(false)
    setSearchable('')
    setCurrentCourse({})
  }

  const addCourse = () => {
    setSearchable('')
    setCurrentCourse({})
    setModalOpen(true)
  }

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja deletar esse curso? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deleteCourse(item.id)
        setSearchable('')
        fetchCourses()
        setLoading(false)
        NotificationManager.success('Curso excluído com sucesso', 'Deletado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Cursos",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' }, 
          { 
            Header: 'Data', 
            accessor: d => Moment(d.date).local().format("DD/MM/YYYY HH:mm"),
            sortType: 'basic'
          }, 
          { Header: 'Empresa', accessor: 'company.name' }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                {getUser()?.role !== AllowedRoles.TEACHER ? 
                  <Button variant="link" onClick={() => editCourse(row.original)}>
                    <FaEdit />
                  </Button>
                : null }
                <Button variant="link" onClick={() => deleteItem(row.original)}>
                  <FaTrash />
                </Button>
                <Link to={{pathname: `/dashboard/empresa/${row.original.companyId}/curso/${row.original.id}/aulas`}}>
                  <FaArrowRight />
                </Link>
              </div>
            )
          }
        ]
      }
    ], []
  );

  const breadcrumbs = !companyId ? [
    {name: 'Início', url: '/dashboard'},
    {name: 'Cursos', url: '/dashboard/courses'}
  ]: [
    {name: 'Início', url: '/dashboard'},
    {name: "Cursos", url: `/dashboard/empresa/${companyId}/cursos`}
  ]

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-course">
        <Row className={css['pg-course__filter']}>
          <Col className={css['pg-course__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          {companyId && 
            <Col xs={3} className={css['pg-course__filter__actions']}>
              <Button onClick={ addCourse }><FaPlus /> Nova</Button>
            </Col>
          }
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchCourses} 
          size={size}
          className={css["pg-course__table"]}
          setPage={setPage}
        />
      </div>
      <ModalCourse 
        show={modalOpen}
        handleClose={closeModal}
        course={currentCourse}
        companyId={companyId}
        setLoading={setLoading}
        handleOpenImport={handleOpenImport}
      />
      <ModalImportEnroll
        show={openModalImport}
        handleClose={closeModalImport}
        companyId={companyId}
        setLoading={setLoading}
        courseToImport={courseToImport}
      />
    </DashboradLayout>
  )
}

export default CourseList