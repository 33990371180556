import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import css from './styles.module.scss'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
    '& .MuiFormControl-root': {
      margin: '8px 0',
    },
  },
}));

export default function SendDocument({ description, setDocuments, documents, nextStep }) {
  const classes = useStyles()

  const onSubmit = async (data) => {
    nextStep()
  }

  const onCertChange = (event) => {
    setDocuments(event.target.files[0])
  }

  return (
    <>
      <Form className={classes.root} onSubmit={onSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <p className={css['cp-send-document__text']}>
                Essa compra está sujeita a avaliação do seguinte documento para a
                validação do valor promocional. 
              </p>
              <p className={css['cp-send-document__description']}>{ description }</p>
              <Form.Control type="file" onChange={onCertChange} />
            </Form.Group>
            <p  className={css['cp-send-document__obs']}>
              *Caso o documento seja inválido, o valor promocional será removido e 
              entraremos em contato para envio de um novo documento válido, pagamento da diferença ou cancelamento da compra.
            </p>
          </Col>
        </Row>
        <Button 
          disabled={!documents}
          type="submit"
          variant="success"
        >Continuar</Button>
      </Form>
    </>
  )

}