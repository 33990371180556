import React, { useState, useEffect, useRef } from 'react'
import css from './styles.module.scss'
import Container from 'react-bootstrap/Container'
import { 
	isAuthenticated, 
	getUser, 
	logout, 
	hasDashboard, 
	redirectTo
} from '../../../../services/auth'
import { logout as logoutRequest } from '../../../../http/auth'
import { Link } from 'react-router-dom'
import { IoBookOutline, IoCalendarOutline } from 'react-icons/io5'
import { GiEntryDoor, GiFairyWand } from 'react-icons/gi'
import { AiOutlineUser } from 'react-icons/ai'
import { FaChevronDown, FaQuestion, FaRegEdit } from 'react-icons/fa'
import { BiMedal } from 'react-icons/bi'
import { TiThMenu } from 'react-icons/ti'
import logo from '../../../../assets/Alissem.png'

const Header = ({ fluid = false, disableNav = false }) => {
	const logged = isAuthenticated()
	const [openned, setOpenned] = useState(false)
	let user = null
	const wrapperRef = useRef(null);

	if (logged)
		user = getUser();

	const handleLogout = async () => {
		try{
			await logoutRequest()
			logout()
			window.location.href = '/'
		} catch(e){
		
		}
	}

	const handleClick = () => {
		setOpenned(last => !last)
	}

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setOpenned(false)
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	return (
		<div className={`${css['cp-header']}`} ref={wrapperRef}>
			<Container fluid={fluid} className={`${css['cp-header__content']}`}>
				<Link to="/" className={`${css['cp-header__logo']}`}>
					<img src={logo} alt="" />
				</Link>
				<div className={`${css['cp-header__content__right']}`}>
					<Link className={css["principal"]} to="/validar-documento">Validador</Link>
					{logged ? 
						<div className={css["cp-header__logged-menu"]}>
							<button className={css["open-btn"]} onClick={handleClick}>Olá { user.name.substring(0, 15) }<FaChevronDown /></button>
							<button className={`${css["open-btn"]} ${css["open-btn--mobile"]}`} onClick={handleClick}><TiThMenu /></button>
							<div className={`${css["cp-header__collapsable-menu"]} ${openned && css["cp-header__collapsable-menu--open"]}`}>
								<ul>
									<li><Link to={ disableNav ? "/" : "/perfil"}><AiOutlineUser />Meu cadastro</Link></li>
									<li><Link to={ disableNav ? "/" : "/meus-cursos"}><IoCalendarOutline />Meus eventos</Link></li>
									<li><Link to={ disableNav ? "/" : "/materiais-assinaturas"}><IoBookOutline />Materiais Associado</Link></li>
									<li><Link to={ disableNav ? "/" : "/meus-pedidos"}><GiFairyWand />Pedidos</Link></li>
									<li><Link to={ disableNav ? "/" : "/meus-certificados"}><BiMedal />Certificados</Link></li>
									<li><Link to={ disableNav ? "/" : "/meus-tickets"}><FaQuestion />Suporte</Link></li>
									{hasDashboard() && 
										<li>
											<Link to={redirectTo[user.role]}>
												<FaRegEdit />
												Gerenciar
											</Link>
										</li>
									}
									<li><button onClick={handleLogout}><GiEntryDoor />Sair</button></li>
								</ul>
							</div>
						</div>
						: <Link to="/login" className={`${css['principal']}`}>Login</Link>
					}
				</div>
			</Container>
		</div>
	)
}

export default Header