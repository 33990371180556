import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { DateTime } from 'luxon'
import css from './styles.module.scss'

export default function ModalTicket({ show, handleClose, ticket }) {

  const close = () => {
    handleClose()
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Suporte</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <span className={css["bold"]}>Empresa:</span> {ticket.company?.name}
        </p>
        <p>
          <span className={css["bold"]}>Criado em:</span>  {DateTime.fromJSDate(new Date(ticket.createdAt)).toFormat("dd/M/yyyy HH:mm")}
        </p>
        {ticket?.user?.name &&
          <p>
            <span className={css["bold"]}>Requisitante:</span>  {ticket.user.name}
          </p>
        }
        <p>
          <span className={css["bold"]}>Assunto:</span> {ticket.title}
        </p>
        {ticket.message && <p>
          <span className={css["bold"]}>Descrição:</span> {ticket.message} </p>
        }
        <p>
          <span className={css["bold"]}>Status:</span> {ticket.answer ? "Respondido" : "Aberto"}
        </p>
        {ticket.updatedAt !== ticket.createdAt &&
          <p><span className={css["bold"]}>Respondido em:</span> {DateTime.fromJSDate(new Date(ticket.updatedAt)).toFormat("dd/M/yyyy HH:mm")}</p>
        }
        {ticket.answer && 
          <p><span className={css["bold"]}>Reposta:</span> <span dangerouslySetInnerHTML={{__html: ticket.answer}}></span></p>
        }
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
