import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { saveCourse, updateCourse } from '../../../../http/course'
import css from './styles.module.scss'
import WYSIWYGEditor from '../../../../components/common/WYSIWYG'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { TextField, FormControlLabel, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
  },
}));

const server = process.env.REACT_APP_API_URL || null

export default function ModalCourse({ show, handleClose, course, companyId, setLoading, handleOpenImport }) {
  const {
    handleSubmit, setValue, reset, getValues, watch, control, formState: { errors },
  } = useForm()
  const classes = useStyles()
  const [logo, setLogo] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)
  const [certBase, setCertBase] = useState(null)
  const [certBasePreview, setCertBasePreview] = useState(null)
  watch('date')
  watch('endDate')
  watch('dateHour')
  watch('endDateHour')

  useEffect(() => {
    const fields = ['name', 'informations', 'date', 'duration', 'endDate', 'price', 'onlyRecorrent', 'certificateRelease', 'open', 'priceName']
    if (course.id) {
      fields.forEach((field) => setValue(field, course[field]))
      if (course.logoUrl) setLogoPreview(`${server}${course.logoUrl}`)
      if (course.certUrl) setCertBasePreview(`${server}${course.certUrl}`)
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [course, setLogoPreview, setValue])

  useEffect(() => {
    if (show && !course.id) {
      setValue('price', '0.00', { shouldValidate: true })
    }
  }, [show])

  const onSubmit = async (data) => {
    setLoading(true)
    let result = null
    data.courseId = course.id
    var formData = new FormData()
    formData.append("name", data.name)
    formData.append("informations", data.informations || '')
    formData.append("date", data.date)
    formData.append("endDate", data.endDate)
    formData.append("price", data.price)
    formData.append("duration", data.duration)
    formData.append("onlyRecorrent", data.onlyRecorrent)
    formData.append("certificateRelease", data.certificateRelease)
    formData.append("companyId", companyId)
    formData.append("open", data.open)
    formData.append("priceName", data.priceName)
    formData.append("logoFile", logo)
    formData.append("certFile", certBase)

    if (course.id) result = updateCourse(course.id, formData)
    else result = saveCourse(formData)

    result.then(e => {
      NotificationManager.success('O curso foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    setLogoPreview(null)
    setCertBasePreview(null)
    setLogo(null)
    handleClose()
  }

  const onLogoChange = (event) => {
    setLogoPreview(URL.createObjectURL(event.target.files[0]))
    setLogo(event.target.files[0])
  }

  const onCertChange = (event) => {
    setCertBasePreview(URL.createObjectURL(event.target.files[0]))
    setCertBase(event.target.files[0])
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Modal.Header closeButton>
          <Modal.Title>{course.id ? 'Editar ' : 'Cadastrar '}curso</Modal.Title>
        </Modal.Header>
        <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ 
                required: { value: true, message: 'O nome do curso é obrigatório' },
                minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
                maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Nome"
                  variant="outlined"
                  value={value || ''}
                  onChange={onChange}
                  fullWidth
                  error={errors.name}
                  helperText={errors.name?.message || ''}
                />
              )}
            />
            <Row>
              <Col>
                <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'A data e hora do curso é obrigatório' }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Data inicial"
                      value={value}
                      onChange={onChange}
                      onError={console.log}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true}                   
                      invalidDateMessage="Data inválida"
                      error={errors.date}
                      helperText={errors.date?.message || ''}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'A data e hora final do curso é obrigatório' }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Data final"
                      value={value || null}
                      onChange={onChange}
                      onError={console.log}
                      minDate={getValues('date')}
                      customInput
                      disabled={!getValues('date')}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true} 
                      invalidDateMessage="Data inválida"
                      minDateMessage="A data final deve ser mais que a data final"
                      error={errors.endDate}
                      helperText={errors.endDate?.message || ''}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      isNumericString
                      prefix={"R$ "}
                      value={value || '0.00'}
                      variant="outlined"
                      fullWidth
                      label="Valor total"
                      error={errors.price}
                      helperText={errors.price?.message || ''}
                      onValueChange={(v) => {
                        onChange(v.value)
                      }}
                    />
                  )}
                  rules={{ 
                    required: { value: true, message: 'O preço do curso é obrigatório' }
                  }}
                  name="price"
                  control={control}
                />
              </Col>
              <Col md={6}> 
                <Controller
                  name="priceName"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Título do valor integral"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={errors.priceName}
                      helperText={errors.priceName?.message || ''}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  name="duration"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'A duração do curso é obrigatória' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Duração (em horas)"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={errors.duration}
                      helperText={errors.duration?.message || ''}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  name="open"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => {
                    const realValue = !value
                    return (
                    <FormControlLabel
                      control={
                        <Switch checked={value} onChange={onChange} />}
                      label="Inscrições abertas?"
                    />
                  )}}
                />
                <Controller
                  name="onlyRecorrent"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch checked={value || false} onChange={onChange} />}
                      label="Somente para associados?"
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="certificateRelease"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch checked={value || false} onChange={onChange} />}
                      label="Certificados liberados?"
                    />
                  )}
                />
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Descrição</Form.Label>
              <Controller
                render={ () => 
                  <WYSIWYGEditor 
                    value={getValues('informations')}
                    onChange={value => {
                      setValue('informations', value)
                    }} 
                  /> 
                }
                name='informations'
                control={control}
                //defaultValue=''
              />
              <Form.Control.Feedback type="invalid">{errors.pagseguroKey?.message}</Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Imagem do curso</Form.Label>
                  <Form.Control type="file" onChange={onLogoChange} accept="image/*" />
                </Form.Group>
                {logoPreview &&
                  <div className={css['cp-modal-course__preview']}>
                    <img src={logoPreview} alt="" />
                  </div>
                }
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Imagem base do certificado</Form.Label>
                  <Form.Control type="file" onChange={onCertChange} accept="image/*" />
                </Form.Group>
                {certBasePreview &&
                  <div className={`
                    ${css['cp-modal-course__preview']} 
                    ${css['cp-modal-course__cert__preview']}
                  `}>
                    <img src={certBasePreview} alt="" />
                  </div>
                }
              </Col>
            </Row>
            {course.id && (
              <Row className={css["cp-modal-course__importBtn"]}>
                <Col md={6} >
                  <Button onClick={() => handleOpenImport(course.id)}>Importar inscritos</Button>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">{course.id ? 'Salvar' : 'Cadastrar'}</Button>
          </Modal.Footer>
        </Form>
      </MuiPickersUtilsProvider>
    </Modal>
  )
}
