import axios from './api'

const getTickets = async ( params ) => {
  try {
    const tickets =  await axios.get(`api/tickets`, { params })
    return tickets.data
  } catch (error) {
    return error;
  }
}

const getTicket = async ( id ) => {
  const tickets =  await axios.get(`api/tickets`, { params: { id } })
  return tickets.data.rows[0]
}

const getOpenQuantity = async ( params ) => {
  try {
    const tickets =  await axios.get(`api/tickets/open`, { params })
    return tickets.data || null
  } catch (e) {
    return null
  }
}


const saveTicket = async ( data ) => {
  const ticket =  await axios.post(`api/ticket`, { data })
  return ticket.data
}

const answerTicket = async ( id, answer ) => {
  const ticket =  await axios.put(`api/ticket/${id}/answer`, { data: {answer} })
  return ticket.data
}

const deleteTicket = async ( id ) => {
  const voucher =  await axios.delete(`api/ticket/${id}`)
  return voucher.data
}

export { 
  getTickets, 
  saveTicket, 
  answerTicket, 
  getTicket,
  deleteTicket,
  getOpenQuantity
}