import React, {useMemo, useEffect, useState} from 'react'
import css from './styles.module.scss'
import { enrollStatus } from '../../../http/enroll'

const UserInfoCourse = ({enrolls, company}) => {
  if (enrolls.length == 0){
    return null
  }
  return (
    <div className={css["cp-user-info-courses"]}>
      <h1>Eventos Matriculados</h1>
      <div className={css["cp-user-info-courses__enrolls"]}>
        {enrolls?.map(enroll => (
          <div className={css["cp-user-info-courses__enrolls__item"]}>
            <div className={css["cp-user-info-courses__enrolls__item__image"]}>
              <img src={enroll.course.logo} />
            </div>
            <div className={css["cp-user-info-courses__enrolls__item__content"]}>
              <p className={css["cp-user-info-courses__enrolls__item__content__name"]}>
                {enroll.course.name}
              </p>
              <p>
                Empresa: {company.name}
              </p>
              {enroll.voucher ? `Voucher: ${enroll.voucher.name}` : (enroll.course.priceName ? enroll.course.priceName : 'Valor integral') }
              <p>Certificado emitido: {enroll.file ? 'Sim' : 'Não'}</p>
              <p>{enrollStatus[enroll.status]}</p>
              
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}


export default UserInfoCourse