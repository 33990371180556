import React from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Controller } from 'react-hook-form'
import InputMask from "react-input-mask"
import { FaRegUser } from 'react-icons/fa'
import { FiHome, FiMail, FiPhone, } from 'react-icons/fi'
import { RiLockPasswordLine } from 'react-icons/ri'
import { BiCake } from 'react-icons/bi'
import { 
  validateEmail, 
  validateBirthDate, 
  validatePostalCode, 
  validateName, 
  validateTelephone,
  validateCpf
} from '../../../util/validate'
import css from './styles.module.scss'
import { states, countries } from '../../../util/utils'

export default function UserDataForm({ 
  register, 
  errors, 
  control, 
  watch= null, 
  showPassword=true,
  twoColumns=false,
  showIcons=true
}) {

  const inputs = [
    {
      name: 'isForeign',
      placeholder: "Sou estrangeiro",
      type: 'checkbox'
    },
    {
      name: 'name',
      icon: <FaRegUser />,
      rules: {
        required: { value: true, message: 'O nome é obrigatório' },
        minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
        maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
        validate: { value: validateName }
      },
      placeholder: "Nome completo",
      defaultError: 'Nome completo inválido'
    },
    (watch('isForeign') ? {
      name: 'foreignDocument',
      icon: <FaRegUser />,
      placeholder: "Documento estrangeiro (passaporte, ID)",
      rules: {
        required: { value: true, message: 'O documento é obrigatório' },
      }
    } : {
      name: 'cpf',
      placeholder: "CPF",
      mask: "999.999.999-99",
      icon: <FaRegUser />,
      defaultError: 'CPF inválido',
      rules: {
        required: { value: true, message: 'O CPF é obrigatório' },
        validate: { value: (value) =>
          watch('isForeign') ? true : validateCpf(value)
        }
      }
    }),{
      name: 'country',
      show: watch('isForeign'),
      icon: <FiHome />,
      ...(
        watch('isForeign') ? 
        {
          rules: {
            required: { value: true, message: 'O país é obrigatório' },
          }
        } : {}
      ),
      as: 'select',
      options: [
        <option key='' value="">País</option>,
        countries.map(({ code, name }) => (
          <option key={code} value={code}>{name}</option>
        ))
      ],
      placeholder: "País",
      defaultError: 'País inválido'
    },{
      name: 'state',
      show: !watch('isForeign'),
      icon: <FiHome />,
      ...(
        watch('isForeign') ? {} :
        {
          rules: {
            required: { value: true, message: 'O estado é obrigatório' },
          }
        }
      ),
      as: 'select',
      options: [
        <option key='' value="">Estado</option>,
        states.map(({ value, name }) => (
          <option key={value} value={value}>{name}</option>
        ))
      ],
      placeholder: "Estado",
      defaultError: 'Estado inválido'
    },{
      name: 'city',
      icon: <FiHome />,
      rules: {
        required: { value: true, message: 'A cidade é obrigatória' },
        minLength: { value: 3, message: 'A cidade deve ter pelo menos 3 caracteres' },
        maxLength: { value: 255, message: 'A cidade não pode ser maior que 255 caracteres' },
      },
      placeholder: "Cidade",
      defaultError: 'Cidade inválida'
    },{
      name: 'cep',
      icon: <FiHome />,
      rules: {
        required: { value: true, message: 'O CEP é obrigatório' },
        validate: { value: watch('isForeign') ? validatePostalCode : () => true }
      },
      ...(
        watch('isForeign') ? {
          placeholder: "Código ZIP",
          defaultError: 'Código ZIP inválido',
          rules: {
            required: { value: true, message: 'O código ZIP é obrigatório' },
          },
        } : { 
          mask: "99999-999",
          placeholder: "CEP",
          defaultError: 'CEP inválido',
        }
      ),
    },{
      name: 'address',
      icon: <FiHome />,
      rules: {
        required: { value: true, message: 'O endereço é obrigatório' },
        minLength: { value: 3, message: 'O endereço deve ter pelo menos 3 caracteres' },
        maxLength: { value: 255, message: 'O endereço não pode ser maior que 255 caracteres' },
      },
      placeholder: "Endereço",
      defaultError: 'Endereço inválido'
    },{
      name: 'addressNeighborhood',
      icon: <FiHome />,
      rules: {
        required: { value: true, message: 'O bairro é obrigatório' },
        minLength: { value: 3, message: 'O bairro deve ter pelo menos 3 caracteres' },
        maxLength: { value: 255, message: 'O bairro não pode ser maior que 255 caracteres' },
      },
      placeholder: "Bairro",
      defaultError: 'Bairro inválido'
    },{
      name: 'addressNumber',
      icon: <FiHome />,
      rules: {
        required: { value: true, message: 'O número é obrigatório' },
      },
      placeholder: "Número",
      defaultError: 'Número inválido'
    },{
      name: 'addressComplement',
      icon: <FiHome />,
      placeholder: "Complemento",
      defaultError: 'Complemento inválido'
    },{
      name: 'email',
      icon: <FiMail />,
      rules: {
        required: { value: true, message: 'O email é obrigatório' },
        minLength: { value: 3, message: 'O email deve ter pelo menos 3 caracteres' },
        maxLength: { value: 255, message: 'O email não pode ser maior que 255 caracteres' },
        validate: { value: validateEmail }
      },
      placeholder: "Email",
      defaultError: 'Email inválido'
    },{
      name: 'cellphone',
      icon: <FiPhone />,
      rules: {
        required: { value: true, message: 'O celular é obrigatório' },
        validate: { value: validateTelephone }
      },
      mask:"(99) 9999tt999?",
      formatChars: {"9": "[0-9]", "t": "[0-9\-]", "?": "[0-9 ]"},
      placeholder: "Celular (com DDD)",
      defaultError: 'Celular inválido'
    },{
      name: 'birthDate',
      icon: <BiCake />,
      rules: {
        required: { value: true, message: 'A data de nascimento é obrigatória' },
        validate: { value: validateBirthDate }
      },
      mask: "99/99/9999",
      placeholder: "Data de nascimento (DD/MM/AAAA)",
      defaultError: 'Data de nascimento inválida'
    },
  ]

  if (showPassword) 
    inputs.push({
      name: 'password',
      icon: <RiLockPasswordLine />,
      rules: {
        required: { value: true, message: 'A senha é obrigatória' },
        minLength: { value: 6, message: 'O senha deve ter pelo menos 6 caracteres' },
        maxLength: { value: 255, message: 'O senha não pode ser maior que 255 caracteres' },
      },
      type: 'password',
      placeholder: "Senha",
      defaultError: 'Senha inválida'
    },{
      name: 'passwordConfirm',
      icon: <RiLockPasswordLine />,
      type: 'password',
      rules: {
        required: { value: true, message: 'A confirmação de senha é obrigatória' },
        validate: { value: value => value === watch('password') || "As senhas não são iguais" }
      },
      placeholder: "Confirmar senha",
      defaultError: 'Confirmação da senha inválida'
    })

  const buildInput = (inputList) => 
    inputList.map((input) => {
      if (input.show === false) return

      if (input.type == 'checkbox') {
        return (
            <Controller
              control={control}
              name={input.name}
              key={input.name}
              {...register(input.name, input.rules)}
              render={({ field: { onChange, value } }) => (
                <Form.Check 
                  value={value}
                  defaultChecked={value}
                  onChange={onChange}
                  type={input.type}
                  label={input.placeholder}
                />
              )}
            /> 
        )
      } else {
        return (
          <Form.Group className="mb-3" key={input.name}>
            {showIcons ? input.icon : input.placeholder }
            { input.mask ? 
              <Controller
                control={control}
                name={input.name}
                key={input.name}
                {...register(input.name, input.rules)}
                render={({ field: { onChange, value } }) => (
                  <InputMask
                    onChange={onChange}
                    value={value || ''}
                    maskChar=""
                    name={input.name}
                    mask={input.mask}
                    formatChars={input.formatChars}
                    placeholder={input.placeholder}
                  >
                    {(inputProps) =>
                      <Form.Control
                        {...inputProps}
                        isInvalid={errors[input.name]}
                      />
                    }
                  </InputMask>
                )}
              /> 
            :
              <Form.Control
                {...register(input.name, input.rules)}
                placeholder={input.placeholder}
                isInvalid={errors[input.name]}
                {... input.as ? { as: input.as } : {}}
                {...input.type ? { type: input.type }: {}}
              >
                {input.options ? input.options : null}
              </Form.Control>
            }
            <Form.Control.Feedback type="invalid">
              {
                errors[input.name]?.type === 'value' ? 
                  input.defaultError : 
                  (errors[input.name]?.message || '')
              }
            </Form.Control.Feedback>
          </Form.Group>
        )
      }
    })

  if (twoColumns) {
    const middleIndex = Math.ceil(inputs.length / 2)
    return (
      <Row>
        <Col md={6}>
          { buildInput(inputs.splice(0, middleIndex+1)) }
        </Col>
        <Col md={6} className={css['second_column']}>
          { buildInput(inputs.splice(-middleIndex-1)) }
        </Col>
      </Row>
    )
  } else {
    return buildInput(inputs)
  }
}