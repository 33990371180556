/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getLessons, deleteLesson, generateViewReportData } from '../../../../http/lesson'
import ModalLesson from '../Modal'
import ModalPreview from '../../../../components/common/ModalPreview'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaEye, FaPlus, FaTrash } from "react-icons/fa"
import { SlGraph } from "react-icons/sl"
import { useParams } from "react-router-dom"
import { getCourse } from '../../../../http/course'
import { NotificationManager } from 'react-notifications'
import ViewStatsModal from '../../../../components/common/Modal/ViewStats'

const LessonList = () => {

  const size = 10
  const [currentLesson, setCurrentLesson] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false)
  const [modalViewStatsOpen, setModalViewStatsOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [course, setCourse] = useState({})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const courseId = params.courseId

  const fetchLessons = async () => {
    try{
      const lessons = await getLessons({
        ...( searchable ? { name: searchable } : {}),
        ...(courseId ? { courseId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(lessons)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  const fetchCourse = async () => {
    try{
      const course = await getCourse(courseId)
      setCourse(course)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchCourse()
    document.title = "Aulas - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  useEffect(() => {
    fetchLessons()
  }, [searchable, page])

  const editLesson = (data) => {
    setCurrentLesson(data)
    setModalOpen(true)
  }

  const openViewStats = (data) => {
    setCurrentLesson(data)
    setModalViewStatsOpen(true)
  }

  const openPreview = (data) => {
    setCurrentLesson(data)
    setModalPreviewOpen(true)
  }

  const closeModal = () => {
    setSearchable('')
    fetchLessons()
    setCurrentLesson({})
    setModalOpen(false)
  }

  const closeViewStatsModal = () => {
    setCurrentLesson({})
    setModalViewStatsOpen(false)
  }

  const getViewStats = async (data, generateFile) => {
    setLoading(true)
    try {
      const result = await generateViewReportData({...data, id: currentLesson.id})
      const file = generateFile(result)
      const element = document.createElement("a")
      element.href = URL.createObjectURL(file)
      element.download = `viewReportLesson${currentLesson.id}.csv`;
      document.body.appendChild(element);
      element.click();
    } catch (e) {
      let message = 'Erro no servidor', title = 'Ocorreu um erro'
      if (e?.response?.status === 404) {
        message = 'Sem dados para o intervalo de datas selecionadas'
      }
      NotificationManager.error(message, title)
    } finally {
      setLoading(false)
    }
  }

  const closePreviewModal = () => {
    setSearchable('')
    fetchLessons()
    setCurrentLesson({})
    setModalPreviewOpen(false)
  }

  const addLesson = () => {
    setCurrentLesson({})
    setModalOpen(true)
  }

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja deletar esse aula? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deleteLesson(item.id)
        setSearchable('')
        fetchLessons()
        setLoading(false)
        NotificationManager.success('Aula excluída com sucesso', 'Deletado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Aulas",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' }, 
          { 
            Header: 'Status', 
            accessor: 'status'
          }, 
          { 
            Header: 'Tipo', 
            accessor: 'type',
            Cell: ({value}) => ({
              image: 'Imagem',
              youtube: 'Youtube',
              vimeo: 'Vimeo',
              document: 'Documento',
              text: 'Texto',
            }[value])
            
          }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Button variant="link" onClick={() => editLesson(row.original)}>
                  <FaEdit />
                </Button>
                <Button variant="link" onClick={() => openPreview(row.original)}>
                  <FaEye />
                </Button>
                <Button variant="link" onClick={() => openViewStats(row.original)}>
                  <SlGraph />
                </Button>
                <Button variant="link" onClick={() => deleteItem(row.original)}>
                  <FaTrash />
                </Button>
              </div>
            )
          }
        ]
      }
    ], [deleteItem, editLesson, openPreview, openViewStats]
  );
  
  const breadcrumbs = course ? [
    {name: 'Início', url: '/dashboard'},
    {name: course.name, url: `/dashboard/empresa/${course.companyId}/curso/${course.id}/aulas`},
    {name: "Aulas", url: `/dashboard/curso/${course.id}/aulas`}
  ] : []

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-lesson">
        <Row className={css['pg-lesson__filter']}>
          <Col className={css['pg-lesson__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          {courseId && 
            <Col xs={3} className={css['pg-lesson__filter__actions']}>
              <Button onClick={ addLesson }><FaPlus /> Nova</Button>
            </Col>
          }
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchLessons} 
          size={size}
          className={css["pg-lesson__table"]}
          setPage={setPage}
        />
      </div>
      <ModalLesson 
        show={modalOpen}
        handleClose={closeModal}
        lesson={currentLesson}
        courseId={courseId} 
        setLoading={setLoading}
      />
      <ModalPreview 
        show={modalPreviewOpen}
        content={currentLesson}
        handleClose={closePreviewModal}
      />
      { modalViewStatsOpen ? 
        <ViewStatsModal 
          show={modalViewStatsOpen}
          handleClose={closeViewStatsModal}
          getData={getViewStats}
        />
        : null }
      
    </DashboradLayout>
  )
}

export default LessonList