import React, { useEffect, useState } from 'react'
import { BoxLoading } from 'react-loadingg'

const PAGSEGURO_API = {
  path: 'https://stc.pagseguro.uol.com.br',
  sandbox: 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
  production : 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js',
}

const LoadLibrary = ({children, setPayment}) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const env = process.env.REACT_APP_PAGSEGURO_ENV || ''

    if (!PAGSEGURO_API.hasOwnProperty(env)) {
      throw new TypeError('ENV incorreto! Utilize production ou sandbox');
    }

		if (loaded || document.querySelector(`script[src="${PAGSEGURO_API[env]}"]`)) {
			setLoaded(true)
			setPayment(true)
			return;
		}

		const tag = document.createElement('script');
		tag.type = 'text/javascript';
		tag.src = PAGSEGURO_API[env];
		tag.onload = setLoaded

		document.body.appendChild(tag);
	}, [loaded, setPayment])

	return loaded ? children : <BoxLoading size="large" color="#0CB59F" />

}

export default LoadLibrary