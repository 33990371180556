import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { saveTicket } from '../../../../http/ticket'
import css from '../styles.module.scss'

export default function ModalOpenTicket({ show, handleClose, companyId }) {
  const {
    register, handleSubmit, reset, formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    saveTicket({...data, companyId: companyId}).then(e => {
      NotificationManager.success('Seu suporte foi aberto com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    })
  }

  const close = () => {
    reset()
    handleClose()
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Abrir suporte</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Assunto</Form.Label>
            <Form.Control
              {...register('title', {
                required: { value: true, message: 'O título é obrigatório' },
                minLength: { value: 3, message: 'O título deve ter pelo menos 3 caracteres' },
                maxLength: { value: 255, message: 'O título não pode ser maior que 255 caracteres' },
              })}
              isInvalid={errors.title}
            />
            <Form.Control.Feedback type="invalid">{errors.title?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Mensagem</Form.Label>
            <Form.Control
              {...register('message')}
              as="textarea"
              rows={5}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">Criar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
