import React from 'react'
import { 
  TextField, 
  Radio, 
  RadioGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Form from 'react-bootstrap/Form'
import Dropzone from 'react-dropzone'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null

export default function CustomField({
  required,
  id,
  title,
  type,
  options,
  onChange,
  value,
  errors
}) {

  const onChangeCheckbox = (el) => {
    const checkeds = value?.split(',') || []
    const index = checkeds?.indexOf(el.target.value) || -1
    if (index > -1) { 
      checkeds.splice(index, 1)
    } else {
      checkeds.push(el.target.value)
    }
    onChange(checkeds.join(','))
  }

  const onChangeFile = (value) => {
    onChange(value[0])
  }

  switch (type) {
    case 'string':
    case 'textarea': 
    case 'number':
      return (
        <Form.Group>
          <TextField
            label={title}
            id={`field_${id}`}
            variant="outlined"
            type={ type === 'number' ? 'number' : 'text'}
            value={value || ''}
            className={css['input']}
            onChange={onChange}
            fullWidth
            multiline={type == 'textarea'}
            {...type == 'textarea' ? { as: 'textarea'} : {}}             
            minRows={4}
            required={required}
            placeholder={title}
            error={errors?.message}
            helperText={errors?.message || ''}
          />
        </Form.Group>
      )
    case 'file':
    case 'image':
      return (
        <Form.Group>
          <Form.Label>{title} {required ? "*" : ""}</Form.Label>
          {/* <FileUpload value={value ? [value] : null} onChange={onChangeFile} /> */}
          <Dropzone 
            onDrop={acceptedFiles => onChangeFile(acceptedFiles)}
            multiple={false}
            accept={type === 'image' ? {
              'image/jpeg': [],
              'image/png': []
            } : {
              'application/pdf': [],
            }}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div className={css['dropzone']} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Arraste um arquivo ou clique para escolher</p>
                  <em>{type === 'image' ? 
                    '(Apenas arquivo *.jpeg e *.png serão aceitos)' :
                    '(Apenas arquivo *.pdf será aceito)'
                  }</em>
                </div>
                
                {value && !value?.path ?                 
                  <a href={`${server}/uploads/metadata/${value}`} target="_blank">{value}</a>
                : ( value && value.path ? value.path : '')}
              </section>
            )}
          </Dropzone>
          <Form.Control.Feedback 
            type="invalid"
            style={{display: !!errors ? 'block' : 'none'}}
          >
            {errors?.message}
          </Form.Control.Feedback>
        </Form.Group>
      )
    case 'radio':
      return (
        <Form.Group>
          <FormLabel required={required} component="legend">{title}</FormLabel>
          <RadioGroup
            value={value || ''}
            onChange={onChange}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name={`field_${id}`}
          >
            {options?.map((option) => 
              <FormControlLabel 
                key={option.id}
                value={`${option.id}`} 
                control={<Radio />}
                label={option.value}
              />
            )}
          </RadioGroup>
          <Form.Control.Feedback 
            type="invalid"
            style={{display: !!errors ? 'block' : 'none'}}
          >
            {errors?.message}
          </Form.Control.Feedback>
        </Form.Group>
      )
    case 'checkbox':
      return (
        <Form.Group>
          <FormControl fullWidth variant="standard">
            <FormLabel required={required}>{title}</FormLabel>
            {options?.map((option) => {
              const checkedValue = value?.split(',').some(el => Number(el) === Number(option.id))
              return (<FormControlLabel
                key={option.id}
                onChange={onChangeCheckbox}
                value={option.id}
                checked={checkedValue || false}
                control={<Checkbox />} 
                label={option.value}
              />)
            })}
          </FormControl>
          <Form.Control.Feedback 
            type="invalid"
            style={{display: !!errors ? 'block' : 'none'}}
          >
            {errors?.message}
          </Form.Control.Feedback>
        </Form.Group>
      )
    default:
      return;
  }
}