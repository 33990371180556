import { axios, formData } from './api'


const subscriptionStatus = {
  'active': 'Ativo',
  'cancelled_by_receiver': 'Cancelado pelo vendedor',
  'cancelled_by_sender': 'Cancelado pelo comprador',
  'cancelled': 'Cancelado',
  'canceled': 'Cancelado',
  'failed': 'Falha no pagamento',
  'expired': 'Expirado',
  'pending': 'Aguardando processamento do pagamento',
  'suspended': 'Suspensa',
  'payment_method_change': 'Aguardando troca de cartão',
  'document_pending': 'Documento enviado em análise',
  'document_denied': 'Documento enviado negado',
  'document_not_sended': 'Documento não enviado',
}

const getSubscriptions = async ( params ) => {
  try {
    const subscriptions =  await axios.get(`api/subscriptions`, { params })
    return subscriptions.data
  } catch (error) {
    return error;
  }
}

const getSubscriptionsReport = async ( data ) => {
  try {
    const subscriptions =  await axios.post(`api/subscriptions/report`, 
      { data, responseType: "blob" }
    )
    return subscriptions.data
  } catch (error) {
    return error;
  }
}

const getSubscription = async ( id ) => {
  const subscriptions =  await axios.get(`api/subscription/${id}`)
  return subscriptions.data
}

const changeSubscription = async ( id, data ) => {
  const subscription =  await axios.put(`api/subscription/${id}/change`, { data })
  return subscription.data
}

const getSubscriptionPublic = async ( id ) => {
  const subscriptions =  await axios.get(`public/subscription/${id}`)
  return subscriptions.data
}

const listSubscriptionPublic = async ( id ) => {
  const subscriptions =  await axios.get(`public/subscriptions`)
  return subscriptions.data
}

const saveSubscription = async ( data ) => {
  const subscription =  await formData.post(`api/subscriptions`, data)
  return subscription.data
}

const updateSubscription = async ( id, data ) => {
  const subscription =  await formData.put(`api/subscription/${id}`, data)
  return subscription.data
}

const associateTeacher = async (id, data) => {
  const subscription =  await axios.post(`api/subscription/${id}/associate`, { data })
  return subscription.data
}

const getMySubscriptions = async () => {
  const subscriptions =  await axios.get(`api/subscription/my-subscriptions`)
  return subscriptions.data
}

const getMyCerts = async () => {
  const subscriptions =  await axios.get(`api/subscription/my-certs`)
  return subscriptions.data
}

const importSubscriptions = async ( data ) => {
  const subscription =  await axios.post(`api/subscriptions/import`, { data })
  return subscription.data
}

const resendWelcomeEmail = async (data) => {
  const subscription =  await axios.post(`api/subscriptions/resend`, { data })
  return subscription.data
}

const getOpenQuantity = async ( params ) => {
  try {
    const subscriptions =  await axios.get(`api/subscriptions/open`, { params })
    return subscriptions.data || null
  } catch (e) {
    return null
  }
}

const cancelSubscriptionTransaction = async ( id ) => {
  const subscription =  await axios.delete(`api/subscriptions/cancelTransaction/${id}`)
  return subscription.data || null
}

export { 
  getSubscriptions, 
  saveSubscription, 
  updateSubscription, 
  getSubscription, 
  associateTeacher,
  getMySubscriptions,
  getMyCerts,
  changeSubscription,
  getSubscriptionPublic,
  listSubscriptionPublic,
  importSubscriptions,
  resendWelcomeEmail,
  subscriptionStatus,
  getSubscriptionsReport,
  getOpenQuantity,
  cancelSubscriptionTransaction
}