import React, { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useForm, Controller } from 'react-hook-form'
import { updateAccount } from '../../../http/auth'
import { setUser as setUserService  } from '../../../services/auth'
import SHA256 from "crypto-js/sha256"
import css from './styles.module.scss'
import { Link } from "react-router-dom"
import { NotificationManager } from 'react-notifications'
import UserDataForm from '../../common/UserDataForm'

export default function CompleteDataModal({ setLoading, setOpen, basicInformation, user }) {
  
  const {
    register, handleSubmit, setError, watch, control, setValue, formState: { errors },
  } = useForm()

  useEffect(() => {

    const fields = [
      'address',
      'addressNeighborhood',
      'addressNumber',
      'addressComplement',
      'cellphone',
      'cep',
      'city',
      'cpf',
      'email',
      'name',
      'state',
      'birthDate',
      'isForeign',
      'country',
      'foreignDocument'
    ]
      
    if (user) {
      fields.forEach((field) => setValue(field, user[field]))
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [user, setValue])

  const onSubmit = (data) => {
    setLoading(true)
    if (data.isForeign) {
      data.cpf = null
      data.state = null
    } else {
      data.country = 'BR'
      data.isForeign = false
      data.foreignDocument = null
    }
    updateAccount({
      ...data,
      cpf: data.cpf?.replace(/(\.|-)/g, ''),
      postalCode: data.cep.replace(/-/g, ''),
      cellphone: data.cellphone.replace(/(\(|\)|-|\s)/g, ''),
      password: SHA256(data.password).toString(),
    })
    .then( res => {
      setUserService(res)
      window.location.reload()
    }).catch( e => {
      if (e.response?.status === 422){
        if (e.response?.data?.error?.errCode === 'cpf_exists')
          setError('cpf', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        else if (e.response?.data?.error?.errCode === 'email_exists')
          setError('email', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
      }
      NotificationManager.error('Ocorreu um erro, tente novamente!')
      setLoading(false)
    })
  }

  return (
    <Modal
      show={basicInformation}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['modal-dialog']}
    >
      <Modal.Header>
        <Modal.Title>Complete seu cadastro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div className={css["modal-auth"]}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {basicInformation ? 
                <UserDataForm
                  register={register}
                  errors={errors}
                  control={control}
                  watch={watch} 
                  showPassword={false}
                /> : null
              }
              <p>
                Ao clicar em Enviar você concorda com os nossos&nbsp; 
                <Link to={{ pathname:`/termos` }}>
                  Termos e Condições
                </Link>
              </p>
              <div className={`d-block d-md-none ${css["button"]}`} onClick={setOpen}>
                <p>Voltar</p>
              </div>
              <Button type="submit">Enviar</Button>
            </Form>
          </div>
      </Modal.Body>
    </Modal>
  )
}
