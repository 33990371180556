/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getEnrolls, enrollStatus } from '../../../../http/enroll'
import ModalEnrolled from '../Modal'
import ConfirmationModal from '../ConfirmationModal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaDownload, FaSearch } from "react-icons/fa"
import { RiRefund2Line } from "react-icons/ri";
import ModalReport from '../../../../components/ModalReport'
import { NotificationManager } from 'react-notifications'
import { useParams, Link } from "react-router-dom"
import { getCourse } from '../../../../http/course'
import DateFnsUtils from '@date-io/date-fns'
import { getUser, AllowedRoles } from '../../../../services/auth'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import FormControl from '@material-ui/core/FormControl';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { getEnrolledReport } from '../../../../http/enroll'
const server = process.env.REACT_APP_API_URL || null

const EnrolledList = () => {
  const size = 10
  const [currentEnrolled, setCurrentEnrolled] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalConfirmationOpen, setConfirmationOpen] = useState(false)
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [startField, setStartField] = useState(null)
  const [endField, setEndField] = useState(null)
  const [status, setStatus] = useState(null)
  const [page, setPage] = useState(0)
  const [course, setCourse] = useState({})
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const courseId = params.courseId

  const fetchEnrolleds = async () => {
    try{
      setLoading(true)
      const enrolleds = await getEnrolls({
        ...(courseId ? { courseId } : {}),
        ...( searchable ? { searchable } : {}),
        ...( startField ? { startField } : {}),
        ...( endField ? { endField } : {}),
        ...( status ? { status: status.id } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(enrolleds)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  useEffect(() => {
    document.title = "Inscritos - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])
  
  useEffect(() => {
    fetchEnrolleds()
  }, [page])

  const editEnrolled = (data) => {
    setCurrentEnrolled(data)
    setModalOpen(true)
  }

  const cancelEnrolled = (data) => {
    setCurrentEnrolled(data)
    setConfirmationOpen(true)
  }
  const closeConfirmationModal = () => {
    setSearchable('')
    fetchEnrolleds()
    setCurrentEnrolled({})
    setConfirmationOpen(false)
  }

  const closeModal = () => {
    setSearchable('')
    fetchEnrolleds()
    setCurrentEnrolled({})
    setModalOpen(false)
  }

  const closeReportModal = () => {
    setReportModalOpen(false)
  }

  const columns = useMemo(
    () => {
      let tst = []
      if (getUser()?.role !== AllowedRoles.TEACHER) {
        tst.push({
          Header: 'Ações', 
          Cell: ({row}) => {
            let isCreditCardPagarmeTransaction = row.original?.transactions?.filter(t => t.gatewayId === 2 && t.method === "creditCard").length > 0
            return (
            <div>
              <Button variant="link" onClick={() => editEnrolled(row.original)}>
                <FaEdit />
              </Button>
              {row.original.status === 'paid' && isCreditCardPagarmeTransaction && (
                <Button variant="link" onClick={() => cancelEnrolled(row.original)}>
                  <RiRefund2Line />
                </Button>
              )}
            </div>
          )}
        })
      }
      return [
        {
          Header: "Empresas",
          columns: [
            { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
            { Header: 'Nome', accessor: 'user.name', sortType: 'basic' },
            { Header: 'Voucher', accessor: 'voucher.name', sortType: 'basic'},
            { 
              Header: 'Status', 
              accessor: 'statusList',
              sortType: 'basic',
              Cell: (cell) => {
                let status = enrollStatus[cell.value]
                if (status === "Cancelado" && cell.row.original.history?.length > 0) {
                  return "Cancelado por " + cell.row.original.history[0].user.name
                }

                return status || '' }
            },
            ...tst,
          ]
        }
      ]
    }, []
  );

  const fetchCourse = async () => {
    try{
      const course = await getCourse(courseId)
      setCourse(course)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  const breadcrumbs = course ? [
    {name: 'Início', url: '/dashboard'},
    {name: course.name, url: `/dashboard/empresa/${course.companyId}/curso/${course.id}/aulas`},
    {name: "Inscritos", url: `/dashboard/curso/${course.id}/inscritos`}
  ] : []  

  const options = Object.keys(enrollStatus).map(key => ({name: enrollStatus[key], id: key}))
  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-enrolled">
        <Row>
          <Col md={{ span: 4, offset: 8 }} className={css['pg-enrolled__filter__actions']}>
            <Button href={`${server}/api/enroll/${courseId}/generateList`} target="_blank"><FaDownload /> Lista</Button>
            <Button href={`${server}/api/enroll/${courseId}/generateLabel`} target="_blank"><FaDownload /> Etiquetas</Button>
          </Col>
        </Row>
        <hr/>
        <Row className={css['pg-enrolled__filter']}>
          <Col>
            <TextField
              label='Busque por nome ou CPF' 
              variant="outlined"
              value={searchable || ''}
              onChange={e => setSearchable(e.target.value)}
              fullWidth
            />
          </Col>
          <Col>
            <FormControl fullWidth variant='outlined'>
              <Autocomplete 
                autoComplete={true}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name || ''}
                options={options}
                onChange={(e, value) => setStatus(value)}
                defaultValue={status || ''}
                value={status || ''}
                filterOptions={(data, { inputValue }) => data.filter(({name}) => name?.toLowerCase().includes(inputValue.toLowerCase()) )} 
                renderInput={(props) => {
                  return (
                    <TextField
                      {...props}
                      label="Status"
                      variant="outlined"
                      fullWidth
                    />
                  )
                }}
              />
            </FormControl>
          </Col>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Col>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Inscrito a partir de"
                value={startField}
                onChange={(e) => {if (e?.toString() !== "Invalid Date"){ setStartField(e)}}}
                format="dd/MM/yyyy HH:mm"
                fullWidth={true}
                inputVariant="outlined"
                autoOk={true}                   
                invalidDateMessage="Data inválida"
              />
            </Col>
            <Col>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Inscrito até"
                value={endField || null}
                onChange={(e) => {if (e?.toString() !== "Invalid Date"){ setEndField(e)}}}
                minDate={startField || null}
                customInput
                format="dd/MM/yyyy HH:mm"
                fullWidth={true}
                inputVariant="outlined"
                autoOk={true} 
                invalidDateMessage="Data inválida"
                minDateMessage="A data final deve ser mais que a data final"
              />
            </Col>
          </MuiPickersUtilsProvider>
        </Row>
        <Row className={css['pg-enrolled__filter__actions']}>
          <Col md={12}>
            <Button 
              onClick={fetchEnrolleds}
              target="_blank"
              className={css['pg-enrolled__filter__button']}
            >
              <FaSearch />
            </Button>
            <Button
              className={css['pg-enrolled__filter__button']}
              onClick={() => { setReportModalOpen(true) }}
            >
              <FaDownload /> Relatório
            </Button>
          </Col>
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={ fetchEnrolleds } 
          size={size}
          className={css["pg-enrolled__table"]}
          setPage={setPage}
        />
      </div>
      <ModalEnrolled 
        show={modalOpen}
        handleClose={closeModal}
        enrolled={currentEnrolled}
        course={course}
        setLoading={setLoading}
      />
      <ConfirmationModal 
        show={modalConfirmationOpen}
        handleClose={closeConfirmationModal}
        enrolled={currentEnrolled}
        course={course}
        setLoading={setLoading}
      />
      {
        reportModalOpen ? 
          <ModalReport 
            show={reportModalOpen}
            handleClose={closeReportModal}
            setLoading={setLoading}
            getData={getEnrolledReport}
            userId={getUser()?.id}
            companyId={course.companyId}
            extraFields={[{
              title: "Campos relativos ao curso",
              fields: [
                {field: "course.name", label: "Nome do curso"},
                {field: "voucher.name", label: "Voucher usado"},
                {field: "course.onlyRecorrent", label: "Somente para associados?"},
                {field: "course.certificateRelease", label: "Certificado liberado?"}
              ]
            }, {
              title: "Campos relativos à inscrição",
              fields: [
                {field: "createdAt", label: "Inscrito em"},
                {field: "completeStatus", label: "Status"},
                {field: "transactionValue", label: "Valor pago"},
                {field: "transactionGateway", label: "Gateway"},
                {field: "transactionPaymentMethod", label: "Método de pagamento"},
                {field: "updatedAt", label: "Última atualização"},
                {field: "file", label: "Certificado gerado?"},
              ]
            }]}
            filters={{
              ...( courseId ? { courseId } : {}),
              ...( searchable ? { searchable } : {}),
              ...( startField ? { startField } : {}),
              ...( endField ? { endField } : {}),
              ...( status ? { status: status.id } : {}),
            }}
          />
        : null
      }
    </DashboradLayout>
  )
}

export default EnrolledList