import React, {useState, useEffect} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../../layouts/default'
import { NotificationManager } from 'react-notifications'
import { useParams, Link, useHistory } from "react-router-dom"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { getLesson } from '../../../http/lesson'
import { FaArrowLeft, FaDownload } from 'react-icons/fa'
import Lessons from '../../../assets/lessons.png'
import Vimeo from '@u-wave/react-vimeo'
import YouTube from 'react-youtube'
const server = process.env.REACT_APP_API_URL || null

const Lesson = () => {
	const params = useParams()
  if (!params.lessonId)
		window.href = '/'

	const lessonId = params.lessonId

	const [lesson, setLesson] = useState(null)
	const [loading, setLoading] = useState(true)
  const [setStyle] = useState(null)

  const history = useHistory()

  const getRandomArbitrary = (min, max) => (Math.random() * (max - min) + min)

  const generateRandom = (count = 0) => {
    let sum = count + 1
    if (sum > 3){
      setStyle({
        position:'absolute', 
        zIndex: '5000', 
        top: `${getRandomArbitrary(25, 75)}%`, 
        left: `${getRandomArbitrary(25, 75)}%`, 
        color: 'yellow',
        fontSize: '25px',
        textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"
      })
      sum = 0
    } else {
      setStyle({ display: 'none' })
    }
    var rand = getRandomArbitrary(10, 20)
    setTimeout(() => generateRandom(sum), rand * 1000);
  }

  const showContent = () => {
    switch(lesson.type){
      case 'youtube':
        var video_id = lesson.content.split('v=')[1]
        var ampersandPosition = video_id ? video_id.indexOf('&') : -1
        if(ampersandPosition !== -1)
          video_id = video_id.substring(0, ampersandPosition)
        return (
          <div className={css["video-container"]}>
            <div className={css["video-container__content"]}>
            < YouTube
              videoId={video_id}
              id="ytplayer"
            />
            </div>
            <div className={css["video-container__logo"]}>
              <img src={`${server}${lesson.course.company.logoUrl}`} alt={lesson.course.company.name} />
            </div>
          </div>
        )
      case 'vimeo':
        return (
          <div className={css["video-container"]}>
            <div className={css["video-container__content"]}>
              <Vimeo
                video={lesson.content}
                autoplay={false}
                speed={true}
                width="640" 
                height="360"
              />
            </div>
            <div className={css["video-container__logo"]}>
              <img src={`${server}${lesson.course.company.logoUrl}`} alt={lesson.course.company.name}/>
            </div>
          </div>
        )
      case 'image':
        return (
          <div className={css["image-container"]}>
            <img src={`${server}${lesson.linkAttachment}`} alt={lesson.name} />
          </div>
        )
        case 'text':
          return <div className={css["text-container"]} dangerouslySetInnerHTML={{__html: lesson.content}}></div>
      default:
        return (
          <Container className={css["document-container"]}>
            <a href={`${server}${lesson.linkAttachment}`} target="_blank" rel="noreferrer">
              <FaDownload />
              {lesson.name}
            </a>
          </Container>
        )
    }
  }

  useEffect(()=> {
    document.title = `Aula - Alissem`
    document.getElementsByTagName("META")[1].content=document.title
    fetchLesson()
  }, [])


  const fetchLesson = () => {
    getLesson(lessonId).then(e => {
      setLesson(e.data)
      setLoading(false)
    }).catch((e) => {
      if (e.response?.status === 401){
        history.replace('/')
        return 
      }
      NotificationManager.error('Erro no servidor! Recarregue a página.', 'Ocorreu um erro')
    })
  }

  useState(() => {
    if (lesson && lesson.type === 'youtube')
      generateRandom()
  }, lesson)

	return (
		<LayoutDefault loading={loading}>
      {!loading && lesson &&
        <div className={css['pg-lesson']}>
          <div className={css['pg-lesson__header']}>
            <Container className={css['pg-lesson__header__container']}>
              <img src={Lessons} alt=""/>
              <h2 className={css['pg-lesson__header__title']}>
                {lesson.name} - {lesson.course.name}
              </h2>
              </Container>
          </div>
          <Container>
          {showContent()}
            <Link 
              to={`/curso/${lesson.course.company.permalink}/${lesson.courseId}/conteudo`} 
              className={css['pg-lesson__back']}
            >
              <FaArrowLeft />
              <p>Voltar para o curso</p>
            </Link>
          </Container>

          {lesson.course.partnerships?.length ?
            <div className={css['pg-lesson__partnerships']}>
              <h2>Patrocinadores</h2>
              <Container>
                <Row>
                  {lesson.course.partnerships.map(partnership => (
                    <Col xs={6} md={3} key={partnership.id} className={css['cp-partnership']}>
                      <a href={partnership.link || "#"} target={partnership.link ? "_blank" : ''} rel="noreferrer">
                        <div className={css['cp-partnership__logo']}>
                          <img src={`${server}${partnership.logoUrl}`} alt={partnership.name} />
                        </div>
                      </a>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div> : null
          }
        </div>
      }
		</LayoutDefault>
	)
}

export default Lesson