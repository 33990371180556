import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import { NotificationManager } from 'react-notifications'
import css from './styles.module.scss'
import CompleteMetaData from '../CompleteMetaData'
import { getMyRequirements } from '../../../http/requirement'

export default function CompleteMetaDataModal({ setLoading, setOpen, customInformations, companyIncomplete }) {
  return (
    <Modal
      show={customInformations}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['modal-dialog']}
    >
      <Modal.Header>
        <Modal.Title>Complete as informações adicionais para {companyIncomplete.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CompleteMetaData
          setLoading={setLoading}
          setOpen={setOpen}
          companyIncomplete={companyIncomplete}
        />
      </Modal.Body>
    </Modal>
  )
}
