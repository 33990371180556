import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { changeStatusEnroll } from '../../../../http/enroll'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null


export default function ModalEnrolled({ show, handleClose, enrolled, course, setLoading }) {
  const {
    register, handleSubmit, setValue, reset, formState: { errors },
  } = useForm()

  useEffect(() => {
    const fields = ['status']
    if (enrolled.id) {
      fields.forEach((field) => setValue(field, enrolled[field]))
      if (enrolled.voucher?.sendDocument) 
        setValue('documentStatus', enrolled.documents[0] ? enrolled.documents[0].status : 'pending')

      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [enrolled, setValue])


  const onSubmit = async (data) => {
    setLoading(true)
    changeStatusEnroll(enrolled.id, {
      status: data.status,
      ...(data.documentStatus ? { documentStatus: data.documentStatus } : null)
    }).then(e => {
      NotificationManager.success('O status foi alterado com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'
      NotificationManager.error(description, title)
    }).finally(e=>{
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Alterar status da inscrição</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  {...register('status', {
                    required: { value: true, message: 'O status é obrigatório' },
                  })}
                  isInvalid={errors.type}
                  as="select"
                  custom
                >
                  <option value="waiting_payment">Aguardando Pagamento</option>
                  <option value="paid">Pago</option>
                  <option value="disponible">Disponível</option>
                  <option value="canceled">Cancelado</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
              </Form.Group> 
            </Col>
          </Row>
            {enrolled.voucher?.sendDocument &&
            <div>
              <hr className={css["cp-document-status__line"]}/>
              <h4>Documento exigido:</h4>
              <p>{enrolled.voucher?.documentDescription}</p>
              <Row className={css["cp-document-status"]}>
                <Col xs={12} md={6}>
                  {enrolled.documents && enrolled.documents[0] && enrolled.documents[0].document ? 
                    <a 
                      href={`${server}${enrolled.documents[0].documentUrl}`} 
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ver documento
                    </a> : 
                    <p>Documento não enviado</p>
                  }
                </Col>
                {enrolled.documents && enrolled.documents[0] ?
                  <Col xs={12} md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Status do documento</Form.Label>
                      <Form.Control
                        {...register('documentStatus', {
                          required: { value: true, message: 'O status do documento é obrigatório' },
                        })}
                        isInvalid={errors.type}
                        as="select"
                        custom
                      >
                        <option value="pending">Pendente</option>
                        <option value="approved">Aprovado</option>
                        <option value="denied">Negado</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.documentStatus?.message}</Form.Control.Feedback>
                    </Form.Group> 
                  </Col>
                : null }
              </Row>
            </div>
            }
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">Salvar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
