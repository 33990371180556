import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { saveSpeaker, updateSpeaker } from '../../../../http/speaker'
import css from './styles.module.scss'
import WYSIWYGEditor from '../../../../components/common/WYSIWYG'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const server = process.env.REACT_APP_API_URL || null
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
  },
}));

export default function ModalSpeaker({ show, handleClose, speaker, course, setLoading }) {
  const {
    handleSubmit, setValue, register, reset, getValues, control, formState: { errors },
  } = useForm()
  const classes = useStyles()
  const [photo, setPhoto] = useState(null)
  const [photoPreview, setPhotoPreview] = useState(null)

  
  useEffect(() => {
    const fields = ['name', 'description', 'lecture', 'date', 'endDate', 'local']
    if (speaker.id) {
      fields.forEach((field) => setValue(field, speaker[field]))
      if (speaker.photoUrl)
        setPhotoPreview(`${server}${speaker.photoUrl}`)
      return
    }
    fields.forEach((field) => setValue(field, null, { shouldValidate: false }))
  }, [speaker, setPhotoPreview, setValue])

  useEffect(() => {
    if (show && !speaker.id) {
      setValue('date', new Date(), { shouldValidate: true })
      setValue('endDate', new Date(), { shouldValidate: true })
    }
  }, [show, speaker])

  const onSubmit = async (data) => {
    setLoading(true)
    let result = null
    data.courseId = course.id
    
    var formData = new FormData()
    formData.append("name", data.name)
    formData.append("description", data.description)
    formData.append("courseId", course.id)
    formData.append("lecture", data.lecture)
    formData.append("date", data.date)
    formData.append("endDate", data.endDate)
    formData.append("local", data.local)
    formData.append("photoFile", photo)

    if (speaker.id) result = updateSpeaker(speaker.id, formData)
    else result = saveSpeaker(formData)

    result.then(e => {
      NotificationManager.success('O palestrante foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    setPhotoPreview(null)
    setPhoto(null)
    handleClose()
  }

  const onFileChange = (event) => {
    setPhotoPreview(URL.createObjectURL(event.target.files[0]))
    setPhoto(event.target.files[0])
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Modal.Header closeButton>
          <Modal.Title>{speaker.id ? 'Editar ' : 'Cadastrar '} palestrante</Modal.Title>
        </Modal.Header>
        <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ 
                required: { value: true, message: 'O nome do palestrante é obrigatório' },
                minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
                maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Nome do palestrante"
                  variant="outlined"
                  value={value || ''}
                  onChange={onChange}
                  fullWidth
                  error={errors.name}
                  helperText={errors.name?.message || ''}
                />
              )}
            />
            <Controller
              name="lecture"
              control={control}
              defaultValue=""
              rules={{ 
                required: { value: true, message: 'O nome do palestra é obrigatório' },
                minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
                maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Nome da palestra"
                  variant="outlined"
                  value={value || ''}
                  onChange={onChange}
                  fullWidth
                  error={errors.lecture}
                  helperText={errors.lecture?.message || ''}
                />
              )}
            />
            <Row>
              <Col>
               <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'A data inicial da palestra é obrigatória' }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Data Inicial"
                      value={value}
                      onChange={onChange}
                      onError={console.log}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true}                   
                      invalidDateMessage="Data inválida"
                      error={errors.date}
                      helperText={errors.date?.message || ''}
                    />
                  )}
                />
              </Col>
              <Col>
               <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'A data final da palestra é obrigatória' }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Data final"
                      value={value}
                      onChange={onChange}
                      onError={console.log}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true}                   
                      invalidDateMessage="Data inválida"
                      error={errors.endDate}
                      helperText={errors.endDate?.message || ''}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  name="local"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Instituição de origem"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                    />
                  )}
                />
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Anexar arquivo</Form.Label>
              <Form.Control type="file" 
                {...register('file')}
                accept="image/*"
                onChange={onFileChange} 
                isInvalid={errors.file}
              />
              {photoPreview &&
                <div className={css['cp-modal-speaker__preview__container']}>
                  <div className={css['cp-modal-speaker__preview']}>
                    <img src={photoPreview} alt="" />
                  </div>
                </div>
              }
              <Form.Control.Feedback type="invalid">{errors.file?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Descrição</Form.Label>
              <Controller
                render={ () => 
                  <WYSIWYGEditor 
                    value={getValues('description')}
                    onChange={value => {
                      setValue('description', value)
                    }} 
                  /> 
                }
                name='description'
                control={control}
                //defaultValue=''
              />
            </Form.Group>
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">{speaker.id ? 'Salvar' : 'Cadastrar'}</Button>
          </Modal.Footer>
        </Form>
      </MuiPickersUtilsProvider>
    </Modal>
  )
}
