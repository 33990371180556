import axios, { formData } from './api';

export const login = async (params) => {
  try {
    return axios.post('auth/login', params);
  } catch (error) {
    return error;
  }
}

export const createAccount = async (params) => {
  try {
    return axios.post('auth/signup', params);
  } catch (error) {
    return error;
  }
}

export const resetPassword = async (data) => {
  const pass =  await axios.post(`auth/resetPassword`, { data })
  return pass.data
}

export const resetPasswordAdmin = async (userId, data) => {
  const pass =  await axios.post(`api/user/${userId}/resetPassword`, { data })
  return pass.data
}


export const updateAccount = async (params) => {
  const pass =  await axios.put(`api/user`, params)
  return pass.data
}

export const updateMetadata = async (params) => {
  const pass =  await formData.put(`api/user/metadata`, params)
  return pass.data
}

export const updateSecurity = async (params) => {
  const pass =  await axios.put(`api/user/security`, params)
  return pass.data
}

export const retrieveMyData = async () => {
  const pass =  await axios.get(`api/user`)
  return pass.data
}

export const recoverPassword = async (data) => {
  const pass =  await axios.post(`auth/recover`, { data })
  return pass.data
}


export const logout = async () => {
  const logout =  await axios.post(`api/logout`)
  return logout.data
}