import React from 'react'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null

export default function CustomField({
  title,
  type,
  options,
  value,
  required,
}) {
  let content
  if (!value || value === 'undefined') {
    content = "Não respondida"
  } else {
    switch (type) {
      case 'string':
      case 'textarea': 
      case 'number':
        content = value
        break
      case 'file':
      case 'image':
        content = <a href={`${server}/uploads/metadata/${value}`} target="_blank">{value}</a>
        break
      case 'radio':
        content = options?.find((option) => `${option.id}` === value)?.value
        break
      case 'checkbox':
        content = options?.filter((option) => 
          value?.split(',').some(el => Number(el) === Number(option.id))
        ).map(({value}) => value).join(", ")
        break
    }
  }

  return (
    <div className={css['cp-requirements-read']}>
      <p className={css['cp-requirements-read__title']}>{title}{required ? '*' : ''}</p>
      <p className={css['cp-requirements-read__answer']}>{content}</p>
    </div>
  )
}