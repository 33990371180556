import React, {useEffect, useState} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../../layouts/default'
import { getTickets } from '../../../http/ticket'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import TableView from 'react-bootstrap/Table'
import { FaEye } from 'react-icons/fa'
import Modal from './modal'

const MyTickets = () => {
	const [tickets, setTickets] = useState(null)
	const [loading, setLoading] = useState(true)
	const [modal, setModal] = useState(false)
	const [currentTicket, setCurrentTicket] = useState(null)


	const fetchTickets = async () => {
    getTickets().then(e => {
      setTickets(e.rows)
      setLoading(false)
    })
	}

	const handleModal = ticket => {
		setCurrentTicket(ticket)
		setModal(prevState => !prevState)
	}

	useEffect(() => {
    document.title = `Meus suportes - Alissem`
    document.getElementsByTagName("META")[1].content=document.title
		fetchTickets()
	}, [])

	return (
		<LayoutDefault loading={loading}>
			{tickets?.length > 0 ? (
				<div className={css['pg-my-tickets']}>
					<Container>
						<Row>
							<Col md={4} className={css['cp-my-tickets']}>
								<div className={css['cp-my-tickets__content']}>
									<div className={css['cp-my-tickets__content__title']}>
										<h2>Meus tickets</h2>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<div className={css['cp-my-tickets']}>
						<Container >
              <Row>
							<TableView striped bordered hover responsive >
								<thead>
									<tr>
										<td>#</td>
										<td>Título</td>
										<td>Empresa</td>
										<td>Status</td>
										<td>Ação</td>
									</tr>
								</thead>
								<tbody>
									{tickets.map((ticket, i) => (
										<tr>
											<td>{ticket.id}</td>
											<td>{ticket.title}</td>
											<td>{ticket.company?.name.substr(0, 30)}</td>
											<td>{ticket.answer ? "Respondido" : "Aberto"}</td>
											<td><FaEye onClick={() => handleModal(ticket)} /></td>
										</tr>
									))}
								</tbody>
      				</TableView>
              </Row>
						</Container>
					</div>
				</div>				
			) : (
        <div className={css['pg-my-tickets--nothing']}>
          <p>Você ainda não tem nenhum ticket aberto.</p>
        </div>
      )}
			{currentTicket ?
				<Modal show={modal} handleClose={handleModal} ticket={currentTicket} /> : null
			}
		</LayoutDefault>
	)
}

export default MyTickets