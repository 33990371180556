/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { NotificationManager } from 'react-notifications'
import {
  Radio, 
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import Form from 'react-bootstrap/Form'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { useLazyQuery,  } from '@apollo/client'
import { GET_COMPANY_REQUIREMENTS } from '../../graphql/queries/company'

export default function ModalReport({ show, handleClose, filters, setLoading, getData, extraFields, userId, companyId }) {

  const [fields, setFields] = useState(null)
  const [type, setType] = useState('csv')

  const [getRequirementsCompany, { loading: loadingData, data: companyData }] = 
    useLazyQuery(GET_COMPANY_REQUIREMENTS, {
      variables: {
        id: Number(userId),
        companyId: Number(companyId)
      },
      fetchPolicy: 'network-only'
    });
  
  useEffect(() => {
    setLoading(true)
    getRequirementsCompany()
  }, [])

  useEffect(() => {
    setLoading(loadingData)
  }, [loadingData])

  const close = () => {
    handleClose()
  }

  const generateReport = async () => {
    try {
      setLoading(true)
      const report = await getData({ filters, fields })
      const worksheet = XLSX.utils.json_to_sheet(report)

      if (type === 'xlsx') {
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFile(workbook, `report_${new Date().getTime()}.xlsx`)
      } else {
        const CSVData = XLSX.utils.sheet_to_csv(worksheet)
        saveAs(new Blob([CSVData],{type:"application/octet-stream"}), `report_${new Date().getTime()}.csv`);
      }
    } catch (e) {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    } finally {
      setLoading(false)
    }
  }

  const onChangeCheckbox = (el) => {
    let checkeds = fields?.split(',') || []
    const index = checkeds?.indexOf(el.target.value)
    if (index > -1) { 
      checkeds = checkeds.filter((checked) => checked !== el.target.value)
    } else {
      checkeds.push(el.target.value)
    }
    setFields(checkeds.length > 0 ? checkeds.join(',') : null)
  }

  const userFields = [
    {field: "user.id", label: "ID"},
    {field: "user.name", label: "Nome"},
    {field: "user.email", label: "Email"},
    {field: "user.cpf", label: "CPF"},
    {field: "user.address", label: "Endereço"},
    {field: "user.addressNumber", label: "Número"},
    {field: "user.addressComplement", label: "Complemento"},
    {field: "user.addressNeighborhood", label: "Bairro"},
    {field: "user.cep", label: "CEP"},
    {field: "user.city", label: "Cidade"},
    {field: "user.state", label: "Estado"},
    {field: "user.cellphone", label: "Telefone"},
    {field: "user.birthDate", label: "Data de nascimento"},
    {field: "user.isForeign", label: "É estrangeiro?"},
    {field: "user.country", label: "País"},
    {field: "user.foreignDocument", label: "Documento de estrangeiro"},
  ]
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Gerar relatório</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <FormLabel required={true} component="legend">Tipo de arquivo exportado</FormLabel>
          <RadioGroup
            value={type}
            onChange={(el) => setType(el.target.value)}
          >
            <FormControlLabel 
              key="csv"
              value="csv" 
              control={<Radio />}
              label="CSV"
            />
            <FormControlLabel 
              key="xlsx"
              value="xlsx"
              control={<Radio />}
              label="Planilha excel"
            />
          </RadioGroup>
        </Form.Group>
        <Form.Group>
          <FormLabel required={true} component="legend">Campos relativos ao usuário</FormLabel>
          {userFields?.map((field) => {
            return (
              <FormControlLabel
                key={field.field}
                onChange={onChangeCheckbox}
                value={field.field}
                checked={fields?.split(',').some(el => el === field.field) || false}
                control={<Checkbox />} 
                label={field.label}
              />
            )
          })}
          { companyData?.company?.requirements?.length > 0 ? 
            <div>
              <FormLabel required={true} component="legend">Campos adicionais da empresa relativos ao usuário</FormLabel>
              { companyData?.company?.requirements?.map((field) => {
                return (
                  <FormControlLabel
                    key={field.field}
                    onChange={onChangeCheckbox}
                    value={`requirement_${field.id}`}
                    checked={fields?.split(',').some(el => el === `requirement_${field.id}`) || false}
                    control={<Checkbox />} 
                    label={field.title}
                  />
                )
              })}
            </div>
            : null
          }
          {extraFields?.map(({title, fields: fieldsBox}) => (
            <div>
              <FormLabel required={true} component="legend">{title}</FormLabel>
              {fieldsBox?.map((field) => {
                return (
                  <FormControlLabel
                    key={field.field}
                    onChange={onChangeCheckbox}
                    value={field.field}
                    checked={fields?.split(',').some(el => el === field.field) || false}
                    control={<Checkbox />} 
                    label={field.label}
                  />
                )
              })}
            </div>
          ))}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={close}>
          Fechar
        </Button>
        <Button variant="primary" onClick={generateReport}>
          Gerar relatório
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
