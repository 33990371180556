import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null

const Speaker = ({ speaker }) => {
  const [show, setShow] = useState(false)
  const { name, photoUrl, description, local } = speaker

  return (
    <div>
      <div className={css["speaker__action"]} onClick={() => setShow(prevState => !prevState)}>
        <p className={css["speaker__action__name"]}>
          { name } { local ? <span className={css["speaker__action__local"]}> - { local }</span> : ''}
        </p>
        { show ? <IoMdRemoveCircle /> : <IoMdAddCircle /> }
      </div>
      {show &&
        <div className={css["speaker__more"]}>
          {photoUrl ? (
            <div className={css["speaker__more__person"]}>
              <img src={`${server}${photoUrl}`} alt={name} />
            </div>
          ) : null }
          <div>{ReactHtmlParser(description)}</div>
        </div>
      }
    </div>
  )
}

export default Speaker