import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import SHA256 from "crypto-js/sha256"
import { resetPasswordAdmin } from '../../../../http/auth'
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function ChangePassword({ show, handleClose, setLoading, userId }) {
  const {
    register, handleSubmit, watch, reset, formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    setLoading(true)
    resetPasswordAdmin(userId, {
      newPassword: SHA256(data.password).toString(),
    })
    .then(res => {
      close()
      NotificationManager.success("Senha alterada com sucesso!")
    }).catch(e => {
      let message = "Ocorreu um erro"
      if (e.response?.data?.error)
        message = e.response.data.error.message
      NotificationManager.error(message, 'Ocorreu um erro')
    })
    .finally(() => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Alterar senha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form key={"update"} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Control
              {...register('password', {
                required: { value: true, message: 'A nova senha é obrigatória' },
                minLength: { value: 6, message: 'O nova senha deve ter pelo menos 6 caracteres' },
                maxLength: { value: 255, message: 'O nova senha não pode ser maior que 255 caracteres' },
              })}
              placeholder="Nova senha"
              type="password"
              isInvalid={errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              {...register('passwordConfirm', {
                required: { value: true, message: 'A confirmação de senha é obrigatória' },
                validate: { value: value => value === watch('password') || "As senhas não são iguais" }
              })}
              placeholder="Confirmar senha"
              type="password"
              isInvalid={errors.passwordConfirm}
            />
            <Form.Control.Feedback type="invalid">{errors.passwordConfirm?.message}</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit">Alterar senha</Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
