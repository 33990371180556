import React, { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useForm, Controller } from 'react-hook-form'
import { updateMetadata } from '../../http/auth'
import css from './styles.module.scss'
import { Link } from "react-router-dom"
import { NotificationManager } from 'react-notifications'
import CustomField from '../common/Metadata/customField'

export default function CompleteMetaData({ setLoading, setOpen, companyIncomplete, nextStep }) {
  
  const { handleSubmit, control, formState: { errors } } = useForm()
  
  const onSubmit = (data) => {
    setLoading(true)
    var formData = new FormData()
    Object.entries(data).map(([key, value]) => {
      if (value) {
        formData.append(key, value)
      }
    })

    updateMetadata(formData)
      .then(async (res) => {
        NotificationManager.success('Informações atualizadas com sucesso')
        if (nextStep) {
          nextStep()
          return
        }          
        window.location.reload()
      }).catch(e => {
        let message = "Ocorreu um erro"
        if (e.response?.data?.error)
          message = e.response.data.error
        NotificationManager.error(message, "Ocorreu um erro")
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className={css["modal-auth"]}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {companyIncomplete && companyIncomplete.incompleteFields ?
          <div>
            { companyIncomplete?.incompleteFields?.map((field) => (
              <Controller
                control={control}
                name={`${field.id}`}
                key={field.id}
                rules={{
                  required: { value: field.required, message: 'Este campo é obrigatório' }
                }}
                render={({ field: { onChange, value } }) => (
                  <CustomField
                    id={field.id}
                    key={field.id}
                    required={field.required}
                    title={field.title}
                    type={field.type}
                    options={field.options}
                    onChange={onChange}
                    value={value}
                    errors={errors[field.id]}
                  />
                )}
                /> 
              ))
            }
          </div>
          : null
        }
        <div className={`d-block d-md-none ${css["button"]}`} onClick={setOpen}>
          <p>Voltar</p>
        </div>
        <Button type="submit">Enviar</Button>
      </Form>
    </div>
  )
}
