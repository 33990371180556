import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from './client'
import { getToken } from '../services/auth'


const Provider = ({ children }) => {
  const token = getToken()

  return (
    <ApolloProvider client={client.graphql({ token })}>{children}</ApolloProvider>
  )
}

export default Provider
