import React from 'react'

import css from './styles.module.scss'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import logo from '../../../../assets/Alissem.png'
import { Link } from 'react-router-dom'

const Footer = ({ showBack = true }) => {
	return (
    <Container fluid>
      <Row className={`${css['cp-portal-footer']}`}>
        <Col>
          <p>Desenvolvido por: 
          <Link to="/" className={`${css['cp-portal-footer__logo']}`}>
            <img src={logo} alt="" />
          </Link>
        </p>
        </Col>
        <Col>
          <p>Contato: +55 (15) 99844-3644 | (15) 99833-3633</p>
        </Col>
      </Row>
    </Container>
	)
}

export default Footer