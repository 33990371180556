import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isAuthorized } from '../services/auth';

const PrivateRoute = ({ component: Component, roles, exists = true, ...rest }) => {
  return (
  <Route
    {...rest}
    render={(props) => (
      exists && isAuthenticated() === true && isAuthorized(roles) ? 
      <Component {...props} /> 
      :
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    )}
  />
)
      };

export default PrivateRoute;
