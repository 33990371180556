import { axios, formData } from './api'

const getRequirements = async ( params ) => {
  try {
    const requirements =  await axios.get(`/api/company/${params.companyId}/requirements`, { params })
    return requirements.data
  } catch (error) {
    return error;
  }
}

const getRequirement = async ( companyId, id ) => {
  const requirements =  await axios.get(`/api/company/${companyId}/requirements/${id}`)
  return requirements.data
}

const saveRequirement = async ( data ) => {
  const plan =  await axios.post(`/api/company/${data.companyId}/requirements`, data)
  return plan.data
}

const updateRequirement = async ( data ) => {
  const plan =  await axios.put(`/api/company/${data.companyId}/requirements`, data)
  return plan.data
}

const getMyRequirements = async ( companyId, companyRelation = false) => {
  const requirements =  await axios.get(`api/company/${companyId}/my-requirements?companyRelation=${companyRelation}`)
  return requirements.data
}

const deleteRequirement = async ( companyId, id ) => {
  const plan =  await axios.delete(`/api/company/${companyId}/requirements/${id}`)
  return plan.data
}

export { 
  getRequirements,
  deleteRequirement,
  saveRequirement, 
  updateRequirement, 
  getRequirement, 
  getMyRequirements
}