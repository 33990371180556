import React, { useEffect, useState } from 'react'
import LayoutDefault from '../../layouts/default'
import Container from 'react-bootstrap/Container'
import css from './styles.module.scss'
import { myOrders } from '../../http/payment'  
import { DateTime } from 'luxon'

const OrdersPage = () => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  

  const fetchOrders = async () => {
    setLoading(true)
    let ordersFound = await myOrders()
    setOrders(ordersFound)
    setLoading(false)
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  const STATUS = {
    waiting_payment: "Aguardando pagamento",
    analysing: "Analisando pagamento",
    paid: "Pago",
    disponible: "Pago",
    in_dispute: "Em disputa",
    refunded: "Reembolsado",
    canceled: "Cancelado",
    active: "Ativa",
    cancelled_by_receiver: "Cancelado pelo vendedor",
    cancelled_by_sender: "Cancelado pelo comprador",
    cancelled: "Cancelado",
    failed: "Falha no pagamento",
    expired: "Expirado",
    pending: "Pendente",
    suspended: "Suspenso",
    payment_method_change: "Aguardando mudança de pagamento"
  }


  return (
		<LayoutDefault loading={loading}>
      <div className={css['pg-orders']}>
        <Container>
          <table>
            <tr>
              <th>Tipo</th>
              <th>Data</th>
              <th>Descrição</th>
              <th>Status</th>
            </tr>
            {
              orders.map(order => (
                <tr key={`${order.type}_${order.id}`}>
                  <td>{ order.type === 'subscription' ? 'Associação' : 'Curso'  }</td>
                  <td>{ DateTime.fromJSDate(new Date(order.createdAt)).toFormat("dd/M/yyyy HH:mm") }</td>
                  <td>{ order.type === 'subscription' ?
                    `${order.plan.name} - ${order.plan.company.name}` : 
                    order.course.name }</td>
                  <td>{ STATUS[order.status] }</td>
                </tr>
              ))
            }
          </table>
        </Container>
      </div>
    </LayoutDefault>
  )
}

export default OrdersPage