import React from 'react'
import css from './styles.module.scss'
import Lecture from '../Lecture'

const Day = ({ day }) => {
  return (
    <div className={css["day"]}>
      <p className={css["day__title"]}>{day.date}</p>
      {day.speakers.map( (lecture, index) => (
        <Lecture key={index} lecture={lecture} day={day.date} />
      ))}
    </div>
  )
}

export default Day