import React, { useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { sendNow } from '../../../../http/email'
import css from './styles.module.scss'
import EmailEditor from 'react-email-editor'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/style.css'


export default function ModalSendNow({ show, handleClose, setLoading }) {
  const {
    register, handleSubmit, watch, reset, formState: { errors },
  } = useForm()

  const emailEditorRef = useRef(null)
  const [emails, setEmails] = useState([])
  const [emailsError, setEmailsError] = useState(null)
  const emailsRef = useRef(null)


  const exportHtml = (callback = () => {}, dataForm = null) => {
    emailEditorRef.current.editor.exportHtml((data) => callback(dataForm, data.html))
  }

  const onSubmit = async (data, html) => {
    setLoading(true)
    sendNow({
      subject: data.subject,
      html: html,
      forAll: data.forAll,
      emails
    }).then(e => {
      NotificationManager.success('O email foi enviado com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro um erro ao enviar os emails')
    }).finally((e) => {
      setLoading(false)
    })
  }


  const handleSave = (data) => {
    if ((!emails || emails.length === 0) && !data.forAll){
      setEmailsError("Insira pelo menos um destinatário!")
      emailsRef.current.emailInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }
    exportHtml(onSubmit, data)    
  }

  const close = () => {
    reset()
    handleClose()
  }

  return (
    <Modal
      show={true}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['modal-dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar email</Modal.Title>
      </Modal.Header>
      <div className={css["onlyMobile"]}>
        <Modal.Body>
          Não suportado nessa resolução.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Fechar
          </Button>
        </Modal.Footer>
      </div>
      <Form onSubmit={handleSubmit(handleSave)} className={css["onlyDesktop"]}>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  {...register('subject', {
                    required: { value: true, message: 'O assunto é obrigatório' },
                    minLength: { value: 3, message: 'O assunto deve ter pelo menos 3 caracteres' },
                    maxLength: { value: 255, message: 'O assunto não pode ser maior que 255 caracteres' },
                  })}
                  placeholder="Assunto"
                  isInvalid={errors.subject}
                />
                <Form.Control.Feedback type="invalid">{errors.subject?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Check 
                {...register('forAll')}
                type="switch"
                id="choose-receiver"
                label="Enviar para todos usuários cadastrados?"
              />
            </Col>
          </Row>
          {!watch("forAll") && (
            <Form.Group className="mb-3">
              <ReactMultiEmail
                ref={emailsRef}
                placeholder="Destinatários"
                emails={emails}
                onChange={(_emails) => setEmails(_emails)}
                validateEmail={email => isEmail(email)}
                getLabel={( email, index, removeEmail ) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
                isInvalid={emailsError}
              />
              {emailsError && <div className="invalid-feedback" style={{display: 'block'}}>{emailsError}</div>}
            </Form.Group>
          )}
          <EmailEditor ref={emailEditorRef} options={{locale: 'pt-BR'}} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">Enviar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
