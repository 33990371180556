import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { validateCnpj, validatePermalink } from '../../../../util/validate'
import { saveCompany, updateCompany } from '../../../../http/company'
import { TextField, FormControlLabel, Switch, FormControl, Select, InputLabel, MenuItem, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import { validateEmail } from '../../../../util/validate'
import css from './styles.module.scss'
import Loader from '../../../../components/common/Loader'
import { checkCredentials } from '../../../../http/payment'
import ColorPicker from '../../../../components/common/ColorPicker';

const server = process.env.REACT_APP_API_URL || null
const { REACT_APP_URL } = process.env
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
  },
}));

export default function ModalCompany({ show, handleClose, company, setLoading, paymentMethods }) {
  const {
    handleSubmit, setValue, reset, control, setError, getValues, watch, formState: { errors },
  } = useForm()
  const classes = useStyles()
  const [logo, setLogo] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)
  const [loadingCredentials, setLoadingCredentials] = useState(false)

  const checkCredential = async () => {
    setLoadingCredentials(true)
    try{
      await checkCredentials({
        ...(getValues("currentPayment.type") == 1 ? { 
          paymentMethod: "PagSeguro",
          token: getValues("currentPayment.pagseguro.tokenCredential").trim(),
          email: getValues("currentPayment.pagseguro.userCredential").trim()
        } : {
          paymentMethod: "Pagarme",
          email: getValues("currentPayment.pagarme.userCredential").trim(),
          token: getValues("currentPayment.pagarme.userPassword").trim()
        })
      })
      NotificationManager.success("Conexão bem sucedida")
    } catch (e) {
      NotificationManager.error("Houve um problema na conexão ou na credencial, confirme e tente novamente")
      console.error(e)
    }
    setLoadingCredentials(false)
  }

  useEffect(() => {
    const fields = ['name', 'cnpj', 'permalink', 'hasAssociation', 'primaryColor', 'secondaryColor']
    if (company.id) {
      fields.forEach((field) => setValue(field, company[field], { shouldValidate: true }))
      if (company.logo) setLogoPreview(`${server}${company.logo}`)
      let activeMethod = null;
      company.paymentGateways?.forEach((paymentGateway) => {
        if (paymentGateway.paymentCompanyGateway.current) {
          activeMethod = paymentGateway.id
        }
        setValue(`currentPayment.${paymentGateway.name.toLowerCase()}.userCredential`, paymentGateway.paymentCompanyGateway.userCredential, { shouldValidate: true })
        setValue(`currentPayment.${paymentGateway.name.toLowerCase()}.tokenCredential`, paymentGateway.paymentCompanyGateway.userToken, { shouldValidate: true })
        setValue(`currentPayment.${paymentGateway.name.toLowerCase()}.userPassword`, paymentGateway.paymentCompanyGateway.userPassword, { shouldValidate: true })
      })
      setValue(`currentPayment.type`, activeMethod, { shouldValidate: true })
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [company, setLogoPreview, setValue])

  const onSubmit = async (data) => {
    let result = null
    setLoading(true)

    var formData = new FormData()
    formData.append("name", data.name)
    formData.append("cnpj", data.cnpj)
    formData.append("currentPayment.pagseguro.tokenCredential", data.currentPayment?.pagseguro?.tokenCredential?.trim())
    formData.append("currentPayment.pagseguro.userCredential", data.currentPayment?.pagseguro?.userCredential?.trim())
    formData.append("currentPayment.pagarme.userPassword", data.currentPayment?.pagarme?.userPassword?.trim())
    formData.append("currentPayment.pagarme.userCredential", data.currentPayment?.pagarme?.userCredential?.trim())
    formData.append("paymentMethod", data.currentPayment.type)
    formData.append("permalink", data.permalink)
    formData.append("hasAssociation", data.hasAssociation)
    formData.append("primaryColor", data.primaryColor)
    formData.append("secondaryColor", data.secondaryColor)
    formData.append("logoFile", logo)

    if (company.id) result = updateCompany(company.id, formData)
    else result = saveCompany(formData)

    result.then(e => {
      NotificationManager.success('A empresa foi salva com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'
      if (e.response?.status === 422){
        description = e.response?.data?.error?.message
        if (e.response?.data?.error?.errCode === 'exists')
          setError('cnpj', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        else if (e.response?.data?.error?.errCode === 'permalink_exists')
          setError('permalink', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
      }      
      NotificationManager.error(description, title)
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    setLogoPreview(null)
    setLogo(null)
    handleClose()
  }

  const onFileChange = (event) => {
    setLogoPreview(URL.createObjectURL(event.target.files[0]))
    setLogo(event.target.files[0])
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      {loadingCredentials ? <Loader /> : null }
      <Modal.Header closeButton>
        <Modal.Title>{company.id ? 'Editar empresa' : 'Cadastrar empresa'}</Modal.Title>
      </Modal.Header>
      <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: { value: true, message: 'O nome da empresa é obrigatório' },
                  minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
                  maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Nome"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    error={errors.name}
                    helperText={errors.name?.message || ''}
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <NumberFormat
                    customInput={TextField}
                    format='##.###.###/####-##'
                    value={value || ''}
                    variant="outlined"
                    fullWidth
                    label="CNPJ"
                    error={errors.cnpj}
                    helperText={errors.cnpj?.type === 'value' ? 'CNPJ inválido' : errors.cnpj?.message}
                    onValueChange={(v) => {
                      onChange(v.value)
                    }}
                  />
                )}
                rules={{ 
                  required: { value: true, message: 'O CNPJ é obrigatório' },
                  validate: { value: validateCnpj },
                }}
                name="cnpj"
                control={control}
              />
            </Col>
          </Row>            
          <Row>
            <Col xs={12} md={6}>
              <Controller
                name="permalink"
                control={control}
                defaultValue=""
                rules={{
                  minLength: { value: 3, message: 'O permalink deve ter pelo menos 3 caracteres' },
                  maxLength: { value: 255, message: 'O permalink não pode ser maior que 255 caracteres' },
                  validate: { value: validatePermalink },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Permalink"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    error={errors.permalink}
                    helperText={
                      errors.permalink?.type === 'value' ? 
                        "Permalink inválido": 
                        (errors.permalink?.message || '')
                      }
                  />
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Controller
                name="hasAssociation"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Switch checked={value || false} onChange={onChange} />}
                    label="Tem sistema de assinatura?"
                  />
                )}
              />
            </Col>
            <Col xs={6} md={3}>
              <FormControl>
                <Controller
                  name="primaryColor"
                  control={control}
                  defaultValue=""
                  variant="outlined"
                  render={({ field: { onChange, value } }) => (
                    <FormControl>
                      <label htmlFor="type">Cor principal</label>
                      <ColorPicker 
                        value={value}
                        defaultValue='#808080'
                        onChange={onChange}
                      />
                    </FormControl>
                  )}
                />
              </FormControl>
            </Col>
            <Col xs={6} md={3}>
              <Controller
                name="secondaryColor"
                control={control}
                defaultValue=""
                variant="outlined"
                render={({ field: { onChange, value } }) => (
                  <FormControl>
                    <label htmlFor="type">Cor secundária</label>
                    <ColorPicker 
                      value={value}
                      defaultValue='#808080'
                      onChange={onChange}
                    />
                  </FormControl>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Logo</Form.Label>
                <Form.Control type="file" onChange={onFileChange} accept="image/*"/>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              {logoPreview &&
                <div className={css['cp-modal-company__preview']}>
                  <img src={logoPreview} alt="" />
                </div>
              }
            </Col>
          </Row>
          <hr />
          <h4>Configurações de pagamento</h4>
          <Row>
            <Col xs={12} md={6}>
              <Controller
                name="currentPayment.type"
                control={control}
                defaultValue=""
                rules={{ 
                  required: { value: true, message: 'O gateway de pagamento é obrigatório'},
                }}
                render={({ field: { onChange, value } }) => (
                  <FormControl 
                    className={classes.formControl} 
                    error={errors.currentPayment?.type}
                    fullWidth
                    variant="outlined"
                  >
                    <InputLabel htmlFor="currentPayment.type">Gateway de pagamento</InputLabel>
                    <Select
                      label="Gateway de pagamento"
                      value={value || ''}
                      fullWidth
                      onChange={onChange}
                      inputProps={{
                        id: 'currentPayment.type',
                      }}
                    >
                      <MenuItem value='1'>Pagseguro</MenuItem>
                      <MenuItem value='2'>Pagarme</MenuItem>
                    </Select>
                    <FormHelperText>{errors.currentPayment?.type?.message || ''}</FormHelperText>
                  </FormControl>
                )}
              />   
            </Col>
          </Row>

          {watch('currentPayment.type') == 1 ? (<>
            <Row>
              <Col>
                <Controller
                  name="currentPayment.pagseguro.tokenCredential"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: { value: watch('currentPayment.type') == 1, message: 'O código do pagseguro é obrigatório' },
                    minLength: { value: 3, message: 'O código do pagseguro deve ter pelo menos 3 caracteres' },
                    maxLength: { value: 255, message: 'O código do pagseguro não pode ser maior que 255 caracteres' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Chave do Pagseguro"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={errors.currentPayment?.pagseguro?.tokenCredential}
                      helperText={errors.currentPayment?.pagseguro?.tokenCredential?.message}
                    />
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <Controller
                  name="currentPayment.pagseguro.userCredential"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: { value: watch('currentPayment.type') == 1, message: 'O email do pagseguro é obrigatório' },
                    minLength: { value: 3, message: 'O email do pagseguro deve ter pelo menos 3 caracteres' },
                    maxLength: { value: 255, message: 'O email do pagseguro não pode ser maior que 255 caracteres' },
                    validate: { value: validateEmail }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Email do Pagseguro"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={errors.currentPayment?.pagseguro?.userCredential}
                      helperText={errors.currentPayment?.pagseguro?.userCredential?.type === 'value' ? 'Email inválido' : errors.currentPayment?.pagseguro?.userCredential?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            { REACT_APP_URL && company.id && 
              <p><b>URL de notificação PagSeguro: </b> {REACT_APP_URL}/transaction/{company.id}/notify</p>
            }
            <Button 
              variant="info" 
              disabled={loadingCredentials} 
              onClick={checkCredential}
            >
            Checar credenciais
            </Button>
          </>)
            : null}
            {watch('currentPayment.type') == 2 ? (<>
            <Row>
              <Col>
                <Controller
                  name="currentPayment.pagarme.userCredential"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: { value: watch('currentPayment.type') == 2, message: 'O usuário do Pagar.me é obrigatório' },
                    minLength: { value: 3, message: 'O usuário do Pagar.me deve ter pelo menos 3 caracteres' },
                    maxLength: { value: 255, message: 'O usuário do Pagar.me não pode ser maior que 255 caracteres' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Usuário do Pagar.me (sk_...)"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={errors.currentPayment?.pagarme?.userCredential}
                      helperText={errors.currentPayment?.pagarme?.userCredential?.message}
                    />
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <Controller
                  name="currentPayment.pagarme.userPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: { value: watch('currentPayment.type') == 2, message: 'A senha do Pagar.me é obrigatório' },
                    minLength: { value: 3, message: 'A chave pública do Pagar.me deve ter pelo menos 3 caracteres' },
                    maxLength: { value: 255, message: 'A chave pública do Pagar.me não pode ser maior que 255 caracteres' }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Chave Pública do Pagar.me (pk_...)"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={errors.currentPayment?.pagarme?.userPassword}
                      helperText={errors.currentPayment?.pagarme?.userPassword?.type === 'value' ? 'Email inválido' : errors.currentPayment?.userPassword?.message}
                    />
                  )}
                />
              </Col>
            </Row>
            { REACT_APP_URL && company.id && 
              <p><b>URL do WebHook Pagarme: </b>{server}/public/transaction/pagarme/{company.id}/notify</p>
            }
            <Button 
              variant="info" 
              disabled={loadingCredentials} 
              onClick={checkCredential}
            >
            Checar credenciais
            </Button>
          </>) : null }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">{company.id ? 'Salvar' : 'Cadastrar'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
