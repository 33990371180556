import { DateTime } from "luxon"

const validateCnpj = (cnpjStr) => {
  const cnpj = cnpjStr.replace(/[^\d]+/g, '')

  if (cnpj === '' ||
      cnpj.length !== 14  ||
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) 
    return false

  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size)
  const digits = cnpj.substring(size)
  let sum = 0
  let pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }
  let result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result.toString() !== digits.charAt(0)) return false

  size += 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7
  for (let i = size; i >= 1; i --) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }
  result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result.toString() !== digits.charAt(1)) return false

  return true
}

const validateCpf = (strCPF) => {
  strCPF = strCPF.replace(/[^\d]+/g, '')
  let sum = 0, rest;
  if (strCPF === "00000000000") return false;

  for (let i=1; i<=9; i++)
    sum = sum + parseInt(strCPF.substring(i-1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))  rest = 0;
  if (rest !== parseInt(strCPF.substring(9, 10)) ) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(strCPF.substring(i-1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11))  rest = 0;
  if (rest !== parseInt(strCPF.substring(10, 11) ) ) return false;
  return true;
}

const validateEmail = (value) => {
  let regexp = /^[a-z0-9.\-_]+@[a-z0-9]+(\.[a-z0-9]+){0,4}\.[a-z]{1,}$/gi
  return regexp.test(value)
}

const validatePermalink = (value) => {
  let regexp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/g
  return regexp.test(value)  
}

const validateName = (value) => {
  let regexp = /[a-zA-Z]+\s+[a-zA-Z]+/g
  return regexp.test(value)
}

const validateBirthDate = (value) => 
  DateTime.fromFormat(value, 'd/M/yyyy').isValid

const validatePostalCode = (value) => {
  let regexp = /^\d{8}$/g
  return regexp.test(value.replace(/-/g,''))
}

const validateTelephone = (value) => {
  const phone = value.replace(/(\(|\)|-|\s)/g, '')
  return (phone.length > 9 && phone.length < 13)
}

export { 
  validateCnpj, 
  validateCpf, 
  validateEmail, 
  validatePermalink, 
  validateName, 
  validateBirthDate,
  validatePostalCode,
  validateTelephone
}