import React, {useMemo, useEffect, useState} from 'react'
import css from './styles.module.scss'
import { format } from 'date-fns'
import ModalSubscription from '../../../pages/Dashboard/Subscribers/Modal'


const statusSubscriptionText = (status, expiryDate) => {
  switch(status.toLowerCase()){
    case 'active': return 'Ativo'
    case 'cancelled_by_receiver': return 'Cancelado pelo vendedor'
    case 'cancelled_by_sender': return 'Cancelado pelo comprador'
    case 'cancelled': case 'canceled': return 'Cancelado'
    case 'failed': return 'Falha no pagamento'
    case 'expired': return 'Expirado'
    case 'pending': return 'Aguardando processamento do pagamento'
    case 'suspended': return 'Suspensa'
    case 'payment_method_change': return 'Aguardando troca de cartão'
    case 'document_pending': return 'Documento enviado em análise'
    case 'document_denied': return 'Documento enviado negado'
    case 'document_not_sended': return 'Documento não enviado'
    default: return
  }
}

const UserInfoAssociation = ({subscriptions, company, editable, setLoading, closeModal}) => {

  const [subscriptionEdition, setSubscriptionEdition] = useState(null)
  const [subscriptionEditionModal, setSubscriptionEditionModal] = useState(null)

  const handleClick = (selectedSubscription) => {
    if (!editable) return
    setSubscriptionEditionModal(true)
    setSubscriptionEdition(selectedSubscription)
  }


  const handleCloseModal = () => {
    setLoading(true)
    closeModal()
    setSubscriptionEditionModal(false)
    setSubscriptionEdition({})
  }

  if (subscriptions.length == 0){
    return null
  }
  return (
    <div className={css["cp-user-info-associations"]}>
      <h1>Associações</h1>
      <div className={css["cp-user-info-associations__subscriptions"]}>
        {subscriptions?.map(subscription => (
          <div 
            className={`${css["cp-user-info-associations__subscriptions__item"]} ${editable ? css["clickable"] : null}`}
            onClick={() => handleClick(subscription)}
          >
            <div className={css["cp-user-info-associations__subscriptions__item__image"]}>
              <img src={company.logo} />
            </div>
            <div className={css["cp-user-info-associations__subscriptions__item__content"]}>
              <p className={css["cp-user-info-associations__subscriptions__item__content__name"]}>
                Plano: {subscription.plan.name}
              </p>
              <p>{format(new Date(subscription.startDate), 'dd/MM/yyyy')} - {format(new Date(subscription.endDate), 'dd/MM/yyyy')}</p>
              <p>{statusSubscriptionText(subscription.status)} {statusSubscriptionText(subscription.status) === 'Ativo' && subscription.canceled_recurrence ? '(Recorrência cancelada)': ''}</p>
              <p>
                {company.name}
              </p>
            </div>
          </div>
        ))}
      </div>
      {subscriptionEditionModal ?
        <ModalSubscription 
          show={subscriptionEditionModal}
          handleClose={handleCloseModal}
          subscription={subscriptionEdition}
          companyId={company.id}
          setLoading={setLoading}
        /> : null
      }
    </div>
  )
}


export default UserInfoAssociation