import React, { useState, useEffect } from "react"
import "./styles.module.scss"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const WYSIWYGEditor = ({ value, onChange }) => {
  const [content, setContent] = useState('')
  
  const handleChange = value => {
    setContent(value)
    return onChange(value)
  }

  useEffect(() => {
    setContent(value || '')
  }, [value])

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  }

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link'
  ]

  return (
    <React.Fragment>
      <div className="editor">
        <ReactQuill 
          theme="snow"
          value={content}
          onChange={handleChange} 
          modules={modules}
          formats={formats}
        />
      </div>
    </React.Fragment>
  );
};

export default WYSIWYGEditor;
