/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import { DateTime } from 'luxon'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getTickets } from '../../../../http/ticket'
import ModalTicket from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import css from './styles.module.scss'
import { FaReply, FaEye } from "react-icons/fa"
import { useParams, Link } from "react-router-dom"
import { getUser } from '../../../../services/auth'
import { NotificationManager } from 'react-notifications'

const TicketList = () => {

  const size = 10
  const [currentTicket, setCurrentTicket] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [editableModal, setEditableModal] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const companyId = params.companyId || getUser()?.companyId || getUser().companyId

  const fetchTickets = async () => {
    try{
      const Tickets = await getTickets({
        ...( searchable ? { searchable } : {}),
        ...(companyId ? { companyId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(Tickets)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchTickets()
  }, [searchable, page])

  const editTicket = (data) => {
    setCurrentTicket(data)
    setEditableModal(true)
    setModalOpen(true)
  }

  const showTicket = (data) => {
    setCurrentTicket(data)
    setEditableModal(false)
    setModalOpen(true)
  }

  const closeModal = () => {
    setSearchable('')
    fetchTickets()
    setCurrentTicket({})
    setModalOpen(false)
  }

  useEffect(() => {
    document.title = "Suporte - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Cursos",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Título', accessor: 'title', sortType: 'basic' },
          { 
            Header: 'Criado em', 
            Cell: ({row}) => DateTime.fromJSDate(new Date(row.original.createdAt)).toFormat("dd/M/yyyy HH:mm")
          },
          { 
            Header: 'Respondido em', 
            Cell: ({row}) => row.original.updatedAt !== row.original.createdAt ? DateTime.fromJSDate(new Date(row.original.updatedAt)).toFormat("dd/M/yyyy HH:mm") : ''
          },
          { 
            Header: 'Status', 
            Cell: ({row}) => {
              if (row.original.answer) return "Respondido"
              return "Aberto"
            } 
          },
          { Header: 'Ações', 
            Cell: ({row}) => {
              return row.original.answer ? (
                <div>
                  <Button variant="link" onClick={() => showTicket(row.original)}>
                    <FaEye />
                  </Button>
                </div>
              ) : (
                <div>
                  <Button variant="link" onClick={() => editTicket(row.original)}>
                    <FaReply />
                  </Button>
                </div>
              )
            }
          }
        ]
      }
    ], []
  )  

  const breadcrumbs = [
    {name: 'Início', url: '/dashboard'},
    {name: "Suporte", url: `/dashboard/empresa/${companyId}/tickets`}
  ]

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-ticket">
        <Row className={css['pg-ticket__filter']}>
          <Col className={css['pg-ticket__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchTickets} 
          size={size}
          className={css["pg-ticket__table"]}
          setPage={setPage}
        />
      </div>
      <ModalTicket 
        show={modalOpen}
        handleClose={closeModal}
        ticket={currentTicket}
        setLoading={setLoading}
        editable={editableModal}
      />
    </DashboradLayout>
  )
}

export default TicketList