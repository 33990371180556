import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { NotificationManager } from 'react-notifications'
import { cancelEnrollTransaction } from '../../../../http/enroll'


export default function ConfirmationModal({ show, handleClose, enrolled, course, setLoading }) {
  const onSubmit = async () => {
    setLoading(true)
    cancelEnrollTransaction(enrolled.id).then(e => {
      NotificationManager.success('A transação foi cancelada no Pagar.me com sucesso', 'Cancelado com sucesso')
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'
      NotificationManager.error(description, title)
    }).finally(e=>{
      setLoading(false)
    })
  }

  const close = () => {
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancelar e estornar compra no Pagar.me</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} md={12}>
            <p>Informamos que o cancelamento e estorno da compra no gateway de pagamento é uma ação irreversível. Uma vez confirmado, não será possível reverter ou recuperar a transação cancelada.</p>
            <p>Por favor, tenha certeza de que deseja prosseguir com o cancelamento, pois isso resultará na anulação da compra e possíveis consequências associadas.</p>
          </Col>
        </Row>
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Cancelar
        </Button>
        <Button variant="success" onClick={onSubmit}>Confirmar</Button>
      </Modal.Footer>
    </Modal>
  )
}
