import axios from './api'

const getVouchers = async ( params ) => {
  try {
    const vouchers =  await axios.get(`api/vouchers`, { params })
    return vouchers.data
  } catch (error) {
    return error;
  }
}

const getVoucher = async ( id ) => {
  const vouchers =  await axios.get(`api/voucher`, { params: { id } })
  return vouchers.data.rows[0]
}

const findVoucher = async ( code, courseId ) => {
  const vouchers =  await axios.get(`api/voucher`, { params: { code, courseId } })
  return vouchers.data
}

const saveVoucher = async ( data ) => {
  const voucher =  await axios.post(`api/vouchers`, { data })
  return voucher.data
}

const updateVoucher = async ( id, data ) => {
  const voucher =  await axios.put(`api/voucher/${id}`, { data })
  return voucher.data
}

const deleteVoucher = async ( id ) => {
  const voucher =  await axios.delete(`api/voucher/${id}`)
  return voucher.data
}

export { getVouchers, saveVoucher, updateVoucher, getVoucher, deleteVoucher, findVoucher }