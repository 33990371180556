import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { addCompany } from '../../../../http/user'
import { getCompanies } from '../../../../http/company'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function AddToCompany({ show, handleClose, userId, setLoading, callback }) {
  const {
    register, handleSubmit, setValue, reset, control, formState: { errors },
  } = useForm()

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])

  const handleSearch = async (query) => {
    try{
      setIsLoading(true)
      const companies = await getCompanies({ searchable: query })
      setOptions(companies.data?.rows ?? [])
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const onSubmit = async (data) => {

    setLoading(true)
    let result = addCompany(userId, {companyId: data.company})

    result.then(e => {
      NotificationManager.success('O usuário foi associado à empresa com sucesso')
      callback()
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'      
      NotificationManager.error(description, title)
    }).finally(e=>{
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Associar à empresa</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Empresa</Form.Label>
          <Controller
            name='company'
            control={control}
            render={({
              field: { onChange, value },
            }) => (
              <AsyncTypeahead
                isLoading={isLoading}
                labelKey={option => `${option.name}`}
                onSearch={handleSearch}
                onChange={(value) => { onChange(value ? value[0]?.id : null)}}
                value={value}
                options={options}
                id={'cnpj'}
                searchText='Buscando'
                promptText='Busque por nome da empresa'
                emptyLabel='Nenhuma empresa encontrada'
                isInvalid={ errors.company }
                className={ errors.company ? "is-invalid" : ''} 
              />
            )}
            rules={{ required: { value: true, message: 'A empresa é obrigatória' } }}
          />
          <Form.Control.Feedback type="invalid">{errors.company?.message}</Form.Control.Feedback>
        </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">Salvar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
