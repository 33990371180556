import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import css from './styles.module.scss'

const Collapse = ({ children, title, startOpen }) => {
	const [open, setOpen] = useState(startOpen)

	const openCard = () => {
		setOpen(prevOpen => !prevOpen)
	}

	return (
		<Card className={css["collapsable"]}>
			<Card.Header onClick={openCard} className={css["collapsable__title"]}>
				{title} { open ? <BsChevronUp /> : <BsChevronDown /> }
			</Card.Header>
			{open &&
				<Card.Body className={css["collapsable__body"]}>
					{ children }
				</Card.Body>
			}
		</Card>
	)
}

export default Collapse