/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getPartnerships, deletePartnership } from '../../../../http/partnership'
import ModalPartnership from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"
import { NotificationManager } from 'react-notifications'
import { useParams, Link } from "react-router-dom"
import { getCourse } from '../../../../http/course'
const server = process.env.REACT_APP_API_URL || null

const PartnershipList = () => {

  const size = 10
  const [currentPartnership, setCurrentPartnership] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const [course, setCourse] = useState({})
	const [loading, setLoading] = useState(true)
  const params = useParams()
  const courseId = params.courseId

  const fetchPartnerships = async () => {
    try{
      const partnerships = await getPartnerships({
        ...(courseId ? { courseId } : {}),
        ...( searchable ? { searchable } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(partnerships)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    document.title = "Parceiros - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  useEffect(() => {
    fetchPartnerships()
  }, [searchable, page])

  const editPartnership = (data) => {
    setCurrentPartnership(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    setSearchable('')
    fetchPartnerships()
    setCurrentPartnership({})
    setModalOpen(false)
  }

  const addPartnership = () => {
    setSearchable('')
    setCurrentPartnership({})
    setModalOpen(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Parceiros",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' }, 
          { Header: 'Logo',
            accessor: 'logoUrl',
            sortType: 'basic', 
            Cell: ({value}) => (
              <div className={css['pg-partnership__preview']} >
                <div className={css['pg-partnership__logo']} >
                  <img src={`${server}${value}`} alt='' />
                </div>
              </div>
            )
          }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Button variant="link" onClick={() => editPartnership(row.original)}>
                  <FaEdit />
                </Button>
                <Button variant="link" onClick={() => deleteItem(row.original)}>
                  <FaTrash />
                </Button>
              </div>
            )
          }
        ]
      }
    ], []
  )

  const fetchCourse = async () => {
    try{
      const course = await getCourse(courseId)
      setCourse(course)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja deletar esse parceiro? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deletePartnership(item.id)
        setSearchable('')
        fetchPartnerships()
        setLoading(false)
        NotificationManager.success('Parceiro excluído com sucesso', 'Deletado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }

  const breadcrumbs = course ? [
    {name: 'Início', url: '/dashboard'},
    {name: course.name, url: `/dashboard/empresa/${course.companyId}/curso/${course.id}/aulas`},
    {name: "Parceiros", url: `/dashboard/curso/${course.id}/parceiros`}
  ] : []
  

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-partnership">
        <Row className={css['pg-partnership__filter']}>
          <Col className={css['pg-partnership__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          <Col xs={3} className={css['pg-partnership__filter__actions']}>
            <Button onClick={ addPartnership }><FaPlus /> Novo</Button>
          </Col>
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={ fetchPartnerships } 
          size={size}
          className={css["pg-partnership__table"]}
          setPage={setPage}
        />
      </div>
      <ModalPartnership 
        show={modalOpen}
        handleClose={closeModal}
        partnership={currentPartnership}
        course={course}
        setLoading={setLoading}
      />
    </DashboradLayout>
  )
}

export default PartnershipList