import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import { changeSubscription } from '../../../../http/subscription'
import './styles.module.scss'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null


export default function ModalEnrolled({ show, handleClose, subscription, course, setLoading }) {
  const {
    register, handleSubmit, setValue, reset, getValues, setError, formState: { errors }, control,
  } = useForm()

  useEffect(() => {
    const fields = ['status', 'startDate', 'endDate']
    if (subscription.id) {
      fields.forEach((field) => {
        if (!['startDate', 'endDate'].includes(field) || !subscription.plan.recorrent) {
          setValue(field, subscription[field])
        }
      })
      if (subscription.plan?.sendDocument) 
        setValue('documentStatus', subscription.documents[0] ? subscription.documents[0].status : 'pending')

      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [subscription, setValue])


  const onSubmit = async (data) => {
    setLoading(true)
    changeSubscription(subscription.id, {
      status: data.status,
      ...(data.endDate ? {endDate: data.endDate} : {}),
      ...(data.startDate ? {startDate: data.startDate} : {}),
      ...(data.documentStatus ? { documentStatus: data.documentStatus } : null)
    }).then(e => {
      NotificationManager.success('A associação foi alterada com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'
      if (e.response?.status === 422){
        let errorCode = e.response?.data?.error?.errCode
        description = e.response?.data?.error?.message
        if (errorCode === 'invalid_period'){
          setError('startDate', {type: errorCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
          setError('endDate', {type: errorCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        }
      } else {
        NotificationManager.error(description, title)
      }
    }).finally(e=>{
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }

  const buildDocument = (document) => {
    if (!document) 
      return (<p>Documento não enviado</p>)
    if (!document.document || document.document === 'null')
      return (<p>Documento fake usado na importação</p>)
    
    return (
      <a 
        href={`${server}${document.documentUrl}`} 
        target="_blank"
        rel="noreferrer"
      >
        Ver documento
      </a>
    )   
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Alterar status da associação</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    {...register('status', {
                      required: { value: true, message: 'O status é obrigatório' },
                    })}
                    isInvalid={errors.type}
                    as="select"
                    custom
                  >
                    <option value="pending">Pendente</option>
                    <option value="active">Ativo</option>
                    <option value="cancelled_by_receiver">Cancelado pelo vendedor</option>
                    <option value="failed">Falha no pagamento</option>
                    <option value="cancelled_by_sender">Cancelado pelo comprador</option>
                    <option value="cancelled">Cancelado</option>
                    <option value="expired">Expirado</option>
                    <option value="suspended">Suspenso</option>
                    <option value="payment_method_change">Aguardando alteração do método de pagamento</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
                </Form.Group> 
              </Col>
            </Row>
            {['active', 'pending'].includes(subscription.status) && !subscription.plan.recorrent ?
              <Row>
                <Col>
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue=""
                    rules={{ 
                      required: { value: true, message: 'A data e hora inicial do curso é obrigatório' }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <KeyboardDateTimePicker
                        variant="inline"
                        ampm={false}
                        label="Data inicial"
                        value={value || null}
                        onChange={onChange}
                        format="dd/MM/yyyy HH:mm"
                        fullWidth={true}
                        inputVariant="outlined"
                        autoOk={true} 
                        invalidDateMessage="Data inválida"
                        error={errors.startDate}
                        helperText={errors.startDate?.message || ''}
                      />
                    )}
                  />
                </Col>
                <Col>
                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue=""
                    rules={{ 
                      required: { value: true, message: 'A data e hora final do curso é obrigatório' }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <KeyboardDateTimePicker
                        variant="inline"
                        ampm={false}
                        label="Data final"
                        value={value || null}
                        onChange={onChange}
                        minDate={getValues('startDate')}
                        format="dd/MM/yyyy HH:mm"
                        fullWidth={true}
                        inputVariant="outlined"
                        autoOk={true} 
                        invalidDateMessage="Data inválida"
                        minDateMessage="A data final deve ser mais que a data final"
                        error={errors.endDate}
                        helperText={errors.endDate?.message || ''}
                      />
                    )}
                  />
                </Col>
              </Row>
            : null }
            {subscription.plan?.sendDocument &&
              <div>
                <hr className={css["cp-document-status__line"]}/>
                <h4>Documento exigido:</h4>
                <p>
                  {
                  subscription.plan?.documentDescription &&
                    subscription.plan?.documentDescription !== 'null' ? 
                      subscription.plan?.documentDescription : 
                      'Sem descrição'
                  }
                </p>
                <Row className={css["cp-document-status"]}>
                  <Col xs={12} md={6}>
                    {buildDocument(subscription?.documents?.[0])}
                  </Col>
                  {subscription.documents && subscription.documents[0] ?
                    <Col xs={12} md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Status do documento</Form.Label>
                        <Form.Control
                          {...register('documentStatus', {
                            required: { value: true, message: 'O status do documento é obrigatório' },
                          })}
                          isInvalid={errors.type}
                          as="select"
                          custom
                        >
                          <option value="pending">Pendente</option>
                          <option value="approved">Aprovado</option>
                          <option value="denied">Negado</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.documentStatus?.message}</Form.Control.Feedback>
                      </Form.Group> 
                    </Col>
                  : null }
                </Row>
              </div>
            }
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">Salvar</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}
