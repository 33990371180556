import { gql } from '@apollo/client'
// import type { Facets, Pagination } from 'graphql/queries/helpers'

export const GET_USER_PROFILE = gql`
  query frontGetUserProfile ($id: Int){
    user (id: $id){
      id
      name
      email
      cpf
      birthDate
      cellphone
      address
      addressNumber
      addressNeighborhood
      addressComplement
      cep
      city
      state
      isForeign
      foreignDocument
      country
      role
      managedCompany{
        id
        name
      }
      companies (first: 30) {
        totalCount
        edges {
          id
          name
          requirements (first: 30) {
            edges {
              id
              required
              title
              type
              options {
                id
                value
              }
              metadata {
                id
                value
              }
            }
          }
        }
      }
    }
  }
`

export const GET_USER_BASIC_PROFILE = gql`
  query frontGetUserProfile ($id: Int, $companyId: Int){
    user (id: $id){
      id
      name
      email
      cpf
      birthDate
      cellphone
      address
      addressNumber
      addressNeighborhood
      addressComplement
      cep
      city
      state
      isForeign
      foreignDocument
      country
      role
      managedCompany{
        id
        name
      }
      companies (first: 30, id: $companyId) {
        totalCount
        edges {
          id
          name
        }
      }
    }
  }
`

export const GET_USER_PROFILE_COMPANY = gql`
  query frontGetUserProfile ($id: Int, $companyId: Int){
    user (id: $id) {
      companies(first: 1, id: $companyId) {
        totalCount
        edges {
          id
          name
          logo
          subscriptions(first: 30) {
            totalCount
            edges {
              id
              file
              code
              createdAt
              endDate
              startDate
              status
              canceled_recurrence
              plan {
                name
              }
            }
          }
          enrolls(first: 30) {
            edges {
              id
              status
              file
              createdAt
              updatedAt
              course {
                id
                name
                logo
                priceName
              }
              voucher {
                id
                name
                value
                type
              }
            }
          }
          requirements (first: 30) {
            edges {
              id
              required
              title
              type
              options {
                  id
                  value
              }
              metadata {
                  id
                  value
              }
            }
          }
        }
      }
    }
  }  
`

export const GET_USER_SUBSCRIPTIONS_MATERIALS = gql`
  query frontGetUserSubscriptionsMaterials {
    user {
        companies (first: 30, page: 1) {
            edges {
                name
                id
                permalink
                subscriptionAditionalContents {
                    id
                    name
                    date
                    content
                    type
                    createdAt
                    updatedAt
                }
            }
        } 
    }
}
`