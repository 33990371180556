/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getVouchers, deleteVoucher } from '../../../../http/voucher'
import ModalVoucher from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"
import { NotificationManager } from 'react-notifications'
import { useParams } from "react-router-dom"
import { getCourse } from '../../../../http/course'

const VoucherList = () => {

  const size = 10
  const [currentVoucher, setCurrentVoucher] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const [course, setCourse] = useState({})
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const courseId = params.courseId

  const fetchVouchers = async () => {
    try{
      const vouchers = await getVouchers({
        ...(courseId ? { courseId } : {}),
        ...( searchable ? { searchable } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(vouchers)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchVouchers()
  }, [searchable, page])

  const editVoucher = (data) => {
    setCurrentVoucher(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    setSearchable('')
    fetchVouchers()
    setCurrentVoucher({})
    setModalOpen(false)
  }

  const addVoucher = () => {
    setSearchable('')
    setCurrentVoucher({})
    setModalOpen(true)
  }

  useEffect(() => {
    document.title = "Voucher - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Empresas",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' }, 
          { Header: 'Código', accessor: 'code', sortType: 'basic' }, 
          { Header: 'Desconto', accessor: 'value', sortType: 'basic',
            Cell: ({row}) => {
              let { value, type } = row.original
              value = value.replace('.', ',')
              return type === 'value' ? `R$ ${value}` : `${value}%`
            }
          },
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Button variant="link" onClick={() => editVoucher(row.original)}>
                  <FaEdit />
                </Button>
                <Button variant="link" onClick={() => deleteItem(row.original)}>
                  <FaTrash />
                </Button>
              </div>
            )
          }
        ]
      }
    ], []
  )

  const fetchCourse = async () => {
    try{
      const course = await getCourse(courseId)
      setCourse(course)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchCourse()
  }, [])

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja deletar esse voucher? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deleteVoucher(item.id)
        setSearchable('')
        fetchVouchers()
        setLoading(false)
        NotificationManager.success('Voucher excluído com sucesso', 'Deletado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }

  const breadcrumbs = course ? [
    {name: 'Início', url: '/dashboard'},
    {name: course.name, url: `/dashboard/empresa/${course.companyId}/curso/${course.id}/aulas`},
    {name: "Vouchers", url: `/dashboard/curso/${course.id}/vouchers`}
  ] : []
  

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-voucher">
        <Row className={css['pg-voucher__filter']}>
          <Col className={css['pg-voucher__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome ou código' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          <Col xs={3} className={css['pg-voucher__filter__actions']}>
            <Button onClick={ addVoucher }><FaPlus /> Nova</Button>
          </Col>
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={ fetchVouchers } 
          size={size}
          className={css["pg-voucher__table"]}
          setPage={setPage}
        />
      </div>
      <ModalVoucher 
        show={modalOpen}
        handleClose={closeModal}
        voucher={currentVoucher}
        course={course}
        setLoading={setLoading}
      />
    </DashboradLayout>
  )
}

export default VoucherList