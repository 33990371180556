
import css from './styles.module.scss'

const CourseContent = ({image, text, button}) => {
  return (
    <div className={css['cp-course-content']}>
      <img src={image} alt=""/>
      <div className={css['cp-course-content__text']}>
        <p>{text}</p>
        <a 
          target={button.newTab ? '_blank': ''}
          href={button.link} 
          className={css['cp-course-content__button']}
          rel="noreferrer"
        >
          {button.text}
        </a>
      </div>
    </div>
  )
}
export default CourseContent