import React, {useEffect, useState, useRef} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../layouts/default'
import { useParams, Link } from "react-router-dom"
import { getCompany } from '../../http/company'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { stripHtml } from '../../util/utils'
import CompanyMyContents from '../../components/Company/MyContents'
import styled from 'styled-components'
import SubscriptionStatus from '../../components/Subscription/Status'
import SubscriptionMoreDetails from '../../components/Subscription/MoreDetails'
import SubscriptionPriceList from '../../components/SubscriptionPriceList'
import { getUser, isAuthenticated } from '../../services/auth'
import ModalCheckoutSubscription from '../../components/Modal/CheckoutSubscription'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import { generateSession } from '../../http/payment'
const server = process.env.REACT_APP_API_URL || null

const ClosedCourseEnd = styled.div`
  background: linear-gradient(90deg, ${props => props.primaryColor ? props.primaryColor : "#808080"} 70%, ${props => props.secondaryColor ? props.secondaryColor : "#292928"} 30%);
  position: relative;
  z-index: 10;
  height: 90px;
  /*&:before {
    background: linear-gradient(90deg, ${props => props.primaryColor ? props.primaryColor : "#808080"} 70%, ${props => props.secondaryColor ? props.secondaryColor : "#292928"} 30%);
    content: '';
    display: block;
    height: 80px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
  }

  &:before {
    top: 0;
    -webkit-transform: skewY(-2.8deg);
            transform: skewY(-2.8deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }*/

  &:after {
    content: '';
    display: block;
    background-color: #CCCCCC;
    height: 30px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 10;
  }

  &:after {
    top: -10px;
    //-webkit-transform: skewY(-2.8deg);
    //        transform: skewY(-2.8deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
`;

const Company = () => {
  const [company, setCompany] = useState(null)
  const [loading, setLoading] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [sessionId, setSessionId] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(null)
  const params = useParams()
  if (!params.companyId || params.permalink)
    window.href = '/'
  const companyId = params.companyId || getUser()?.companyId
  const permalink = params.permalink

  const fetchCompany = async () => {
    let company = await getCompany({id: companyId, permalink})
    let closed = [], open = [], today = (new Date()).getTime()
    company.courses.forEach(course => {
      if (today < (new Date(course.endDate).getTime())){
        open.push(course)
        return
      }
      closed.push(course)
    })
    company.closedCourses = [...closed].sort((a,b) =>
    new Date(a.endDate) > new Date(b.endDate) ? -1 : 1
    )
    company.openCourses = [...open].sort((a,b) =>
      new Date(a.endDate) > new Date(b.endDate) ? -1 : 1
    )
    company.firstTwoType = company.openCourses.length ? 'open' : 'closed'
    company.firstTwo = company.openCourses.length ? company.openCourses.splice(0,2) : company.closedCourses.splice(0,2)
    company.closedCoursesIncomplete = company.closedCourses 
    company.openCoursesIncomplete = company.openCourses
    setCompany(company)
    document.title = `${company.name} - Alissem`
    document.getElementsByTagName("META")[1].content=document.title
    setLoading(false)
  }

  const getSession = async () => {
    const { session } = await generateSession({companyId: company.id})
    setSessionId(session.id)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const subscribeMe = async (plan) => {
    const amount = Number(plan.value).toFixed(2)
    if (!isAuthenticated()){
      window.location.href = `/login?redirect=/empresa/${company.permalink}`
      return 
    }

    if (amount != "0.00" ){
      setLoading(true)
      if (company.paymentCompanyGateways[0].paymentGateway.id === 1) {
        await getSession()
      }
      setModalOpen(true)
      setLoading(false)
      return
    }

    try {
      /*await proccess({
        productId: productId,
      })
      window.location.reload()*/
      return
    } catch (e) {
      NotificationManager.error("Ocorreu um erro ao processar o seu pagamento. Tente novamente")
    }
  }

  const executeScroll = (ref) => {
    if (!ref || !ref.current) {
      return false
    }
    ref.current.scrollIntoView({behavior: 'smooth'})
  }
  
  const showPlans = company && company.plans && 
  (!company.subscription ||
    ['active', 'paid','disponible'].indexOf(company.subscription.status.toLowerCase()) === -1)

  const mySubscription = useRef(null)
  const newSubscription = useRef(null)

  useEffect(() => {
    fetchCompany()
  }, [companyId])

  const primaryColor = company?.primaryColor ? { style: { background: company.primaryColor || '#292928' }} : null
  const secondaryColor = company?.secondaryColor ? { style: { background: company.secondaryColor || '#808080' }} : null

  return (
    <LayoutDefault loading={loading} openTicket={true} companyId={company?.id}>
      {company && (
        <div className={css['pg-Company']} style={{background: `linear-gradient(90deg, ${company?.secondaryColor || '#808080'} 50%, ${company?.primaryColor || '#292928'} 50%)`}}
        >
          <Container>
            <Row>
              <Col md={4} className={css['cp-company']} {...(company?.secondaryColor ? secondaryColor : {})}>
                <div className={css['cp-company__content']}>
                {companyId == 7 || companyId == 'abvhmt' ? null :
                    <div className={css['cp-company__content__title']}>
                      <h2>Eventos</h2>
                    </div>
                }
                  <div className={css['cp-company__logo']}>
                    <img src={`${server}${company.logo}`} alt={company.name} />
                  </div>
                  <p className={css['cp-company__title']}>{company.name}</p>
                  <p className={css['cp-company__desc']}>{company.description}</p>
                  { showPlans && 
                    <p 
                      className={css['cp-company__associate']}
                      onClick={() => executeScroll(newSubscription)}
                    >Associe-se</p>
                  }
                </div>
              </Col>
              <Col md={8} className={css['cp-open-courses']} {...(company?.primaryColor ? primaryColor : {})}>
                {company.subscription && 
                  <SubscriptionStatus 
                    subscription={company.subscription}
                    handleClick={() => executeScroll(mySubscription)} 
                  />
                } 
                <div className={css['cp-open-courses__title']}>
                  <h2>{company.firstTwoType === 'open' ? 'Inscrições Abertas' : 'Eventos já realizados'}</h2>
                </div>
                <Row>
                  {company.firstTwo?.map((course, index) => {
                    if (index > 1) return null
                    const enroll = course.enroll && course.enroll.status === 'paid' 
                    return (
                      <Col md={6} key={course.id} className={css['cp-open-course']}>
                        <Link
                          to={ enroll ? `/curso/${company.permalink}/${course.id}/conteudo` : `/curso/${company.permalink}/${course.id}`}
                        >
                          <div className={css['cp-open-course__content']}>
                            <div className={css['cp-open-course__content__logo']}>
                              <img src={`${server}${course.logoUrl}`} alt={course.name} />
                            </div>
                            <p className={css['title']}>{course.name}</p>
                            <p>{stripHtml(course.informations).substring(0,120)}</p>
                            {enroll ? 
                              <Link 
                                className={`${css['read_more']} ${css['read_more--buyed']}`} 
                                to={`/curso/${company.permalink}/${course.id}/conteudo`}
                              > 
                                Inscrito! <p>Acessar área do aluno</p>
                              </Link>
                              :
                              <Link 
                                className={`${css['read_more']}`} 
                                to={`/curso/${company.permalink}/${course.id}`}
                              >
                                Saiba mais
                              </Link>
                            }
                          </div>
                        </Link>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
          {company.openCoursesIncomplete?.length > 0 ||  company.closedCoursesIncomplete?.length > 0 ?
            <div>
              <div className={css['cp-closed-courses']}>
                <Container >
                  {company.openCoursesIncomplete &&
                    <Row>
                      {company.openCoursesIncomplete.map((course, index) => {
                        const enroll = course.enroll && course.enroll.status === 'paid'
                        return (
                          <Col md={4} key={course.id} className={css['cp-closed-course']}>
                            <Link
                              to={ enroll ? `/curso/${company.permalink}/${course.id}/conteudo` : `/curso/${company.permalink}/${course.id}`}
                            >
                              <div className={css['cp-closed-course__content']}>
                                <div className={css['cp-closed-course__content__logo']}>
                                  <img src={`${server}${course.logoUrl}`} alt={course.name} />
                                </div>
                                <h3>{course.name}</h3>
                                <p>{stripHtml(course.informations).substring(0,60)}</p>
                              </div>
                            </Link>
                            {enroll ? 
                              <Link 
                                className={`${css['cp-closed-course__buy']} ${css['cp-closed-course__buy--buyed']}`} 
                                to={`/curso/${company.permalink}/${course.id}/conteudo`}
                              > 
                                Inscrito! <p>Acessar área do aluno</p>
                              </Link>
                              :
                              <Link 
                                className={`${css['cp-closed-course__buy']}`} 
                                to={`/curso/${company.permalink}/${course.id}`}
                              >
                                Saiba mais
                              </Link>
                            }							
                          </Col>
                        )
                      })}
                    </Row>
                  }
                </Container>

              
                {company.closedCoursesIncomplete?.length &&
                  <div className={css["cp-closed-courses"]}>
                    <span className={css["cp-closed-courses__decorator"]}></span>
                    <Container >
                      {company.closedCoursesIncomplete.length > 0 && <h2>Eventos já realizados</h2>}
                      <Row>
                        {company.closedCoursesIncomplete.map((course, index) => {
                          const enroll = course.enroll && course.enroll.status === 'paid'
                          return (
                            <Col md={4} key={course.id} className={css['cp-closed-course']}>
                              <Link
                                to={ enroll ? `/curso/${company.permalink}/${course.id}/conteudo` : `/curso/${company.permalink}/${course.id}`}
                              >
                                <div className={css['cp-closed-course__content']}>
                                  <div className={css['cp-closed-course__content__logo']}>
                                    <img src={`${server}${course.logoUrl}`} alt={course.name} />
                                  </div>
                                  <h3>{course.name}</h3>
                                  <p>{stripHtml(course.informations).substring(0,60)}</p>
                                </div>
                              </Link>
                                
                              {enroll ? 
                                <Link 
                                  className={`${css['cp-closed-course__buy']} ${css['cp-closed-course__buy--buyed']}`} 
                                  to={`/curso/${company.permalink}/${course.id}/conteudo`}
                                > 
                                  Inscrito <p>Acessar área do aluno</p>
                                </Link>
                                :
                                <Link 
                                  className={`${css['cp-closed-course__buy']}`} 
                                  to={`/curso/${company.permalink}/${course.id}`}
                                >
                                  { course.open ? 'Adquira' : 'Saiba Mais' }
                                </Link>
                              }
                            </Col>
                          )
                        })}
                      </Row>
                    </Container>
                  </div>
                }
              </div>              
              { isAuthenticated() &&
                <div>
                  <ClosedCourseEnd 
                    secondaryColor={company.primaryColor} 
                    primaryColor={company.secondaryColor}
                  />
                  <CompanyMyContents company={company} /> 
                </div>
              }
            </div>
          : null}
          { company.subscription &&
            <div ref={mySubscription}>
              <SubscriptionMoreDetails
                subscription={company.subscription}
                setLoading={setLoading}
              />
            </div>
          }
          	
          { showPlans &&
            <div ref={newSubscription} className={css['pg-Company__prices']}>
              <h1>Seja Sócio</h1>
              <Container>
                <SubscriptionPriceList 
                  companyId={companyId}
                  prices={company.plans}
                  setPrice={setSelectedPlan}
                  handleClick={subscribeMe}
                  buyable={true}
                />
              </Container>
            </div>
          }
          <ModalCheckoutSubscription
            show={modalOpen}
            paymentGateway = {company?.paymentCompanyGateways?.[0]}
            handleClose={closeModal}
            plan={{
              name: company.name,
              logo: `${server}${company.logo}`,
              plan: selectedPlan,
              company: company,
              repeat: false
            }}
            sessionId={sessionId}
            setSelectedVoucher={setSelectedPlan}
          />	
        </div>		
      )}
    </LayoutDefault>
  )
}

export default Company