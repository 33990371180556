import React, { useState } from 'react'
import css from './styles.module.scss'
import { IoShieldCheckmark } from 'react-icons/io5'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PagSeguro from '../../../assets/pagseguro.png'
import Pagarme from '../../../assets/pagarme.png'
import { findVoucher } from '../../../http/voucher'
import { NotificationManager } from 'react-notifications'

export default function CheckoutInfo({ 
  product, 
  installment, 
  setVoucher, 
  isSubscription, 
  logo,
  paymentMethod
}) {
  let { name, price, company, voucher } = product
  let period
  if (isSubscription) {
    price = product.plan.price
    period = product.plan.period
  }

  const [voucherCode, setVoucherCode] = useState('')
  const [loading, setLoading] = useState(false)
  const validateVoucher = async () => {
    setLoading(true)
    try{
      let resp = await findVoucher(voucherCode, product.id)
      setVoucher(resp)
      NotificationManager.success("Cupom aplicado com sucesso!")
    } catch (e) {
      let message = "Erro ao aplicar o cupom"
      if (e.response.status === 422 && e.response.data?.error?.message) {
        message = e.response.data.error.message
      }
      NotificationManager.error(message)
    }
    setLoading(false)
  }

  const textPeriod = () => {
    switch(period){
      case 'MONTHLY':
        return 'mensal'
      case "BIMONTHLY":
        return 'bimestral'
      case "TRIMONTHLY":
        return 'trimestral'
      case "SEMIANNUALLY":
        return 'semestral'
      case "YEARLY":
        return 'anual'
      default:
    }
  }

  price = Number(price).toFixed(2)
  if (voucher)
    voucher.value = Number(voucher.value).toFixed(2)
  return (
    <div className={css['cp-checkoutinfo']}>
      <div className={css['cp-checkoutinfo__powered']}>
        <p>PROCESSADO POR 
          {paymentMethod.toLowerCase() === 'pagseguro' ? <img src={PagSeguro} alt="Pagseguro"/> : null}
          {paymentMethod.toLowerCase() === 'pagarme' ? <img src={Pagarme} alt="Pagarme"/> : null}
          
        </p>
      </div>
      <div className={css['cp-checkoutinfo__secure']}>
        <IoShieldCheckmark />
        <p>COMPRA 100% SEGURA</p>
      </div>
      <h1>{name}</h1>
      <div className={css['cp-checkoutinfo__author']}>
        <div className={css['cp-checkoutinfo__author__logo']}>
          <img src={`${logo}`} alt={company.name} />
        </div>
        <div>
          <p className={css["bold"]}>Oferecido por: </p>
          <p>
            <a href={`/empresa/${company.permalink}`} target="_blank" rel="noreferrer">
              {company.name}
            </a>
          </p>
        </div>
      </div>
      <div className={css['cp-checkoutinfo__price']}>
        {voucher &&
          <p className={css['name']}>{voucher.name}</p>
        }
        {voucher?.company ? 
          <p className={css['description']}>Associados {voucher.company.name}</p> : ''
        }
        {voucher && installment ? 
          <>
            <p className={css['old-price']}>R$ {price.replace(".", ",")}</p>
            <p className={css['price']}>
              {installment.quantity > 1 ? `${installment.quantity}x de ` : ''} 
              R$ {String(Number(installment.installmentAmount).toFixed(2)).replace(".", ",")} 
              {!installment.interestFree ? "*" : ''}
            </p>
          </>
        : 
          <>
            { voucher && voucher.value < price ?
              <p className={css['old-price']}>R$ {price.replace(".", ",")}</p> : null
            }
            <p className={css['price']}>
              R$ {(voucher ? voucher.value : price).replace(".", ",")}
              { isSubscription ? `/${textPeriod()}`: ''}
            </p>
          </>
        }
      </div>
      {!isSubscription && !voucher?.id ?
        <div className={css['cp-checkoutinfo__voucher']}>
          <p>Tenho cupom de desconto</p>
          <div className={css['cp-checkoutinfo__voucher__input']}>
            <Form.Control
              placeholder="Insira o cupom"
              disabled={loading}
              onChange={(e) => setVoucherCode(e.target.value) }
            />
            <Button 
              onClick={validateVoucher} 
              disabled={loading}
            >
              {loading ? 
                <Spinner animation="border" role="status">
                </Spinner> : 'Inserir' 
              }
            </Button>
          </div>
        </div>
        : '' }
    </div>
  )
}