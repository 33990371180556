import { axios, formData } from './api'

const getPartnerships = async ( params ) => {
  try {
    const partnerships =  await axios.get(`api/partnerships`, { params })
    return partnerships.data
  } catch (error) {
    return error;
  }
}

const getPartnership = async ( id ) => {
  const partnerships =  await axios.get(`api/partnership`, { params: { id } })
  return partnerships.data.rows[0]
}

const savePartnership = async ( data ) => {
  const partnership =  await formData.post(`api/partnership`, data)
  return partnership.data
}

const updatePartnership = async ( id, data ) => {
  const partnership =  await formData.put(`api/partnership/${id}`, data )
  return partnership.data
}

const deletePartnership = async ( id ) => {
  const partnership =  await axios.delete(`api/partnership/${id}`)
  return partnership.data
}

export { getPartnerships, savePartnership, updatePartnership, getPartnership, deletePartnership }