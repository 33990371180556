import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import SHA256 from "crypto-js/sha256"
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_PROFILE } from '../../../graphql/queries/users'
import css from './styles.module.scss'
import { RiLockPasswordLine } from 'react-icons/ri'
import { updateAccount, updateSecurity, updateMetadata } from '../../../http/auth'
import LayoutDefault from '../../../layouts/default'
import UserDataForm from '../../../components/common/UserDataForm'
import CompanyRequirementForm from '../../../components/common/Metadata/companyRequirementForm'

const MyProfile = () => {
  const [loading, setLoading] = useState(false)
  const {
    register, handleSubmit, control, watch, setError, setValue, formState: { errors },
  } = useForm()
  const [getUserProfile, { loading: loadingData, data: userProfile }] = useLazyQuery(GET_USER_PROFILE, {fetchPolicy: 'network-only'});
  const {
    register: registerSecurity,
    watch: watchSecurity,
    formState: { errors: errorsSecurity },
    handleSubmit: handleSubmitSecurity,
    reset: resetSecurity,
  } = useForm();

  useEffect(() => {
    document.title = `Meu perfil - Alissem`
    document.getElementsByTagName("META")[1].content=document.title
    getUserProfile()
  }, [])

  useEffect(() => {
    const fields = [
      'address',
      'addressNeighborhood',
      'addressComplement',
      'addressNumber',
      'cellphone',
      'cep',
      'city',
      'cpf',
      'email',
      'name',
      'state',
      'birthDate',
      'isForeign',
      'country',
      'foreignDocument'
    ]

    if (userProfile && userProfile.user) {
      fields.forEach((field) => setValue(field, userProfile.user[field]))
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [userProfile, setValue])

  const onSubmit = (data) => {
    setLoading(true)
    updateAccount({
      ...data,
      cpf: data.cpf?.replace(/(\.|-)/g, ''),
      cep: data.cep.replace(/-/g, ''),
      cellphone: data.cellphone.replace(/(\(|\)|-|\s)/g, ''),
    })
      .then(async (res) => {
        NotificationManager.success('Usuário atualizado com sucesso')
        await getUserProfile()
      }).catch(e => {
        if (e.response?.status === 422){
          if (e.response?.data?.error?.errCode === 'cpf_exists')
            setError('cpf', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
          else if (e.response?.data?.error?.errCode === 'email_exists')
            setError('email', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        }
        NotificationManager.error('Ocorreu um erro, tente novamente!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSubmitSecurity = (data) => {
    setLoading(true)
    updateSecurity({
      currentPassword: SHA256(data.currentPassword).toString(),
      newPassword: SHA256(data.password).toString(),
    })
      .then(async (res) => {
        resetSecurity()
        NotificationManager.success('Usuário atualizado com sucesso')
        await getUserProfile()
      }).catch(e => {
        let message = "Ocorreu um erro"
        if (e.response?.data?.error)
          message = e.response.data.error
        NotificationManager.error(message, "Ocorreu um erro")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const saveMetaData = (data) => {
    setLoading(true)
    var formData = new FormData()
    Object.entries(data).map(([key, value]) => {
      if (value) {
        formData.append(key, value)
      }
    })

    updateMetadata(formData)
      .then(async (res) => {
        await getUserProfile()
        NotificationManager.success('Informações atualizadas com sucesso')
      }).catch(e => {
        let message = "Ocorreu um erro"
        if (e.response?.data?.error)
          message = e.response.data.error
        NotificationManager.error(message, "Ocorreu um erro")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <LayoutDefault loading={loading || loadingData}>
      <div className={css["pg-my-profile"]}>
        <Container>
          <div className={css["cp-my-profile"]}>
            <h2>Meu perfil</h2>
            <Form key={"updateData"} onSubmit={handleSubmit(onSubmit)}>
              <UserDataForm
                register={register}
                errors={errors}
                control={control}
                watch={watch}
                showPassword={false}
                twoColumns={true}
              />
              <Button type="submit">Salvar</Button>
            </Form>
          </div>
          <div className={css["cp-my-profile"]}>
            <h2>Alterar senha</h2>
            <Form key={"updateSecurity"} onSubmit={handleSubmitSecurity(onSubmitSecurity)}>
              <Row>
                  <Col>
                   <Form.Group className="mb-3">
                      <RiLockPasswordLine />
                      <Form.Control
                        {...registerSecurity('currentPassword', {
                          required: { value: true, message: 'A senha atual é obrigatória' },
                          minLength: { value: 6, message: 'O senha atual deve ter pelo menos 6 caracteres' },
                          maxLength: { value: 255, message: 'O senha atual não pode ser maior que 255 caracteres' },
                        })}
                        placeholder="Senha atual"
                        type="password"
                        isInvalid={errorsSecurity.currentPassword}
                      />
                      <Form.Control.Feedback type="invalid">{errorsSecurity.currentPassword?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <RiLockPasswordLine />
                      <Form.Control
                        {...registerSecurity('password', {
                          required: { value: true, message: 'A nova senha é obrigatória' },
                          minLength: { value: 6, message: 'O nova senha deve ter pelo menos 6 caracteres' },
                          maxLength: { value: 255, message: 'O nova senha não pode ser maior que 255 caracteres' },
                        })}
                        placeholder="Nova senha"
                        type="password"
                        isInvalid={errorsSecurity.password}
                      />
                      <Form.Control.Feedback type="invalid">{errorsSecurity.password?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <RiLockPasswordLine />
                      <Form.Control
                        {...registerSecurity('passwordConfirm', {
                          required: { value: true, message: 'A confirmação de senha é obrigatória' },
                          validate: { value: value => value === watchSecurity('password') || "As senhas não são iguais" }
                        })}
                        placeholder="Confirmar senha"
                        type="password"
                        isInvalid={errorsSecurity.passwordConfirm}
                      />
                      <Form.Control.Feedback type="invalid">{errorsSecurity.passwordConfirm?.message}</Form.Control.Feedback>
                    </Form.Group>

                  </Col>
                </Row>
              <Button type="submit">Alterar senha</Button>
            </Form>
          </div>
          {userProfile?.user?.companies?.edges?.map((company) => (
            <div className={css["cp-my-profile-extra"]} key={company.id}>
              <h2>Informação adicionais - {company.name}</h2>
              <CompanyRequirementForm
                company={company}
                onSave={saveMetaData}
              />
            </div>
          ))}
        </Container>  
      </div>
    </LayoutDefault>
  )
}

export default MyProfile