import React from 'react'
import css from './styles.module.scss'
import Button from 'react-bootstrap/Button'


export default function Boleto({ proccessPayment }) {

  const handleButton = () => {
    proccessPayment({
      method: 'boleto',
    })
  }

  return (
    <div className={css['cp-boleto']}>
      <h1>Atente-se aos detalhes:</h1>
      <ul>
        <li>Boleto (somente à vista)</li>
        <li>Pagamentos com Boleto Bancário levam até 3 dias úteis para serem compensados e então terem os produtos liberados</li>
        <li>Atente-se ao vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa lotérica até o dia do vencimento</li>
        <li>Depois do pagamento, verifique seu e-mail para receber os dados de acesso ao produto (verifique também a caixa de SPAM)</li>
      </ul>
      <Button onClick={handleButton} variant="success">Comprar agora</Button>
    </div>
  )
}
