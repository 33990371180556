import React from 'react'
import css from './styles.module.scss'
import { isAuthenticated } from '../../../services/auth'

const Price = ({type, price, handleClick}) => {
  const textPeriod = (period) => {
    switch(period){
      case 'MONTHLY':
        return 'Plano mensal'
      case "BIMONTHLY":
        return 'Plano bimestral'
      case "TRIMONTHLY":
        return 'Plano trimestral'
      case "SEMIANNUALLY":
        return 'Plano semestral'
      case "YEARLY":
        return 'Plano anual'
      default:
    }
  }
  
  return (
    <div className={`${css["cp-price"]}`}>
      <div className={`${css["cp-price__box"]}`}>
        <div className={`${css["cp-price__info"]}`}>
          <p className={`${css["cp-price__info__name"]}`}>{price.name}</p>
          <p className={`${css["cp-price__info__price"]}`}>R$ {Number(type === 'course' ? price.value : price.price).toFixed(2).replace(".", ",")}</p>
        </div>
        {price.company && 
          <p className={`${css["cp-price__info__description"]}`}>
            Somente para associados {price.company.name}
          </p>
        }
        {type === 'subscription' && 
          <>
            <p className={`${css["cp-price__info__plan-type"]}`}>
              {price.recorrent ? 'Pagamento recorrente' : 'Pagamento Avulso'}
            </p>
            <p className={`${css["cp-price__info__plan-type"]}`}>
              {textPeriod(price.period)}
            </p> 
          </>
        }
        {price.partner && 
          <p className={`${css["cp-price__info__description"]}`}>
            Somente para associados {price.partner.name}
          </p>
        }
      </div>
      <div className={`${css["cp-price__action"]}`}>
        <button 
          className={`
            ${css["cp-price__action__btn"]} 
            ${!price.applicable ? css["cp-price__action__btn--disabled"] : ''}
          `}
          disabled={!price.applicable}
          onClick={() => handleClick(price)}
        >
          Associe-se
        </button>
      </div>
    </div>
  )
}

export default Price