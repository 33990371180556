/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getEmails } from '../../../../http/email'
import ModalEmail from '../Modal'
import SendNow from '../SendNow'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit } from "react-icons/fa"
import { NotificationManager } from 'react-notifications'
import { useParams, Link } from "react-router-dom"

const EmailContext = {
  //confirm_account: 'Confirme a sua conta',
  welcome: 'Seja bem-vindo',
  reset_password: 'Resetar senha',
  enroll_buy: 'Pedido de inscrição',
  subscription_buy: 'Pedido de associação',
  enroll_change: 'Alteração no sua inscrição',
  subscription_change: 'Alteração no sua associação',
  course_remember: 'Lembrete do curso',
  subscription_remember: 'Lembrete de assinatura sem recorrência',
  subscription_ended: 'Aviso de finalização de assinatura',
  import_advise: 'Email de boas vindas pela importação de associados',
  import_enroll_advise: 'Email de boas vindas pela importação de inscritos',
  new_ticket_company: 'Notificação de novo ticket para empresa'
}

const EmailList = () => {

  const size = 10
  const [currentEmail, setCurrentEmail] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalSendNowOpen, setModalSendNowOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [page, setPage] = useState(0)
	const [loading, setLoading] = useState(true)
  const params = useParams()
  const courseId = params.courseId

  const fetchEmails = async () => {
    try{
      const emails = await getEmails({
        ...(courseId ? { courseId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(emails)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    document.title = "Emails - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  useEffect(() => {
    fetchEmails()
  }, [page])

  const editEmail = (data) => {
    setCurrentEmail(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    fetchEmails()
    setCurrentEmail({})
  }

  const columns = useMemo(
    () => [
      {
        Header: "Emails",
        columns: [
          { Header: 'Assunto', accessor: 'subject', sortType: 'basic' }, 
          { Header: 'Contexto',
            accessor: 'context',
            sortType: 'basic', 
            Cell: ({value}) => EmailContext[value]
          }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Button variant="link" onClick={() => editEmail(row.original)}>
                  <FaEdit />
                </Button>
              </div>
            )
          }
        ]
      }
    ], []
  )

  const breadcrumbs = [
    {name: 'Início', url: '/dashboard'},
    {name: "Emails", url: `/dashboard/emails`}
  ]
  

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className={css['pg-email__filter__actions']}>
        <Button onClick={ () => setModalSendNowOpen(true) }>Enviar agora</Button>
      </div>
      <div className="pg-email">
        <Table 
          columns={columns} 
          data={data} 
          fetch={ fetchEmails } 
          size={size}
          className={css["pg-email__table"]}
          setPage={setPage}
        />
      </div>
      {modalOpen && currentEmail ?
        <ModalEmail 
          show={modalOpen}
          handleClose={closeModal}
          currentEmail={currentEmail}
          setLoading={setLoading}
        />
      : null }
      {modalSendNowOpen ?
        <SendNow 
          show={modalSendNowOpen}
          handleClose={() => setModalSendNowOpen(false) }
          setLoading={setLoading}
        />
      : null }
    </DashboradLayout>
  )
}

export default EmailList