import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { saveVoucher, updateVoucher } from '../../../../http/voucher'
import './styles.module.scss'
import { TextField, FormControl, InputLabel, MenuItem, Select, FormHelperText, FormControlLabel, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import { getCompanies } from '../../../../http/company'
import { getUser } from '../../../../services/auth'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
    '& .MuiFormControl-root': {
      margin: '8px 0',
    },
  },
  formControl: {
    margin: '8px 0',
  },
}));

export default function ModalVoucher({ show, handleClose, voucher, course, setLoading }) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoadingAutoComplete] = useState(false)

  const user = getUser()
  const {
    control, handleSubmit, setValue, watch, reset, setError, formState: { errors },
  } = useForm()
  const classes = useStyles()

  useEffect(() => {
    const fields = ['name', 'code', 'type', 'value', 'automatic', 'companyCnpj', 'sendDocument', 'documentDescription', 'limitQuantity', 'endUse']
    if (voucher.id) {
      fields.forEach((field) => setValue(field, voucher[field], { shouldValidate: true }))
      if (voucher.company)
        setValue('companyCnpj', voucher.company.cnpj)
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [voucher, setValue])

  useEffect(() => {
    if (!open)
      setOptions([])
  }, [open])


  const getData = async (e) => {
    setLoadingAutoComplete(true)
    const { data } = await getCompanies({ searchable: e?.target?.value || '' })
    setOptions(data.rows)
    setLoadingAutoComplete(false)
  }

  useEffect(()=>{
    getData()
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    let result = null
    data.courseId = course.id
    if (data.companyCnpj)
      data.companyCnpj = data.companyCnpj.replace(/[^\d]+/g, '')
    if (voucher.id) result = updateVoucher(voucher.id, data)
    else result = saveVoucher(data)

    result.then(e => {
      NotificationManager.success('O voucher foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'
      if (e.response?.status === 422){
        description = e.response?.data?.error?.message
        if (e.response?.data?.error?.errCode === 'not_found_company')
          setError('companyCnpj', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        if (e.response?.data?.error?.errCode === 'duplicate_company')
          setError('companyCnpj', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
      }
      NotificationManager.error(description, title)
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{voucher.id ? 'Editar voucher' : 'Cadastrar voucher'}</Modal.Title>
        </Modal.Header>
        <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row>
              <Col xs={12} md={6}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'O nome do desconto é obrigatório' },
                    minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
                    maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Nome do desconto"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name?.message || ''}
                    />
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Código personalizado"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Controller
                  name="type"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'O tipo da aula é obrigatório' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl 
                      className={classes.formControl} 
                      error={errors.type}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel htmlFor="type">Tipo de desconto</InputLabel>
                      <Select
                        value={value || ''}
                        fullWidth
                        onChange={onChange}
                        inputProps={{
                          id: 'type'
                        }}
                      >
                        <MenuItem value="percentage">Porcentagem</MenuItem>
                        <MenuItem value="value">Valor de desconto</MenuItem>
                      </Select>
                      <FormHelperText>{errors.type?.message || ''}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Col>
              <Col xs={12} md={6}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      customInput={TextField}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      isNumericString
                      prefix={ watch('type') === 'value' ? "R$ " : ''}
                      suffix={ watch('type') === 'value' ? "" : '%' }
                      value={value || ''}
                      variant="outlined"
                      fullWidth
                      label="Valor"
                      error={!!errors.value}
                      helperText={errors.value?.message || ''}
                      onValueChange={(v) => { onChange(v.value) }}
                    />
                  )}
                  rules={{ 
                    required: { value: true, message: 'O valor é obrigatório' },
                    min: { value: 0.01, message: 'O valor deve ser maior que 0' },
                    max: { 
                      value: watch('type') === 'value' ? course.price : 100, 
                      message: `O valor deve ser igual ou menor que ${watch('type') === 'value' ? `R$ ${course.price ? course.price.replace('.', ',') : null}` : '100%'}`
                    },
                  }}
                  name="value"
                  control={control}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  name="limitQuantity"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    min: { value: 0, message: 'O valor deve ser maior ou igual a 0' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label='Quantidade'
                      variant="outlined"
                      type='number'
                      value={isNaN(parseInt(value)) ? '' : parseInt(value)}
                      onChange={onChange}
                      fullWidth
                      error={!!errors?.limitQuantity}
                      helperText={errors?.limitQuantity?.message || ''}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="endUse"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Expira em"
                      value={value || null}
                      onChange={onChange}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true} 
                      invalidDateMessage="Data inválida"
                      error={errors.endUse}
                      helperText={errors.endUse?.message || ''}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  render={({ field: { onChange, value } }) => {
                    if (user.role === 'admin') {
                      return (
                        <Autocomplete 
                          autoComplete={true}
                          getOptionSelected={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name || ''}
                          options={options}
                          onInputChange={getData}
                          onChange={(event, choosen) => onChange(choosen?.cnpj || '')}
                          onOpen={() => { setOpen(true) }}
                          onClose={() => { setOpen(false) }}
                          loading={loading}
                          filterOptions={(x) => x} 
                          defaultValue={voucher.company}
                          renderInput={(props) => {
                            return (
                              <TextField
                                {...props}
                                label="Somente para associados da empresa"
                                variant="outlined"
                                fullWidth
                              />
                            )
                          }}
                        />
                      )
                    }
                    return (
                      <NumberFormat
                        customInput={TextField}
                        format='##.###.###/####-##'
                        value={value || ''}
                        variant="outlined"
                        fullWidth
                        label="Somente para associados da empresa"
                        error={!!errors.companyCnpj}
                        helperText={errors.companyCnpj?.message || ''}
                        onValueChange={(v) => { onChange(v.value) }}
                      />
                    )
                  }}
                  name="companyCnpj"
                  control={control}
                />
              </Col>
              <Col>
                <Controller
                  name="sendDocument"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch checked={value || false} onChange={onChange} />}
                      label="Enviar documento?"
                    />
                  )}
                />
                <Controller
                  name="automatic"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch checked={value || false} onChange={onChange} />}
                      label="Desconto público?"
                    />
                  )}
                />
              </Col>
            </Row>
            {watch('sendDocument') ? 
              <Controller
                name="documentDescription"
                control={control}
                defaultValue=""
                rules={{ 
                  required: { value: watch('sendDocument'), message: 'A descrição do documento é obrigatória' },
                  minLength: { value: 3, message: 'A descrição do documento deve ter pelo menos 3 caracteres' },
                  maxLength: { value: 255, message: 'A descrição do documento não pode ser maior que 255 caracteres' },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Descrição do documento a ser enviado"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    error={errors.documentDescription}
                    helperText={errors.documentDescription?.message || ''}
                  />
                )}
              />
            : null}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">{voucher.id ? 'Salvar' : 'Cadastrar'}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}
