import React, { useState } from 'react'
import css from './styles.module.scss'
import { FaQuestion } from 'react-icons/fa'
import ModalOpenTicket from './Modal'

const OpenTicket = ({ companyId = null }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleModal = () => {
    setOpenModal(prevState => !prevState)
  }

  if (!companyId)
    return null

  return (
    <div>
      <div className={css["cp-ticket-btn"]} onClick={handleModal}>
        <FaQuestion />
      </div>
      <ModalOpenTicket show={openModal} handleClose={handleModal} companyId={ companyId }/>
    </div>
  )
}

export default OpenTicket