import React, { useEffect, useState } from 'react'
import Cards from 'react-credit-cards';
import css from './styles.module.scss'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import InputMask from "react-input-mask"
import { Controller, useForm } from "react-hook-form"
import 'react-credit-cards/es/styles-compiled.css';
import { NotificationManager } from 'react-notifications';
import { requestCardHash } from '../../../util/Pagarme';


export default function CreditCard({  
  PagPayment,
  price,
  maxInstallmentNoInterest,
  proccessPayment,
  setInstallment,
  setLoading,
  hasInstallment,
  paymentGateway,
  installmentsFromServer,
}) {
  const [focus, setFocus] = useState('')
  const [brand, setBrand] = useState(null)
  const [installments, setInstallments] = useState(installmentsFromServer)
  const {
    setError, setValue, watch, control, handleSubmit, formState: { errors },
  } = useForm()
  const handleInputFocus = (e) => {
    setFocus(e.target.name)
  }

  useEffect(() => {
    setInstallments(installmentsFromServer)
  }, [installmentsFromServer])

  const getBrand = async (cardNumber) => {
    return new Promise ((resolve, reject) =>
      PagPayment.getBrand({
        cardBin: cardNumber.substring(0,6),
        success: async response => {
          setBrand(response.brand.name)
          if (hasInstallment) {
            const { installments } = await getInstallments(response.brand.name)
            setInstallments(installments[response.brand.name])
          }
          setValue('installment', hasInstallment ? 0 : 1)
          resolve(response)
        },
        error: response => {
          if (response.error) {
            setError('number', 
              {
                type: 'invalid', 
                message: 'Número do cartão inválido',
              },
              { shouldFocus: true }
            )
          }else{
            NotificationManager.error("Erro na comunicação com o PagSeguro. Recarregue a página e tente novamente.")
          }
          reject(response)
        }
      })
    )
  }

  const getInstallments = async (brand) => {
    return new Promise ((resolve, reject) => {
      PagPayment.getInstallments({
        amount: Number(price).toFixed(2),
        maxInstallmentNoInterest,
        brand,
        success: response => resolve(response),
        error: response => {
          NotificationManager.error("Erro na comunicação com o PagSeguro. Recarregue a página e tente novamente.")
          reject(response)
        }
      })
    })
  }

  const pagarmeDict = [{
    error: "The number field is not a valid card number",
    field: "number",
    message: "Número do cartão inválido"
  },{
    error: "You may not use numbers or special characters.",
    field: "name",
    message: "O nome não deve ter números ou caracteres especiais"
  }, {
    error: "Card expired.",
    field: "expiry",
    message: "Cartão expirado"
  }]

  const createCardToken = async ({
    cardNumber,
    cvv,
    holderName,
    expirationMonth,
    expirationYear,
    encryptKey
  }) => {
    if (paymentGateway.paymentGatewayId === 1){
      return new Promise ((resolve, reject) => {
        PagPayment.createCardToken({
          cardNumber,
          brand,
          cvv,
          expirationMonth,
          expirationYear,
          success: ({ card }) => resolve(card.token),
          error: response => {
            if (response.error) {
              Object.entries(response.errors).map((value) => {
                switch (value[0]){
                  case "10006":
                    setError('cvc', 
                      {
                        type: 'invalid', 
                        message: 'O código de segurança é inválido',
                      },
                      { shouldFocus: true }
                    )
                    break;
                  case "30400":
                    NotificationManager.error("Revise os dados do seu cartão e tente novamente!")
                    break    
                  default:
                }
              })
            }
            reject(response)
          }
        })
      })
    } else if (paymentGateway.paymentGatewayId === 2){
      return new Promise (async (resolve, reject) => {
        try {
          let result = await requestCardHash({
            number: cardNumber,
            holder_name: holderName,
            exp_month: expirationMonth,
            exp_year: expirationYear,
            cvv,
          }, encryptKey)
          resolve(result)
        } catch (e) {
          if (e.response?.data?.errors) {
            let errors = e.response.data.errors
            

            for (const [key, value] of Object.entries(errors)) {
              for (let error of value) {
                pagarmeDict.every((dict) => {
                if (error === dict.error) {
                  setError(dict.field, 
                    {
                      type: 'invalid', 
                      message: dict.message,
                    },
                    { shouldFocus: true }
                  )
                  return false;
                }
                return true;
              })
              }
            }
            setLoading(false)
            throw e
          } else{
            NotificationManager.error("Erro na comunicação com o Pagarme. Recarregue a página e tente novamente.")
          }
          reject(e)      
        }
      })
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
    const cardHash = await createCardToken({
      cardNumber: data.number.replace(/\s/gi, ''),
      cvv: data.cvc,
      expirationMonth: data.expiry.split("/")[0],
      expirationYear: `20${data.expiry.split("/")[1]}`,
      holderName: data.name,
      encryptKey: paymentGateway.publicKey,
    })
    if (cardHash) {
      proccessPayment({
        method: 'creditCard',
        cardHash,
        buyerName: data.name,
        installmentQuantity: hasInstallment && installments ? installments[data.installment || 0].quantity : 1,
        installmentValue: hasInstallment && installments ? installments[data.installment || 0].installmentAmount : price
      })
    }} catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={css['cp-credit-card']}>
      <Row>
        <Col lg={6}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>NÚMERO DO CARTÃO</Form.Label>
            <Controller
              control={control}
              name="number"
              rules={{ required: { value: true, message: 'O número do cartão é obrigatório' } }}
              render={({ field: { onChange, value } }) => (
                <InputMask 
                  onChange={onChange}
                  onBlur={(event) => {
                    const clear = (event.target.value || '').replace(" ", "")
                    if (clear.length > 6 && paymentGateway.paymentGatewayId === 1){
                      getBrand(clear)
                    }
                  }}
                  value={value || ''}
                  maskChar=""
                  name="number"
                  mask="9999 9999 9999 9999"
                  onFocus={handleInputFocus}
                  placeholder="Digite somente números"
                >
                  {(inputProps) => 
                    <Form.Control
                      {...inputProps}
                      isInvalid={errors.number}
                    />
                  }
                </InputMask>
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.number?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>TITULAR DO CARTÃO</Form.Label>
            <Controller
              control={control}
              name="name"
              rules={{ 
                required: { value: true, message: 'O nome é obrigatório' },
                minLength: { value: 3, message: 'O nome deve ter no mínimo 3 caracteres'},
                maxLenght: { value: 255, message: 'O nome deve ter até 255 caracteres'}
              }}
              render={({ field: { onChange, value } }) => (
                <Form.Control
                  name="name"
                  isInvalid={errors.name}
                  onChange={ onChange}
                  value={value || ''}
                  placeholder="Digite o nome como impresso no cartão"
                />
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>DATA DE VALIDADE</Form.Label>
            <Controller
              control={control}
              name="expiry"
              rules={{ 
                required: { value: true, message: 'A data de validade é obrigatória' },
                pattern: {
                  value: /[0-9][0-9]\/[0-9][0-9]/,
                  message: "Digite a data no fomato MM/AA"
                }
              }}
              render={({ field: { onChange, value } }) => (
                <InputMask
                  onChange={onChange}
                  value={value || ''}
                  maskChar=""
                  mask="99/99"
                  name="expiry"
                  onFocus={handleInputFocus}
                >
                  {(inputProps) => 
                    <Form.Control
                      {...inputProps}
                      isInvalid={errors.expiry}
                      placeholder="Digite somente números"
                    />
                  }
                </InputMask>
              )}
            />
            <Form.Control.Feedback type="invalid">{errors.expiry?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>CÓDIGO DE SEGURANÇA</Form.Label>
            <Controller
              control={control}
              name="cvc"
              rules={{ 
                required: { value: true, message: 'O cvc é obrigatório' },
                minLength: { value: 3, message: 'O cvc deve ter no mínimo 3 dígitos'},
                maxLength: { value: 4, message: 'O cvc deve ter até 4 dígitos'}
              }}
              render={({ field: { onChange, value } }) => (
                <InputMask 
                  onChange={onChange}
                  value={value || ''}
                  maskChar=""
                  mask="9999"
                  name="cvc"
                  onFocus={handleInputFocus}
                >
                  {(inputProps) => 
                    <Form.Control
                      {...inputProps}
                      isInvalid={errors.cvc}
                      placeholder="3 ou 4 dígitos"
                    />
                  }
                </InputMask>
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.cvc?.type === 'value' ? 'CVC inválido' : (errors.cvc?.message || '')}
            </Form.Control.Feedback>
          </Form.Group>
          {hasInstallment && 
            <Form.Group>
              <Form.Label>OPÇÕES DE PARCELAMENTO</Form.Label>
              <Controller
                control={control}
                name="installment"
                rules={{ 
                  required: { value: false, message: 'Selecione uma opção de parcelamento' },
                }}
                render={({ field: { onChange, value } }) => (
                  <Form.Control as="select"
                    onChange={(e) => {
                      setInstallment(installments[e.target.value])
                      onChange(e)
                    }} 
                    value={value} 
                    disabled={!installments}
                  >
                    { installments && 
                      installments.map(({installmentAmount, interestFree, quantity, totalAmount}, index) => 
                        <option key={quantity} value={index}>
                          {quantity}x de R$ {installmentAmount.toFixed(2).replace('.', ',')}{!interestFree ? '*' : ''}
                        </option>
                      )
                    }
                  </Form.Control>
                )}
              />
              <Form.Control.Feedback type="invalid">{errors.cvc?.message}</Form.Control.Feedback>
            </Form.Group>
          }
          <Button type="submit" variant="success">Comprar agora</Button>
        </Form>
        </Col>
        <Col lg={6} className="d-none d-lg-block" >
          <Cards
            cvc={watch('cvc') || ""}
            expiry={watch('expiry') || ""}
            focused={focus}
            name={watch('name') || ""}
            number={watch('number') || ""}
          />          
        </Col>
      </Row>
    </div>
  )
}
