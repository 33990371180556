import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getCompanies, deleteCompany } from '../../../../http/company'
import ModalCompany from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaArrowRight, FaPlus, FaTrash } from "react-icons/fa"
import { Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

const CompanyList = () => {

  const size = 10
  const [currentCompany, setCurrentCompany] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
	const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchCompanies()
  }, [searchable, page])

  useEffect(() => {
    document.title = "Empresas - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const editCompany = (data) => {
    setCurrentCompany(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    fetchCompanies()
    setCurrentCompany({})
    setModalOpen(false)
  }

  const addCompany = () => {
    setCurrentCompany({})
    setModalOpen(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Empresas",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' }, 
          { 
            Header: 'CNPJ',
            accessor: 'cnpj', 
            Cell: ({cell: { value }}) => {
              return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            }
          }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Button variant="link" onClick={() => editCompany(row.original)}>
                  <FaEdit />
                </Button>
                <Button variant="link" onClick={() => deleteItem(row.original)}>
                  <FaTrash />
                </Button>
                <Link to={{pathname: `/dashboard/empresa/${row.original.id}/cursos`}}>
                  <FaArrowRight />
                </Link>
              </div>
            )
          }
        ]
      }
    ], []
  );

  const fetchCompanies = async () => {
    try{
      const companies = await getCompanies({
        ...( searchable ? { searchable } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(companies.data)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja deletar esse empresa? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deleteCompany(item.id)
        setSearchable('')
        fetchCompanies()
        setLoading(false)
        NotificationManager.success('Empresa excluída com sucesso', 'Deletado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }
  

  return (
    <DashboradLayout loading={loading} breadcrumbs={[
      {name: 'Início', url: '/dashboard'},
      {name: 'Empresas', url: '/dashboard/empresas'}
    ]}>
      <div className="pg-company">
        <Row className={css['pg-company__filter']}>
          <Col className={css['pg-company__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome ou CNPJ' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          <Col xs={3} className={css['pg-company__filter__actions']}>
            <Button onClick={ addCompany }><FaPlus /> Nova</Button>
          </Col>
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={ fetchCompanies } 
          size={size}
          className={css["pg-company__table"]}
          setPage={setPage}
        />
      </div>
      <ModalCompany 
        show={modalOpen}
        handleClose={closeModal}
        company={currentCompany}
        setLoading={setLoading}
      />
    </DashboradLayout>
  )
}

export default CompanyList