/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getSpeakers, deleteSpeaker } from '../../../../http/speaker'
import ModalSpeaker from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa"
import { useParams, Link } from "react-router-dom"
import { getCourse } from '../../../../http/course'
import { NotificationManager } from 'react-notifications'

const SpeakerList = () => {

  const size = 10
  const [currentSpeaker, setCurrentSpeaker] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const params = useParams()
  const [course, setCourse] = useState({})
	const [loading, setLoading] = useState(true)
  const courseId = params.courseId

  const fetchSpeakers = async () => {
    try{
      const speakers = await getSpeakers({
        ...( searchable ? { searchable } : {}),
        ...(courseId ? { courseId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(speakers)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchSpeakers()
  }, [searchable, page])

  const editSpeaker = (data) => {
    setCurrentSpeaker(data)
    setModalOpen(true)
  }

  const fetchCourse = async () => {
    try{
      const course = await getCourse(courseId)
      setCourse(course)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    document.title = "Palestrantes - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
    fetchCourse()
  }, [])

  const closeModal = () => {
    setSearchable('')
    fetchSpeakers()
    setCurrentSpeaker({})
    setModalOpen(false)
  }

  const addSpeaker = () => {
    setSearchable('')
    setCurrentSpeaker({})
    setModalOpen(true)
  }

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja deletar esse palestrante? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deleteSpeaker(item.id)
        setSearchable('')
        fetchSpeakers()
        setLoading(false)
        NotificationManager.success('Palestrante excluído com sucesso', 'Deletado com sucesso')
      }catch(e){
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Cursos",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' },
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Button variant="link" onClick={() => editSpeaker(row.original)}>
                  <FaEdit />
                </Button>
                <Button variant="link" onClick={() => deleteItem(row.original)}>
                  <FaTrash />
                </Button>
              </div>
            )
          }
        ]
      }
    ], []
  );

  const breadcrumbs = course ? [
    {name: 'Início', url: '/dashboard'},
    {name: course.name, url: `/dashboard/empresa/${course.companyId}/curso/${course.id}/aulas`},
    {name: "Palestrantes", url: `/dashboard/curso/${course.id}/professores`}
  ] : []

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-speaker">
        <Row className={css['pg-speaker__filter']}>
          <Col className={css['pg-speaker__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          {courseId && 
            <Col xs={3} className={css['pg-speaker__filter__actions']}>
              <Button onClick={ addSpeaker }><FaPlus /> Novo</Button>
            </Col>
          }
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchSpeakers} 
          size={size}
          className={css["pg-speaker__table"]}
          setPage={setPage}
        />
      </div>
      <ModalSpeaker 
        show={modalOpen}
        handleClose={closeModal}
        speaker={currentSpeaker}
        course={course}
        setLoading={setLoading}
      />
    </DashboradLayout>
  )
}

export default SpeakerList