import React from 'react'
import { BoxLoading } from 'react-loadingg'
import css from './styles.module.scss'

export default function Loader() {
  return (
    <div className={css['loader-body']}>
      <BoxLoading size="large" color="#0CB59F" />
    </div>
  )
}