import React, {useEffect, useState} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../layouts/default'
import { Link } from "react-router-dom"
import { listCompaniesPublic } from '../../http/company'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { NotificationManager } from 'react-notifications'
const server = process.env.REACT_APP_API_URL || null

const Home = () => {
	const [companies, setCompanies] = useState(null)
	const [loading, setLoading] = useState(false)
  const [openCompleteModal, setOpenCompleteModal] = useState(false)
  
  const fetchCompanies = () => {
    setLoading(true)
    listCompaniesPublic().then(e => {
      setCompanies(e)
    }).catch(e => {
      NotificationManager.error("Erro ao carregar empresas")
    }).finally(e => {
      setLoading(false)
    })
  }

  useEffect(() => {
    document.title = `Alissem`
    document.getElementsByTagName("META")[1].content=document.title
    fetchCompanies()
  }, [])

	return (
		<LayoutDefault loading={loading} openCompleteModal={openCompleteModal}>
			{!loading && companies && (
				<div className={css['cp-company-home']}>
					<Container>
            <Row>
              {companies.rows?.map(company => (
                <Col md={4} key={company.id}>
                  <Link to={`/empresa/${company.permalink}`} className={css["cp-company-home__content"]}>
                    <div className={css["cp-company-home__logo"]}>
                      <div className={css["cp-company-home__preview"]}>
                        <img src={`${server}${company.logo}`}  alt={company.name} />
                      </div>
                    </div>
                    <p className={css["cp-company-home__title"]}>{company.name}</p>
                  </Link>
                </Col>
              ))}
            </Row>
					</Container>
				</div>				
			)}
		</LayoutDefault>
	)
}

export default Home