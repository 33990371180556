import React from 'react'
import { NotificationManager } from 'react-notifications'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import css from './styles.module.scss'
import { isAuthenticated } from '../../services/auth'
import Price from './Price'

const PriceList = ({type='course', productId, normalPrice, priceName, prices, setPrice, handleClick, buyable, course}) => {
  const logged = isAuthenticated()
  const handleSelectPrice = (voucher) => {
    if (!logged){
      window.location.href = `/login?redirect=${type=== 'course' ? `/curso/${course.company.permalink}/${productId}` : `/empresa/${productId}`}`
      return 
    }
    if (voucher.applicable){
      setPrice(voucher)
      handleClick(voucher.value, productId)
      return
    }
  }
  return (
    <div className={css["cp-price-list"]}> 
      <Row>
        {type === 'course' &&
          <Col md={3}>
            <Price
              handleClick={handleSelectPrice}
              price={{
                applicable: buyable,
                company: null,
                value: Number(normalPrice),
                id: null,
                name: priceName || "Valor Integral",
              }}
              type={type}
            />
          </Col>
        }
        { prices.map((price, index) => {
          //if (!price.applicable && logged)
          //  return null
          return (
            <Col md={3} key={index}>
              <Price type={type} price={price} handleClick={handleSelectPrice}/>
            </Col>
          )
        }) }
      </Row>
    </div>
  )
}

export default PriceList