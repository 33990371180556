import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useForm } from 'react-hook-form'
import { login, resetPassword } from '../../../http/auth'
import { login as serviceLogin, redirect } from '../../../services/auth'
import SHA256 from "crypto-js/sha256"
import css from './styles.module.scss'
import { FaRegUser } from 'react-icons/fa'
import { RiLockPasswordLine } from 'react-icons/ri'
import { BsArrowRightShort } from 'react-icons/bs'
import { NotificationManager } from 'react-notifications'
import { useLocation } from "react-router-dom"


const Login = ({ setLoading, setOpen }) => {
  const {
    register, handleSubmit, setError, watch, formState: { errors },
  } = useForm()

  const { search } = useLocation()

  const onSubmit = (data) => {
    setLoading(true)
    const user = data.login.indexOf('@') !== -1 ? data.login : data.login.replace(/\./g,'').replace(/-/g,'') 
    login({
      user,
      password: SHA256(data.password).toString()
    }).then( res => {
      if(res.data && res.data.auth){
        const {token, user} = res.data
        serviceLogin(token, user)
        redirect(search, user.role)
      }
    }).catch( e => {
      if (e.response.status === 404 && e.response.data.error?.errCode === 'not_exists'){
        setError('login', {type: 'required',  message: e.response?.data?.error?.message}, { shouldFocus: true })
        setError('password', {type: 'required',  message: e.response?.data?.error?.message}, { shouldFocus: false })
      }
      setLoading(false)
    })
  }

  const handleResetPassword = async () => {
    const login = watch('login')

    if (!login || login === '' ) {
      setError('login', {type: 'required',  message: 'Digite o seu email ou CPF'}, { shouldFocus: true })
      return 
    }

    try {
      await resetPassword({ login })
      NotificationManager.success('Foi enviado um email para resetar a sua senha', 'Email enviado')
    }catch(e){
      NotificationManager.error('O usuário não existe ou ocorreu um erro no servidor! Tente novamente', 'Ocorreu um erro')
    }
  }

  return (
    <div className={css["cp-login"]}>
      <h2>Login</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3">
          <FaRegUser />
          <Form.Control
            {...register('login', {
              required: { value: true, message: 'O CPF ou email é obrigatório' },
              minLength: { value: 3, message: 'O CPF ou email deve ter pelo menos 3 caracteres' },
              maxLength: { value: 255, message: 'O CPF ou email não pode ser maior que 255 caracteres' },
            })}
            placeholder="CPF ou Email"
            isInvalid={errors.login}
          />
          <Form.Control.Feedback type="invalid">{errors.login?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <RiLockPasswordLine />
          <Form.Control
            {...register('password', {
              required: { value: true, message: 'A senha é obrigatória' },
            })}
            placeholder="Senha"
            isInvalid={errors.password}
            type="password"
          />
          <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
        </Form.Group>
        <p>
          <div className={css["button"]} onClick={handleResetPassword}>Esqueci minha senha</div>
        </p>
        <div className={`d-block d-md-none ${css["button"]}`} onClick={setOpen}>
          <p>Quero me cadastrar</p>
        </div>
        <Button type="submit"><BsArrowRightShort /></Button>
      </Form>
    </div>
  )
}

export default Login
