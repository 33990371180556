import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import { associateTeacher } from '../../../../http/course'
import css from './styles.module.scss'
import NumberFormat from 'react-number-format'
import { validateCpf } from '../../../../util/validate'

export default function ModalTeacher({ show, handleClose, teacher, courseId, setLoading }) {
  const {
    handleSubmit, control, reset, setError, formState: { errors },
  } = useForm()


  const onSubmit = async (data) => {
    setLoading(true)
    data.teacherCpf = data.teacherCpf.replace(/[^\d]+/g, '')
    let result = associateTeacher(courseId, {...data, courseId})

    result.then(e => {
      NotificationManager.success('O professor foi associado com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'
      if (e.response?.status === 422){
        description = e.response?.data?.error?.message
        if (e.response?.data?.error?.errCode === 'not_found')
          setError('teacherCpf', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
      }
      
      NotificationManager.error(description, title)
    }).finally(e=>{
      setLoading(true)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Associar professor</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Controller
            render={({ field: { onChange, value } }) => (
              <NumberFormat
                customInput={TextField}
                format='###.###.###-##'
                value={value || ''}
                variant="outlined"
                fullWidth
                label="CPF do professor cadastrado"
                error={errors.teacherCpf}
                helperText={errors.teacherCpf?.message && errors.teacherCpf?.type === 'value' ? 'CPF inválido' : errors.teacherCpf?.message || ''}
                onValueChange={(v) => {
                  onChange(v.value)
                }}
              />
            )}
            rules={{
              required: { value: true, message: 'O CPF é obrigatório' },
              validate: { value: validateCpf },
            }}
            name="teacherCpf"
            control={control}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">Salvar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
