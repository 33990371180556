import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { DateTime } from 'luxon'
import { Link } from "react-router-dom"
import { BsChevronRight } from 'react-icons/bs'
import { getAditionals } from '../../../http/subscriptionAditionalContent'
import NotificationManager from 'react-notifications/lib/NotificationManager'
const server = process.env.REACT_APP_API_URL || null

export default function ModalSubscriptionDetails({ show, handleClose, subscription, setLoading}) {
  //const [aditionalContents, setAditionalContents] = useState(null)
  
  const close = () => {
    handleClose()
  }

  const statusSubscriptionText = (status, expiryDate) => {
    switch(status.toLowerCase()){
      case 'active': return 'Ativo'
      case 'cancelled_by_receiver': return 'Cancelado pelo vendedor'
      case 'cancelled_by_sender': return 'Cancelado pelo comprador'
      case 'cancelled': case 'canceled': return 'Cancelado'
      case 'failed': return 'Falha no pagamento'
      case 'expired': return 'Expirado'
      case 'pending': return 'Aguardando processamento do pagamento'
      case 'suspended': return 'Suspensa'
      case 'payment_method_change': return 'Aguardando troca de cartão'
      case 'document_pending': return 'Documento enviado em análise'
      case 'document_denied': return 'Documento enviado negado'
      case 'document_not_sended': return 'Documento não enviado'
      default: return
    }
  }

  // const fetchAditionals = async () => {
  //   try {
  //     setLoading(true)
  //     const data = await getAditionals({ companyId: subscription.companyId })
  //     setAditionalContents(data.rows)
  //   } catch (e) {
  //     NotificationManager.error("Erro no servidor", "Erro ao buscar materiais complementares.")
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // useEffect(() => {
  //   if (show) {
  //     fetchAditionals()
  //   }
  // }, [show])
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>Informações de Associação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={css['cp-modal-details-body']}>
          <h2>Detalhes da assinatura</h2>
          <p>Data incial: <span>{DateTime.fromJSDate(new Date(subscription.startDate)).toFormat("dd/MM/yyyy")}</span></p>
          {subscription.endDate && 
            <p>Próxima renovação: <span>{DateTime.fromJSDate(new Date(subscription.endDate)).toFormat("dd/MM/yyyy")}</span></p>
          }
          <p>Status: <span>{statusSubscriptionText(subscription.status)} {statusSubscriptionText(subscription.status) === 'Ativo' && subscription.canceled_recurrence ? "(Recorrência cancelada)" : ""}</span></p>
          {subscription.lastTransaction &&
            <p>Última modificação: <span>{subscription.lastTransaction}</span></p>
          }

          <h2>Detalhes do plano:</h2>
          <p>Nome: <span>{subscription.plan.name}</span></p>
          <p>Modelo de cobrança: <span>{subscription.plan.recorrent ? "Recorrente" : "Compra avulsa"}</span></p>
          {/* {aditionalContents?.length ? 
            <div>
              <h2>Materiais complementares:</h2>
              <ul className={css["cp-lessons"]}>
                {aditionalContents.map(aditionalContent => {
                  let blocked = new Date(aditionalContent.date) > new Date()
                  return (
                    <li key={aditionalContent.id} className={`${css["lesson__item"]} ${css["blocked"]}`}>
                      { blocked ? 
                        <p>
                          {aditionalContent.name} 
                          <span className={`${css["lesson__item__unblock"]}`}>
                            Libera em {DateTime.fromISO(aditionalContent.date).toFormat("dd/MM/yyyy 'às' HH:mm")}
                          </span>
                        </p> :
                        <Link to={`/complementar/associacao/${aditionalContent.id}`}>
                          {aditionalContent.name} 
                        </Link>
                      }
                      <BsChevronRight />
                    </li>
                  )
                })}
              </ul>
            </div>
          : null} */}
        </div>
        {subscription.code &&
          <a 
            className={`${css['cp-my-course__go']}`}
            target="_blank"
            href={`${server}/api/subscription/${subscription.code}/getCard`}
            rel="noreferrer"
          >
            Baixar
          </a>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={close}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
