import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import Vimeo from '@u-wave/react-vimeo'
import YouTube from 'react-youtube'
const server = process.env.REACT_APP_API_URL || null

export default function ModalPreview({ show, handleClose, content }) {

  const close = () => {
    handleClose()
  }

  const showContent = () => {
    switch(content.type){
      case 'youtube':
        var video_id = content.content.split('v=')[1]
        var ampersandPosition = video_id ? video_id.indexOf('&') : -1
        if(ampersandPosition !== -1)
          video_id = video_id.substring(0, ampersandPosition)
        return (
          <YouTube 
            id="ytplayer"
            videoId={video_id}
          />
        )
      case 'vimeo':
        return (
          <Vimeo
            video={content.content}
            autoplay={false}
            speed={true}
            width="640" 
            height="360"
          />
        )
      case 'image':
        return <img src={`${server}${content.linkAttachment}`} alt={content.name} />
      case 'text':
        return <div dangerouslySetInnerHTML={{__html: content.content}}></div>
      default:
        return <a href={`${server}${content.linkAttachment}`} target="_blank" rel="noreferrer">{content.name}</a>
    }
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>{content.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content.content && showContent()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
