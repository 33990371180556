import axios from './api';

export const proccess = async (params) => {
  try {
    const { data } = await axios.post('api/transaction/makePayment', params)
    return data
  } catch (e) {
    throw e
  }
}

export const proccessSubscription = async (params) => {
  try {
    const { data } = await axios.post('api/subscription/proccess', params)
    return data
  } catch (e) {
    throw e
  }
}

export const generateSession = async (params) => {
  try {
    const { data } = await axios.post('api/generateSession', params)
    return data
  } catch (e) {
    throw e
  }
}

export const checkCredentials = async (params) => {
  try {
    const { data } = await axios.get('api/checkCredentials', { params })
    return data
  } catch (e) {
    throw e
  }
}

export const myOrders = async (params) => {
  try {
    const { data } = await axios.get('api/my-orders', { params })
    return data
  } catch (e) {
    throw e
  }
}

