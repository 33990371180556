import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { savePlan, updatePlan } from '../../../../http/plan'
import { TextField, FormControlLabel, Switch, MenuItem, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core'
import css from './styles.module.scss'
import NumberFormat from 'react-number-format'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { getUser } from '../../../../services/auth'
import { getCompanies } from '../../../../http/company'

const server = process.env.REACT_APP_API_URL || null
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
    '& .MuiFormControl-root': {
      margin: '8px 0',
    },
  },
}));

export default function ModalPlan({ show, handleClose, plan, companyId, setLoading, handleOpenImport }) {
  const {
    watch, handleSubmit, setValue, reset, setError, control, formState: { errors },
  } = useForm()
  const classes = useStyles()
  const [certBase, setCertBase] = useState(null)
  const [open, setOpen] = useState(false)
  const [certBasePreview, setCertBasePreview] = useState(null)
  const [options, setOptions] = useState([])
  const [loading, setLoadingAutoComplete] = useState(false)

  const user = getUser()

  useEffect(() => {
    const fields = ['name', 'period', 'price', 'recorrent', 'status', 'sendDocument', 'documentDescription']
    if (plan.id) {
      fields.forEach((field) => setValue(field, plan[field]))
      if (plan.certUrl) setCertBasePreview(`${server}${plan.certUrl}`)
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [plan, setCertBasePreview, setValue])

  const getData = async (e) => {
    setLoadingAutoComplete(true)
    const { data } = await getCompanies({ searchable: e?.target?.value || '' })
    setOptions(data.rows)
    setLoadingAutoComplete(false)
  }

  useEffect(()=>{
    setValue('price', '0.00')
    getData()
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    let result = null
    data.planId = plan.id
    
    var formData = new FormData()
    formData.append("name", data.name)
    formData.append("period", data.period)
    formData.append("price", data.price || '0.00')
    formData.append("sendDocument", data.sendDocument)
    formData.append("status", data.status)
    formData.append("companyId", companyId)
    formData.append("recorrent", data.recorrent)
    formData.append("certFile", certBase)
    formData.append("documentDescription", data.documentDescription)

    if (data.companyCnpj) 
      formData.append("companyCnpj", data.companyCnpj)

    if (plan.id) result = updatePlan(plan.id, formData)
    else result = savePlan(formData)

    result.then(e => {
      NotificationManager.success('O plano foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {let title = 'Ocorreu um erro', description = 'Erro no servidor'
      if (e.response?.status === 422){
        description = e.response?.data?.error?.message
        if (e.response?.data?.error?.errCode === 'not_found_company')
          setError('companyCnpj', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        if (e.response?.data?.error?.errCode === 'duplicate_company')
          setError('companyCnpj', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        description = e.response?.data?.error?.message || description
        title = e.response?.data?.error?.reason || title
      }
      NotificationManager.error(description, title)
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    setCertBase(null)
    setCertBasePreview(null)
    handleClose()
  }

  const onCertChange = (event) => {
    setCertBasePreview(URL.createObjectURL(event.target.files[0]))
    setCertBase(event.target.files[0])
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>{plan.id ? 'Editar ' : 'Cadastrar '}plano</Modal.Title>
      </Modal.Header>
      <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
            {plan.id && plan.planCode && <p>Plano PagSeguro: {plan.planCode}</p>}
            <Row>
              <Col>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'O nome do plano é obrigatório' },
                    minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
                    maxLength: { value: 100, message: 'O nome não pode ser maior que 255 caracteres' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      disabled={plan.id}
                      label="Nome"
                      variant="outlined"
                      value={value || ''}
                      onChange={onChange}
                      fullWidth
                      error={errors.name}
                      helperText={errors.name?.message || ''}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="period"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'O período de cobrança é obrigatório' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl 
                      className={classes.formControl} 
                      error={errors.period}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel htmlFor="period">Período de cobrança</InputLabel>
                      <Select
                        disabled={plan.id}
                        label="Período de cobrança"
                        value={value || ''}
                        fullWidth
                        onChange={onChange}
                        inputProps={{
                          id: 'period',
                        }}
                      >
                        <MenuItem value="MONTHLY">Mensal</MenuItem>
                        <MenuItem value="BIMONTHLY">Bimestral</MenuItem>
                        <MenuItem value="TRIMONTHLY">Trimestral</MenuItem>
                        <MenuItem value="SEMIANNUALLY">Semestral</MenuItem>
                        <MenuItem value="YEARLY">Anual</MenuItem>
                      </Select>
                      <FormHelperText>{errors.type?.message || ''}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      disabled={plan.id}
                      customInput={TextField}
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={2}
                      isNumericString
                      prefix={"R$ "}
                      value={value}
                      variant="outlined"
                      fullWidth
                      label="Valor da associação"
                      error={errors.price}
                      helperText={errors.price?.message || ''}
                      onValueChange={(v) => {
                        onChange(v.value)
                      }}
                    />
                  )}
                  name="price"
                  control={control}
                />
              </Col>
              <Col>
                <Controller
                  name="recorrent"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      disabled={plan.id}
                      control={
                        <Switch checked={value || false} onChange={onChange} />}
                      label="Pagamento recorrente?"
                    />
                  )}
                />
                <Controller
                  name="sendDocument"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Switch checked={value || false} onChange={onChange} />}
                      label="Enviar documento?"
                    />
                  )}
                />
              </Col>
            </Row>
            {watch('sendDocument') ? 
              <Controller
                name="documentDescription"
                control={control}
                defaultValue=""
                rules={{ 
                  required: { value: watch('sendDocument'), message: 'A descrição do documento é obrigatória' },
                  minLength: { value: 3, message: 'A descrição do documento deve ter pelo menos 3 caracteres' },
                  maxLength: { value: 255, message: 'A descrição do documento não pode ser maior que 255 caracteres' },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Descrição do documento a ser enviado"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    error={errors.documentDescription}
                    helperText={errors.documentDescription?.message || ''}
                  />
                )}
              />
            : null}
            <Controller
              render={({ field: { onChange, value } }) => {
                if (user.role === 'admin') {
                  return (
                    <Autocomplete 
                      autoComplete={true}
                      getOptionSelected={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name || ''}
                      options={options}
                      onInputChange={getData}
                      onChange={(event, choosen) => onChange(choosen?.cnpj || '')}
                      onOpen={() => { setOpen(true) }}
                      onClose={() => { setOpen(false) }}
                      loading={loading}
                      defaultValue={plan.partner}
                      filterOptions={(x) => x} 
                      renderInput={(props) => {
                        return (
                          <TextField
                            {...props}
                            label="Somente para associados da empresa"
                            variant="outlined"
                            fullWidth
                          />
                        )
                      }}
                    />
                  )
                }
                return (
                  <NumberFormat
                    customInput={TextField}
                    format='##.###.###/####-##'
                    value={value || ''}
                    variant="outlined"
                    fullWidth
                    label="Somente para associados da empresa"
                    error={errors.companyCnpj}
                    helperText={errors.companyCnpj?.message || ''}
                    onValueChange={(v) => { onChange(v.value) }}
                  />
                )
              }}
              name="companyCnpj"
              control={control}
            />
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Imagem base da carteirinha</Form.Label>
                <Form.Control type="file" onChange={onCertChange} accept="image/*" />
              </Form.Group>
              {certBasePreview &&
                <div className={`
                  ${css['cp-modal-plan__preview']} 
                  ${css['cp-modal-plan__cert__preview']}
                `}>
                  <img src={certBasePreview} alt="" />
                </div>
              }
            </Col>
          </Row>
          {plan.id && !plan.recorrent && (
            <Row>
              <Col md={6} >
                <Button onClick={() => handleOpenImport(plan.id)}>Importar associados</Button>
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">{plan.id ? 'Salvar' : 'Cadastrar'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
