import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { saveRequirement, updateRequirement } from '../../../../http/requirement'
import { TextField, FormControlLabel, Switch, MenuItem, FormControl, InputLabel, Select, FormHelperText, InputAdornment } from '@material-ui/core'
import css from './styles.module.scss'
import { makeStyles } from '@material-ui/core/styles'
import { getUser } from '../../../../services/auth'
import { metaDataTypes } from '../../../../util/utils' 
import { FaEdit, FaEye, FaPlus, FaTrash } from "react-icons/fa"

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
    '& .MuiFormControl-root': {
      margin: '8px 0',
    },
  },
}));

export default function ModalRequirement({ show, handleClose, requirement, companyId, setLoading, handleOpenImport }) {
  const {
    watch, handleSubmit, setValue, reset, getValues, register, setError, control, formState: { errors },
  } = useForm()
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "options",
  });
  const classes = useStyles()
  const user = getUser()

  useEffect(() => {
    const fields = ['title', 'required', 'type']
    if (requirement.options) {
      setValue('options', [
        ...requirement.options
      ])
    }
    if (requirement.id) {
      fields.forEach((field) => setValue(field, requirement[field]))
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [requirement, setValue])

  const onSubmit = async (data) => {
    setLoading(true)
    let result = null
    data.id = requirement.id
    data.companyId = companyId
    
    if (requirement.id) result = updateRequirement(data)
    else result = saveRequirement(data)

    result.then(e => {
      NotificationManager.success('O requiremento foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      let title = 'Ocorreu um erro', description = 'Erro no servidor'
      if (e.response?.status === 422 && e.response?.data?.error?.error)
        description = e.response?.data?.error?.error
      NotificationManager.error(description, title)
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    handleClose()
  }


  const handleAppend = (value) => {
    append(value);
  };

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>{requirement.id ? 'Editar ' : 'Cadastrar '}informação adicional</Modal.Title>
      </Modal.Header>
      <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Controller
            name="type"
            control={control}
            defaultValue=""
            rules={{ 
              required: { value: true, message: 'O tipo de informação é obrigatória' },
            }}
            render={({ field: { onChange, value } }) => (
              <FormControl 
                className={classes.formControl} 
                error={errors.type}
                fullWidth
                variant="outlined"
              >
                <InputLabel htmlFor="type">Tipo de informação</InputLabel>
                <Select
                  label="Tipo de informação"
                  value={value || ''}
                  fullWidth
                  onChange={onChange}
                  inputProps={{
                    id: 'type',
                  }}
                >
                  { metaDataTypes.map(({ value, name }) => (
                    <MenuItem key={value} value={value}>{name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.type?.message || ''}</FormHelperText>
              </FormControl>
            )}
          />
          <Row>
            <Col>
              <Controller
                name="title"
                control={control}
                defaultValue=""
                rules={{ 
                  required: { value: true, message: 'O titulo é obrigatório' },
                  maxLength: { value: 255, message: 'O titulo não pode ser maior que 255 caracteres' },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Título"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    error={errors.title}
                    helperText={errors.title?.message || ''}
                  />
                )}
              />
            </Col>
            <Col>
              <Controller
                name="required"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={
                      <Switch checked={value || false} onChange={onChange} />}
                    label="Informação obrigatória?"
                  />
                )}
              />
            </Col>
          </Row>
          {['radio', 'checkbox'].indexOf(watch('type')) !== -1 ? 
            <div> 
              <Row>
                <br />
                <Col xs={9} >
                  Opções
                </Col> 
                <Col xs={3} className={css['cp-modal-attributes__options__add']}>
                  <Button onClick={ () => handleAppend({ value: "" }) }>
                    <FaPlus /> Nova opção
                  </Button>
                </Col>
              </Row> 
              {fields.map((item, index) => (
                <Controller
                  key={index}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Opção"
                      variant="outlined"
                      fullWidth
                      error={!!errors.options?.[index]?.value?.message}
                      helperText={errors.options?.[index]?.value?.message || ''}
                      InputProps={{
                        endAdornment: (
                          !watch(`options[${index}]`).id ? 
                          <InputAdornment position="end">
                            <div
                              aria-label="toggle password visibility"
                              onClick={() => remove(index)}
                              className={css['cp-modal-attributes__options__remove']}
                            >
                              <FaTrash/>
                            </div>
                          </InputAdornment> : null
                        )
                      }}
                    />
                  )}
                  rules={{ 
                    required: { value: true, message: 'A opção é obrigatório' },
                    maxLength: { value: 255, message: 'A opção deve ter no máximo 255 caracteres' },
                  }}
                  defaultValue={item.value}
                  name={`options[${index}].value`}
                  control={control}
                />
                )
              )}              
            </div>
          : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">{requirement.id ? 'Salvar' : 'Cadastrar'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
