import { axios, formData } from './api'

const getCompany = async ({id, permalink}) => {
  let company =  null
  if (id)
    company = await axios.get(`public/company/${id}`)
  else
    company =  await axios.get(`public/company-name/${permalink}`)
  return company.data
}

const getDashCompany = async ( id ) => {
  const companies =  await axios.get(`api/company/${id}`)
  return companies
}

const getCompanies = async ( params ) => {
  const companies =  await axios.get(`api/companies`, { params })
  return companies
}

const listCompaniesPublic = async () => {
  const company =  await axios.get(`public/companies`)
  return company.data
}

const saveCompany = async ( data ) => {
  const company =  await formData.post(`api/companies`, data)
  return company.data
}

const updateCompany = async ( id, data ) => {
  const company =  await formData.put(`api/company/${id}`, data)
  return company.data
}


const deleteCompany = async ( id, data ) => {
  const company =  await formData.delete(`api/company/${id}`, data)
  return company.data
}

const uploadLogoCompany = async ( id, file ) => {
  var formData = new FormData()
  formData.append("logo", file)
  const company =  await axios.post(`api/company/${id}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return company.data
}

export { 
  getCompanies,
  getDashCompany,
  saveCompany, 
  updateCompany, 
  getCompany, 
  uploadLogoCompany, 
  listCompaniesPublic,
  deleteCompany
}