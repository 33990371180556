/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getAditionals, deleteAditional, generateViewReportData } from '../../../../http/subscriptionAditionalContent'
import ModalAditional from '../Modal'
import ModalPreview from '../../../../components/common/ModalPreview'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaEye, FaPlus, FaTrash } from "react-icons/fa"
import { SlGraph } from "react-icons/sl"
import { useParams } from "react-router-dom"
import { NotificationManager } from 'react-notifications'
import { getUser } from '../../../../services/auth'
import ViewStatsModal from '../../../../components/common/Modal/ViewStats'

const SubscriptionAditionalList = () => {

  const size = 10
  const [currentAditional, setCurrentAditional] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false)
  const [modalViewStatsOpen, setModalViewStatsOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const companyId = params.companyId || getUser()?.companyId

  const fetchAditionals = async () => {
    try{
      const aditionals = await getAditionals({
        ...( searchable ? { name: searchable } : {}),
        ...(companyId ? { companyId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(aditionals)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor. Recarregeue a página', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    fetchAditionals()
    document.title = "Material Complementar - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  useEffect(() => {
    fetchAditionals()
  }, [searchable, page])

  const editAditional = (data) => {
    setCurrentAditional(data)
    setModalOpen(true)
  }

  const openViewStats = (data) => {
    setCurrentAditional(data)
    setModalViewStatsOpen(true)
  }

  const openPreview = (data) => {
    setCurrentAditional(data)
    setModalPreviewOpen(true)
  }

  const closeModal = () => {
    setSearchable('')
    fetchAditionals()
    setCurrentAditional({})
    setModalOpen(false)
  }

  const closeViewStatsModal = () => {
    setCurrentAditional({})
    setModalViewStatsOpen(false)
  }

  const getViewStats = async (data, generateFile) => {
    setLoading(true)
    try {
      const result = await generateViewReportData({...data, id: currentAditional.id})
      const file = generateFile(result)
      const element = document.createElement("a")
      element.href = URL.createObjectURL(file)
      element.download = `viewReportAditionalContent${currentAditional.id}.csv`
      document.body.appendChild(element)
      element.click()
    } catch (e) {
      let message = 'Erro no servidor', title = 'Ocorreu um erro'
      if (e?.response?.status === 404) {
        message = 'Sem dados para o intervalo de datas selecionadas'
      }
      NotificationManager.error(message, title)
    } finally {
      setLoading(false)
    }
  }

  const closePreviewModal = () => {
    setSearchable('')
    fetchAditionals()
    setCurrentAditional({})
    setModalPreviewOpen(false)
  }

  const addAditional = () => {
    setCurrentAditional({})
    setModalOpen(true)
  }

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja deletar esse material complementar? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deleteAditional(item.id)
        setSearchable('')
        fetchAditionals()
        setLoading(false)
        NotificationManager.success('Material complementar excluída com sucesso', 'Deletado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }

  const types = {
    image: 'Imagem',
    youtube: 'Youtube',
    document: 'Documento',
    vimeo: 'Vimeo',
    text: 'Texto',
  }

  const columns = useMemo(
    () => [
      {
        Header: "Aulas",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' }, 
          { 
            Header: 'Status', 
            accessor: 'status'
          }, 
          { 
            Header: 'Tipo', 
            accessor: 'type',
            Cell: ({value}) => types[value]
          }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Button variant="link" onClick={() => editAditional(row.original)}>
                  <FaEdit />
                </Button>
                <Button variant="link" onClick={() => openPreview(row.original)}>
                  <FaEye />
                </Button>
                <Button variant="link" onClick={() => openViewStats(row.original)}>
                  <SlGraph />
                </Button>
                <Button variant="link" onClick={() => deleteItem(row.original)}>
                  <FaTrash />
                </Button>
              </div>
            )
          }
        ]
      }
    ], [deleteItem, editAditional, openPreview, openViewStats]
  );
  
  const breadcrumbs = !params.companyId ? [
    {name: 'Início', url: '/dashboard'},
    {name: 'Associados', url: '/dashboard/associacao/associados'}
  ]: [
    {name: 'Início', url: '/dashboard'},
    {name: "Associados", url: `/dashboard/empresa/${companyId}/associacao/associados`}
  ]

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-aditional">
        <Row className={css['pg-aditional__filter']}>
          <Col className={css['pg-aditional__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          {companyId && 
            <Col xs={3} className={css['pg-aditional__filter__actions']}>
              <Button onClick={ addAditional }><FaPlus /> Nova</Button>
            </Col>
          }
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchAditionals} 
          size={size}
          className={css["pg-aditional__table"]}
          setPage={setPage}
        />
      </div>
      <ModalAditional 
        show={modalOpen}
        handleClose={closeModal}
        aditional={currentAditional}
        companyId={companyId} 
        setLoading={setLoading}
      />
      <ModalPreview 
        show={modalPreviewOpen}
        content={currentAditional}
        handleClose={closePreviewModal}
      />
      { modalViewStatsOpen ? 
        <ViewStatsModal 
          show={modalViewStatsOpen}
          handleClose={closeViewStatsModal}
          getData={getViewStats}
        />
        : null }
      
    </DashboradLayout>
  )
}

export default SubscriptionAditionalList