import { axios, formData } from './api'

const getCourses = async ( params ) => {
  try {
    const courses =  await axios.get(`api/courses`, { params })
    return courses.data
  } catch (error) {
    return error;
  }
}

const getCourse = async ( id ) => {
  const courses =  await axios.get(`api/course/${id}`)
  return courses.data
}

const getCoursePublic = async ( id ) => {
  const courses =  await axios.get(`public/course/${id}`)
  return courses.data
}

const getCourseEnrolled = async ( id ) => {
  const courses =  await axios.get(`api/course/${id}/enrolled`)
  return courses.data
}

const listCoursePublic = async ( id ) => {
  const courses =  await axios.get(`public/courses`)
  return courses.data
}

const saveCourse = async ( data ) => {
  const course =  await formData.post(`api/courses`, data)
  return course.data
}

const updateCourse = async ( id, data ) => {
  const course =  await formData.put(`api/course/${id}`, data)
  return course.data
}

const deleteCourse = async ( id, data ) => {
  return await formData.delete(`api/course/${id}`, data)
}

const associateTeacher = async (id, data) => {
  const course =  await axios.post(`api/course/${id}/associate`, { data })
  return course.data
}

const getMyCourses = async () => {
  const courses =  await axios.get(`api/course/my-courses`)
  return courses.data
}

const getMyCerts = async () => {
  const courses =  await axios.get(`api/course/my-certs`)
  return courses.data
}


export { 
  getCourses, 
  saveCourse, 
  updateCourse, 
  getCourse, 
  associateTeacher,
  getMyCourses,
  getMyCerts,
  getCoursePublic,
  listCoursePublic,
  getCourseEnrolled,
  deleteCourse
}