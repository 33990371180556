/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getRequirements, deleteRequirement } from '../../../../http/requirement'
import ModalRequirement from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"
import { useParams, Link } from "react-router-dom"
import { NotificationManager } from 'react-notifications'
import { getUser, AllowedRoles } from '../../../../services/auth'

const PlanList = () => {

  const size = 10
  const [currentRequirement, setCurrentRequirement] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const companyId = params.companyId || getUser()?.companyId

  const fetchRequirements = async () => {
    try{
      const settings = await getRequirements({
        ...( searchable ? { searchable } : {}),
        ...(companyId ? { companyId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(settings)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }


  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja desativar essa informação adicional? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deleteRequirement(item.id)
        setSearchable('')
        fetchRequirements()
        setLoading(false)
        NotificationManager.success('Informação adicionar desativada com sucesso', 'Desativado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }
  
  useEffect(() => {
    fetchRequirements()
  }, [searchable, page])

  const editRequirements = (data) => {
    setCurrentRequirement(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    fetchRequirements()
    setModalOpen(false)
    setSearchable('')
    setCurrentRequirement({})
  }

  const addRequirement = () => {
    setSearchable('')
    setCurrentRequirement({})
    setModalOpen(true)
  }

  useEffect(() => {
    document.title = "Configurações - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Informações adicionais de usuários",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Título', accessor: 'title', sortType: 'basic' }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                {getUser()?.role !== AllowedRoles.TEACHER ? 
                  <>
                    <Button variant="link" onClick={() => editRequirements(row.original)}>
                      <FaEdit />
                    </Button>
                    <Button variant="link" onClick={() => deleteItem(row.original)}>
                      <FaTrash />
                    </Button>
                  </>
                : null }
              </div>
            )
          }
        ]
      }
    ], []
  );

  const breadcrumbs = !companyId ? [
    {name: 'Início', url: '/dashboard'},
    {name: 'Configurações', url: '/dashboard/configuracoes'}
  ]: [
    {name: 'Início', url: '/dashboard'},
    {name: "Configurações", url: `/dashboard/empresa/${companyId}/configuracoes`}
  ]

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-setting">
        <Row className={css['pg-setting__filter']}>
          <Col md={12}>
            <h2>Informações adicionais de usuários</h2>
            <Row className={css['pg-setting__filter']}>
              <Col md={9} className={css['pg-setting__filter__searchable']}>
                <Form.Control 
                  type="text" 
                  placeholder='Busque por titulo' 
                  value={searchable} 
                  onChange={e => setSearchable(e.target.value)}
                />
              </Col>
              {companyId && 
                <Col xs={3} className={css['pg-setting__filter__actions']}>
                  <Button onClick={ addRequirement }><FaPlus /> Nova</Button>
                </Col>
              }
            </Row>
            <Table 
              columns={columns} 
              data={data} 
              fetch={fetchRequirements} 
              size={size}
              className={css["pg-setting__table"]}
              setPage={setPage}
            />
          </Col>
        </Row>
      </div>
      <ModalRequirement 
        show={modalOpen}
        handleClose={closeModal}
        requirement={currentRequirement}
        companyId={companyId}
        setLoading={setLoading}
        handleOpenImport={() => {}}
      />
    </DashboradLayout>
  )
}

export default PlanList