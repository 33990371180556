import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaRegCopy } from 'react-icons/fa'
import { socket } from '../../util/socket';
import { RotateCircleLoading } from 'react-loadingg'

export default function Step4({ product, response }) {
  const [success, setSuccess] = useState(false)
  const { method, status, paymentCode, id } = response
  let statusTitle = 'Parabéns, seu pagamento está sendo processado.'
  let statusMessage = 'Em breve receberá um email com a confirmação do pagamento e a liberação ao conteúdo.'

  if ((["paid", "disponible"]).indexOf(status) > -1) {
    statusTitle = 'Parabéns, seu pagamento foi aprovado.'
    statusMessage = 'Agora você pode ter acesso ao conteudo, clicando abaixo ou fechando esta mensagem'
  }

  const handleClick = () => {
    window.open(response.link, '_blank').focus()
  }

  const copyCode = () => {
    navigator.clipboard.writeText(paymentCode)
  }

  useEffect(() => {
    if (method === 'pix') {
      socket.connect()
      socket.emit('message', {event: 'connected', data: {room: id}});
      socket.on('status_change', (data) => {
        if (data.status === 'paid') {
          setSuccess(true)
        }
      })
      return () => {
        socket.disconnect();
      };
    }
  }, [])


  return (
      <div className={css['cp-pay']}>
        <Row>
          <Col md={12}>
            {method === 'boleto' && (
              <div>
                <h1>Seu boleto foi gerado com sucesso!</h1>
                <p>Você pode ver o seu boleto no botão abaixo</p>
                <Button onClick={handleClick} variant="success">Ver boleto</Button>
              </div>
            )}
            {method === 'creditCard' && (
              <div>
                <h1>{statusTitle}</h1>
                <p>{statusMessage}</p>
              </div>
            )}
            {method === 'pix' && !success && (
              <div className={css['cp-pay__pix']}>
                <h1>Pedido gerado!</h1>
                <p>Agora você só precisa finalizar o pagamento</p>
                
                <div className={css['cp-pay__pix__code']}>
                  <img src={response.paymentImage} alt="QR Code PIX" />
                  <RotateCircleLoading style={{position: 'relative', margin: '0 auto 10px'}}/>
                  <Button onClick={copyCode} variant="outline-success">
                    <FaRegCopy /> Copiar codigo
                  </Button>
                </div>
                <div className={css['cp-pay__pix__instructions']}>
                  <p>- Abra o aplicativo do seu banco no celular</p>
                  <p>- Selecione a opção de pagar com Pix/escanear QR code</p>
                  <p>- Após o pagametno, você receberá um email de confirmação e o seu acesso estará liberado</p>
                </div>
              </div>
            )}
            {method === 'pix' && success && (
              <div>
                <h1>Parabéns, seu pagamento foi aprovado.</h1>
                <p>Agora você pode ter acesso ao conteudo, clicando abaixo ou fechando esta mensagem</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
  )
}