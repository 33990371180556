import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { updateAccount } from '../../../../http/auth'
import UserDataForm from '../../../../components/common/UserDataForm'
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function ChanegUserData({ show, handleClose, setLoading, userId, userData }) {
  const {
    register, handleSubmit, watch, reset, control, setValue, setError, formState: { errors },
  } = useForm()
  
  useEffect(() => {
    const fields = [
      'address',
      'addressNeighborhood',
      'addressComplement',
      'addressNumber',
      'cellphone',
      'cep',
      'city',
      'cpf',
      'email',
      'name',
      'state',
      'birthDate',
      'isForeign',
      'country',
      'foreignDocument'
    ]

    if (userData && userData.user) {
      fields.forEach((field) => setValue(field, userData.user[field]))
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [userData, setValue])

  const onSubmit = (data) => {
    setLoading(true)
    updateAccount({
      ...data,
      id: userId,
      cpf: data.cpf?.replace(/(\.|-)/g, ''),
      cep: data.cep.replace(/-/g, ''),
      cellphone: data.cellphone.replace(/(\(|\)|-|\s)/g, ''),
    })
      .then(async (res) => {
        close()
        NotificationManager.success('Usuário atualizado com sucesso')
      }).catch(e => {
        if (e.response?.status === 422){
          if (e.response?.data?.error?.errCode === 'cpf_exists')
            setError('cpf', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
          else if (e.response?.data?.error?.errCode === 'email_exists')
            setError('email', {type: e.response?.data?.error?.errCode,  message: e.response?.data?.error?.message}, { shouldFocus: true })
        }
        NotificationManager.error('Ocorreu um erro, tente novamente!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const close = () => {
    reset()
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Alterar dados</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form key={"updateData"} onSubmit={handleSubmit(onSubmit)}>
          <UserDataForm
            register={register}
            errors={errors}
            control={control}
            watch={watch}
            showPassword={false}
            twoColumns={true}
            showIcons={false}
          />
          <Button type="submit">Salvar</Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
