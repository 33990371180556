import React, {useEffect, useState} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../../layouts/default'
import { getMyCerts } from '../../../http/course'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
const server = process.env.REACT_APP_API_URL || null

const MyCerts = () => {
	const [enrolls, setEnrolls] = useState(null)
	const [loading, setLoading] = useState(true)


	const fetchCourse = async () => {
    getMyCerts().then(e => {
      setEnrolls(e.rows)
      setLoading(false)
    })
	}

	useEffect(() => {
		document.title = `Meus certificados - Alissem`
    document.getElementsByTagName("META")[1].content=document.title
		fetchCourse()
	}, [])

	return (
		<LayoutDefault loading={loading}>
			{enrolls?.length > 0 ? (
				<div className={css['pg-my-courses']}>
					<Container>
						<Row>
							<Col md={4} className={css['cp-my-courses']}>
								<div className={css['cp-my-courses__content']}>
									<div className={css['cp-my-courses__content__title']}>
										<h2>Meus certificados</h2>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<div className={css['cp-my-courses']}>
						<Container >
              <Row>
                {enrolls.map((enroll, index) => {
                  return (
                    <Col md={3} key={enroll.id} className={css['cp-my-course']}>
                      <div className={css['cp-my-course__content']}>
                        <div className={css['cp-my-course__content__logo']}>
                          <img src={`${server}${enroll.course.logoUrl}`} alt={enroll.course.name} />
                        </div>
                        <h3>{enroll.course.name}</h3>
                        <h4>{enroll.course.company.name}</h4>
                      </div>
                      <a 
												className={`${css['cp-my-course__go']}`}
												target="_blank"
												href={`${server}/api/enroll/${enroll.code}/getCertificate`}
												rel="noreferrer"
											>
                        Baixar
                      </a>
                    </Col>
                  )
                })}
              </Row>
						</Container>
					</div>
				</div>				
			) : (
        <div className={css['pg-my-courses--nothing']}>
          <p>Você ainda não tem nenhum certificado liberado.</p>
        </div>
      )}
		</LayoutDefault>
	)
}

export default MyCerts