import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { savePartnership, updatePartnership } from '../../../../http/partnership'
import css from './styles.module.scss'
import { TextField } from '@material-ui/core'
import { makeStyles, alpha } from '@material-ui/core/styles'

const server = process.env.REACT_APP_API_URL || null
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
  },
}));


export default function ModalPartnership({ show, handleClose, partnership, course, setLoading }) {
  const {
    register, handleSubmit, control, setValue, reset, formState: { errors },
  } = useForm()
  const classes = useStyles()
  const [logo, setLogo] = useState(null)
  const [logoPreview, setLogoPreview] = useState(null)

  useEffect(() => {
    const fields = ['name', 'link']
    if (partnership.id) {
      fields.forEach((field) => setValue(field, partnership[field]))
      if (partnership.logoUrl)
        setLogoPreview(`${server}${partnership.logoUrl}`)
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [partnership, setLogoPreview, setValue])


  const onSubmit = async (data) => {
    setLoading(true)
    let result = null
    data.courseId = course.id
    
    var formData = new FormData()
    formData.append("name", data.name)
    formData.append("link", data.link)
    formData.append("courseId", course.id)
    formData.append("logoFile", logo)

    if (partnership.id) result = updatePartnership(partnership.id, formData)
    else result = savePartnership(formData)

    result.then(e => {
      NotificationManager.success('O parceiro foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    setLogoPreview(null)
    setLogo(null)
    handleClose()
  }

  const onFileChange = (event) => {
    setLogoPreview(URL.createObjectURL(event.target.files[0]))
    setLogo(event.target.files[0])
  }
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{partnership.id ? 'Editar ' : 'Cadastrar '} parceiro</Modal.Title>
      </Modal.Header>
      <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ 
                  required: { value: true, message: 'O nome do parceiro é obrigatório' },
                  minLength: { value: 1, message: 'O nome deve ter pelo menos 3 caracteres' },
                  maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Nome do parceiro"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    error={errors.name}
                    helperText={errors.name?.message || ''}
                  />
                )}
              />
            </Col>
            <Col xs={12} md={6}>
              <Controller
                name="link"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Site"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                  />
                )}
              />
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Anexar arquivo</Form.Label>
            <Form.Control type="file" 
              {...register('file', {
                required: { 
                  value: !partnership.id,
                  message: 'A imagem do parceiro é obrigatório'
                }
              })}
              accept="image/*"
              onChange={onFileChange} 
              isInvalid={errors.file}
            />
            {logoPreview &&
              <div className={css['cp-modal-partnership__preview__container']}>
                <div className={css['cp-modal-partnership__preview']}>
                  <img src={logoPreview} alt="" />
                </div>
              </div>
            }
            <Form.Control.Feedback type="invalid">{errors.file?.message}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button type="submit" variant="success">{partnership.id ? 'Salvar' : 'Cadastrar'}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
