import { axios, formData } from './api'

const getLessons = async ( params ) => {
  try {
    const lessons =  await axios.get(`api/lessons`, { params })
    return lessons.data
  } catch (error) {
    return error;
  }
}

const getLesson = async ( id ) => {
  const lessons =  await axios.get(`api/lesson/${id}`)
  return lessons
}

const saveLesson = async ( data ) => {
  const company =  await formData.post(`api/lesson`, data)
  return company.data
}

const updateLesson = async ( id, data ) => {
  const company =  await formData.put(`api/lesson/${id}`, data)
  return company.data
}

const deleteLesson = async ( id ) => {
  const partnership =  await axios.delete(`api/lesson/${id}`)
  return partnership.data
}

const generateViewReportData = async (data) => {
  const viewReport =  await axios.get(`api/lesson/${data.id}/viewReport`, { params: data } )
  return viewReport.data
}

export { getLessons, saveLesson, updateLesson, getLesson, deleteLesson, generateViewReportData }