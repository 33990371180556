import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import css from './styles.module.scss'
import { isAuthenticated } from '../../services/auth'
import Price from './Price'

const SubscriptionPriceList = ({companyId, prices, setPrice, handleClick}) => {
  const logged = isAuthenticated()
  const handleSelectPrice = (plan) => {
    if (!logged){
      window.location.href = `/login?redirect=/empresa/${companyId}`
      return 
    }
    if (plan.applicable){
      setPrice(plan)
      handleClick(plan)
      return
    }
  }
  return (
    <div className={css["cp-price-list"]}> 
      <Row>
        { prices.map((price, index) => {
          return (
            <Col md={3} key={index}>
              <Price price={price} handleClick={handleSelectPrice}/>
            </Col>
          )
        }) }
      </Row>
    </div>
  )
}

export default SubscriptionPriceList