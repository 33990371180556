import React, { useEffect, useState } from 'react'
import css from './styles.module.scss'
import Header from '../components/common/Portal/Header'
import Footer from '../components/common/Portal/Footer'
import SideMenu from '../components/common/Sidemenu'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import 'react-notifications/lib/notifications.css'
import {NotificationContainer} from 'react-notifications'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Loader from '../components/common/Loader'
import { getDashCompany } from '../http/company'
import { getCourse } from '../http/course'
import { isAuthenticated, getUser } from '../services/auth'
import { useParams, useHistory } from "react-router-dom"
import CompleteDataModal from '../components/Modal/CompleteData'
import { useLocation } from 'react-router-dom'

const LayoutDashboard = ({children, loading = true, breadcrumbs }) => {
  let history = useHistory()
  const location = useLocation()
  const { companyId, courseId } = useParams()
  const [company, setCompany] = useState()
  const [loadingInternal, setLoadingInternal] = useState(loading)
  const [course, setCourse] = useState()
  const [openCompleteModal, setOpenCompleteModal] = useState(false)
  const user = getUser()

  useEffect(() => {
    const requiredFields = [
      'name',
      'email',
      'address',
      'addressNumber',
      'addressNeighborhood',
      'cep',
      'city',
      'cellphone',
      'birthDate',
      'isForeign'
    ]

    if (isAuthenticated && user && location.pathname !== '/perfil')  {
      if (user.isForeign) {
        requiredFields.push('country')
        requiredFields.push('foreignDocument')
      } else {
        requiredFields.push('state')
        requiredFields.push('cpf')
      }
      // setOpenCompleteModal(!requiredFields.reduce(
      //   (result, requiredField) => 
      //     (user[requiredField] && user[requiredField] !== '') && result, true))
    }
  }, [user])

  const fetchCompany = async () => {
    const companyFound = await getDashCompany(companyId || user?.companyId)
    if (!companyFound.data.hasAssociation && /dashboard\/empresa\/[0-9]+\/associacao\/[a-z]+/.test(history.location.pathname)){
      history.replace("/dashboard")
    }
    setCompany(companyFound.data)
  }

  const fetchCourse = async () => {
    const courseFound = await getCourse(courseId)
    setCourse(courseFound)
  }

  useEffect(() => {
    if (companyId || user?.companyId)
      fetchCompany()
    if (courseId) 
      fetchCourse()
  }, [])

  useEffect(() => {
    setLoadingInternal(loading)
  }, [loading, setLoadingInternal])

  return (
    <div className={`${css['cp-layout']}`}>
      {loadingInternal ? <div className={css['cp-layout-loading']}>
        <Loader />
      </div> : null}
      <Header fluid={true} />
      <div className={`${css['cp-dash-layout']} ${css['cp-dash-layout__content']} `}>
        <Container fluid>
          <Row>
            <Col md={2} className={css["cp-dash-layout__content__sidebar"]}>
              <SideMenu company={company} course={course}/>
            </Col>
            <Col md={10} className={css["cp-dash-layout__content__box"]}>
              {breadcrumbs &&
                <Breadcrumb>
                  {breadcrumbs.map((breadcrumb, index) => (
                    <Breadcrumb.Item key={"breadcrumb_"+index}
                      active={index === breadcrumbs.length - 1} 
                      href={breadcrumb.url}
                    >
                      {breadcrumb.name}
                    </Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              }
              { children }
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
      <NotificationContainer/>
      <CompleteDataModal
        setLoading={setLoadingInternal}
        setOpen={true}
        basicInformation={openCompleteModal}
        customInformations={false}
        user={user}
      />
    </div>
  )
}

export default LayoutDashboard