import React, { useRef, useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import styled from 'styled-components'
import css from './styles.module.scss'

const ColorSquare = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: ${props => props.color || '#FFFFFF'};
  border: 1px solid #CECECE;
  margin-bottom: 20px;
`

export default function ColorPicker ({ value, onChange, defaultValue}) {
  const [open, setOpen] = useState(false)

  const color = value || defaultValue
  const wrapperRef = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [wrapperRef])

  return (
    <div ref={wrapperRef} className={css["cp-colorpicker"]}>
      <ColorSquare 
        onClick={() => setOpen(prev => !prev)}
        color={color}
      />
      {open &&
        <div className={css["cp-colorpicker__modal"]}>
          <ChromePicker 
            color={color}
            disableAlpha={true}
            onChange={({hex})=>{
              onChange(hex)
            }}
            onChangeComplete={({hex})=>{
              onChange(hex)
            }}
          />
        </div>
      }
    </div>
  )
}