import axios from 'axios';

async function requestCardHash(card, encryptionKey) {
  try {
    const {
      data: { id }
    } = await axios.post(`https://api.pagar.me/core/v5/tokens?appId=${encryptionKey}`, {
      type: 'card',
      card: {
        number: card.number,
        holder_name: card.holder_name,
        exp_month: card.exp_month,
        exp_year: card.exp_year,
        cvv: card.cvv,
      }
    });

    return id;
  } catch (error) {
    throw error;
}
}

export { requestCardHash }