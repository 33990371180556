import React, { useState, useEffect } from "react"
import { useTable } from "react-table"
import TableView from 'react-bootstrap/Table'
import Pagination from 'react-bootstrap/Pagination'
import css from './styles.module.scss'

export default function Table({ 
  columns, 
  size, 
  fetch,
  data,
  className,
  setPage
}) {
    
  const [currentPage, setCurrentPage] = useState(0)
  const [pageItems, setPageItems] = useState([])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data: [...(data.rows || [])] });

  useEffect(()=>{
    setPageItems(Array.from(Array(Math.ceil(data.count / size) || 0).keys()))
  }, [data, size])


  const handlePageChange = (page) => {
    setPage(page)
    setCurrentPage(page)
  }

  return (
    <div className={`${className} ${css['cp-table']}`}>
      <TableView {...getTableProps()} striped bordered hover responsive >
        <thead>
          {headerGroups.map((headerGroup, index) => {
            if (index === 0)
              return null
            return(
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          )})}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map(cell => (
                    <td 
                      {...cell.getCellProps()}
                      className={
                        cell.column.render("Header") === "Ações" ? 
                        css['cp-table__column__actions'] : null
                      } 
                    >
                      {cell.render("Cell")}
                    </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableView>
      <Pagination className={css['cp-table__pagination']}>
        <Pagination.First onClick={() => handlePageChange(0)} disabled={currentPage - 1 < 0} />
        
        {pageItems.map((data, index) => {
          let final = []
          if (index === 0){
            final.push(
              <Pagination.Item onClick={() => handlePageChange(index)} key={index} active={index === currentPage}>
                {index + 1}
              </Pagination.Item>
            )
            if (currentPage !== 0)
              final.push(<Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage - 1 < 0} />)
            return final
          }else if (index === currentPage){
            return(
              <Pagination.Item onClick={() => handlePageChange(index)} key={index} active={index === currentPage}>
                {index + 1}
              </Pagination.Item>)
          } 
          if (pageItems.length - 1 === index){
            if (currentPage !== pageItems.length - 1)
              final.push(<Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage + 1 >= pageItems.length} />)
            final.push(
              <Pagination.Item onClick={() => handlePageChange(index)} key={index} active={index === currentPage}>
                {index + 1}
              </Pagination.Item>
            )
            return final
          }
          return null
        })}
        <Pagination.Last onClick={() => handlePageChange(pageItems.length - 1)} disabled={currentPage + 1 >= pageItems.length} />
      </Pagination>
    </div>
  )
}