import React from 'react'
import css from './styles.module.scss'
import { DateTime } from 'luxon'
import styled from 'styled-components'

const Status = styled.div`
  background-color: 
    ${props => 
      props.status === 'active' ? '#0CB59F' : (
        props.status === 'expired' ? '#a80e0e' : (
          props.status === 'pending' ? '#f5c31f' : '#808080' 
        )
      )
      }
`

const statusText = (status) => {
  switch(status.toLowerCase()){
    case 'active': return {status, message: 'Ativo'}
    case 'cancelled_by_receiver': return {status, message: 'Cancelado pelo vendedor'}
    case 'cancelled_by_sender': return {status, message: 'Cancelado pelo comprador'}
    case 'cancelled': case 'canceled': return {status, message: 'Cancelado'}
    case 'failed': return {status, message: 'Falha no pagamento'}
    case 'expired': return {status, message: 'Expirado'}
    case 'pending': return {status, message: 'Aguardando processamento do pagamento'}
    case 'suspended': return {status, message: 'Suspensa'}
    case 'payment_method_change': return {status, message: 'Aguardando troca de cartão'}
    case 'document_pending': return {status, message: 'Documento enviado em análise'}
    case 'document_denied': return {status, message: 'Documento enviado negado'}
    case 'document_not_sended': return {status, message: 'Documento não enviado'}
    default: return {}
  }
  
}

const SubscriptionStatus = ({subscription, handleClick}) => {
  const status = statusText(subscription.status)
  if (status.message === 'Ativo' && subscription.canceled_recurrence) {
    status.message += " (Recorrência cancelada)"
  }

  return (
    <Status 
      status={status.status}
      className={css['cp-subscription-status']}
      onClick={handleClick}
    >
      <p>Status <span>{status.message}</span></p>
    </Status>
  )
}

export default SubscriptionStatus