import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { NotificationManager } from 'react-notifications'
import { deleteUser } from '../../../../http/user'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useHistory } from "react-router-dom"

export default function DeleteModal({ show, handleClose, setLoading, userId }) {
  const history = useHistory()

  const handleDeleteUser = async () => {
    setLoading(true)
    try{
      await deleteUser(userId)
      history.replace("/dashboard/usuarios")
      NotificationManager.success('O usuário foi deletado com sucesso')
    }catch(e){
      NotificationManager.error(
        e?.response?.data?.error?.message || 'Erro no servidor', 'Ocorreu um erro', 10000)
    }
    setLoading(false)
  }

  const close = () => {
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Quer apagar os dados desse usuário?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>Serão apagados do sistema todas as informações pessoais, histórico de matrículas em cursos e associações.</li>
          <li>Depois de excluídos, você não poderá recuperá-los</li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={handleClose}>Cancelar</Button>
        <Button variant='link'  onClick={handleDeleteUser}>Prosseguir</Button>
      </Modal.Footer>
    </Modal>
  )
}
