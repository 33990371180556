/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import css from './styles.module.scss'
import ModalDetails from '../ModalDetails'
import Certificate from '../../../assets/certificateAlt.png'

const SubscriptionMoreDetails = ({subscription, setLoading}) => {
  const [modal, setModal] = useState(false)
  return (
    <div className={css['cp-subscription-more-details']}>
      <div className={css['cp-subscription-more-details__title']}>
        <h2>Minha associação</h2>
      </div>
      {//<img src={image} alt=""/>
      }
      <div className={css['cp-subscription-more-details__text']}>
        <p>Verifique o status de sua <br /> associação, emita <br/> comprovantes e certificados.</p>
        <a
          href="javascript:;"
          onClick={() => setModal(true)}
          className={css['cp-subscription-more-details__button']}
          rel="noreferrer"
        >
          Informações de Associação
        </a>        
      </div> 
      <img className={css['cp-subscription-more-details__img']} src={Certificate} />
      <ModalDetails
        show={modal}
        subscription={subscription}
        setLoading={setLoading}
        handleClose={() => setModal(false)}
      />
    </div>
  )
}

export default SubscriptionMoreDetails