import axios from './api'

const getUsers = async ( params ) => {
  try {
    const users =  await axios.get(`api/users`, { params })
    return users.data
  } catch (error) {
    return error;
  }
}

const getUser = async ( id ) => {
  const users =  await axios.get(`api/user/${id}`)
  return users.data
}

const updateUser = async ( id, data ) => {
  const user =  await axios.put(`api/user/${id}`, { data })
  return user.data
}

const addAdmin = async ( id ) => {
  const user =  await axios.put(`api/user/${id}/addAdmin`)
  return user.data
}

const removeAdmin = async ( id ) => {
  const user =  await axios.put(`api/user/${id}/removeAdmin`)
  return user.data
}

const addCompany = async ( id, params ) => {
  const user =  await axios.put(`api/user/${id}/addCompany`, params)
  return user.data
}

const removeCompany = async ( id ) => {
  const user =  await axios.put(`api/user/${id}/removeCompany`)
  return user.data
}

const deleteUser = async ( id ) => {
  const user =  await axios.delete(`api/user/${id}`)
  return user.data
}

export { 
  getUsers, 
  updateUser,
  getUser,
  addAdmin,
  removeAdmin,
  addCompany,
  removeCompany,
  deleteUser
}