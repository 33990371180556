import React, { useEffect, useState } from 'react'
import { useParams, useLocation, Link } from "react-router-dom"
import css from './styles.module.scss'
import { Nav } from "react-bootstrap"
import {
  FaBuilding,
  FaBook,
  FaBookOpen,
  FaUserAlt,
  FaUserGraduate,
  FaMoneyBillAlt,
  FaRedo,
  FaUserTie,
  FaRegHandshake,
  FaQuestion,
} from "react-icons/fa"
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import { GoMail } from 'react-icons/go'
import { BiBookBookmark } from 'react-icons/bi'
import { getUser, AllowedRoles } from '../../../services/auth'
import { getOpenQuantity } from '../../../http/ticket'
import { getOpenQuantity as getOpenQuantitySubscription } from '../../../http/subscription'
import { FiSettings } from 'react-icons/fi'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -9,
    top: 5,
    padding: '0 4px',
  },
}));

const Sidemenu = ({ showBack = true, company, course }) => {
  const params = useParams()
  const { role } = getUser()
  const location = useLocation()
  const [openTickets, setOpenTickets] = useState()
  const [subscriptionNeedAction, setSubscriptionNeedAction] = useState()

  useEffect(() => {
    async function fetchData() {
      const opennedTickets = await getOpenQuantity({companyId: params.companyId} )
      const subscriptionNeedActionResponse = 
        await getOpenQuantitySubscription({companyId: params.companyId} )
      setOpenTickets(opennedTickets)
      setSubscriptionNeedAction(subscriptionNeedActionResponse)
    }
    fetchData()
  }, [])

  const navItem = (pathname, title, icon, key, badge) => (
    <Nav.Item key={key}>
      <Link to={{ pathname }}>
        { badge ? 
          <StyledBadge badgeContent={badge} color="error" anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
            {icon} {title}
          </StyledBadge>
          : <span> {icon} {title} </span>
        } 
      </Link>
    </Nav.Item>
  )

  const adminMenu = [
    { 
      name: "Empresas",
      url: "/dashboard/empresas",
      icon: <FaBuilding /> 
    }, { 
      name: "Cursos",
      url: "/dashboard/cursos",
      icon: <FaBook /> 
    }, { 
      name: "Usuários",
      url: "/dashboard/usuarios",
      icon: <FaUserAlt /> 
    }, { 
      name: "Emails",
      url: "/dashboard/emails",
      icon: <GoMail /> 
    },
  ]

  const userMenu = (company) => {
    const types = [
      { 
        name: "Configurações",
        url: role === AllowedRoles.ADMIN ? 
          `/dashboard/empresa/${params.companyId}/configuracoes` : 
          `/dashboard/settings`,
        show: role !== AllowedRoles.TEACHER,
        icon: <FiSettings /> 
      },{ 
        name: "Cursos",
        url: role !== AllowedRoles.ADMIN ? 
          `/dashboard/cursos` :
          `/dashboard/empresa/${params.companyId}/cursos`,
        icon: <FaBook /> 
      }, { 
        name: "Suporte",
        badge: openTickets,
        url: role === AllowedRoles.ADMIN ? 
          `/dashboard/empresa/${params.companyId}/tickets` : 
          `/dashboard/tickets`,
        show: role !== AllowedRoles.TEACHER,
        icon: <FaQuestion /> 
      }, { 
        name: "Usuários",
        url: role === AllowedRoles.ADMIN ? 
          `/dashboard/empresa/${params.companyId}/usuarios` : 
          `/dashboard/usuarios`,
        show: role !== AllowedRoles.TEACHER,
        icon: <FaUserAlt /> 
      }
    ]
    if (company?.hasAssociation) {
      types.push({ 
        name: "Associações",
        badge: subscriptionNeedAction,
        url: role === AllowedRoles.ADMIN ? 
          `/dashboard/empresa/${params.companyId}/associacao/planos` : 
          `/dashboard/associacao/planos`,
        show: role !== AllowedRoles.TEACHER,
        icon: <FaRedo /> 
      })
    }
    return types
  }

  const courseMenu = [
    { 
      name: "Aulas",
      url: `/dashboard/empresa/${params.companyId}/curso/${params.courseId}/aulas`,
      icon: <FaBookOpen /> 
    }, { 
      name: "Complementar",
      url: `/dashboard/empresa/${params.companyId}/curso/${params.courseId}/complementar`,
      icon: <BiBookBookmark /> 
    }, { 
      name: "Professores",
      url: `/dashboard/empresa/${params.companyId}/curso/${params.courseId}/professores`,
      show: role !== AllowedRoles.TEACHER,
      icon: <FaUserTie /> 
    }, { 
      name: "Vouchers",
      url: `/dashboard/empresa/${params.companyId}/curso/${params.courseId}/vouchers`,
      show: role !== AllowedRoles.TEACHER,
      icon: <FaMoneyBillAlt /> 
    }, { 
      name: "Inscritos",
      url: `/dashboard/empresa/${params.companyId}/curso/${params.courseId}/inscritos`,
      icon: <FaUserGraduate /> 
    }, { 
      name: "Parceiros",
      url: `/dashboard/empresa/${params.companyId}/curso/${params.courseId}/parceiros`,
      show: role !== AllowedRoles.TEACHER,
      icon: <FaRegHandshake /> 
    }, { 
      name: "Palestrantes",
      url: `/dashboard/empresa/${params.companyId}/curso/${params.courseId}/palestrantes`,
      show: role !== AllowedRoles.TEACHER,
      icon: <FaUserTie /> 
    },
  ]

  const buildMenu = menu => 
    menu.map(({ show = true, url, name, icon, badge }, index) => 
      show ? navItem(url, name, icon, index, badge) : null
    )
  
  const associationMenu = [
    { 
      name: "Planos",
      url: role === AllowedRoles.ADMIN ? 
      `/dashboard/empresa/${params.companyId}/associacao/planos` : 
      `/dashboard/associacao/planos`,
      icon: <FaMoneyBillAlt /> 
    },
    { 
      name: "Associados",
      url: role === AllowedRoles.ADMIN ? 
      `/dashboard/empresa/${params.companyId}/associacao/associados` : 
      `/dashboard/associacao/associados`,
      icon: <FaUserAlt /> 
    },
    { 
      name: "Complementar",
      url: role === AllowedRoles.ADMIN ? 
      `/dashboard/empresa/${params.companyId}/associacao/complementar` : 
      `/dashboard/associacao/complementar`,
      icon: <BiBookBookmark /> 
    },
  ]

  return (
    <Nav className={`col-md-12 d-none d-md-block ${css['sidebar']}`}>
      {params.courseId &&
        <div>
          <p className={css["sidebar__subsection"]}>
            {(course?.name ? course.name : "Curso")}
          </p>
          {buildMenu(courseMenu)}
        </div>
      }
      {(role === AllowedRoles.ADMIN || role === AllowedRoles.COMPANY) && 
        location.pathname.indexOf('/associacao') !== -1 && (
        <div>
          <p className={css["sidebar__subsection"]}>Associações</p>
          {buildMenu(associationMenu)}
        </div>
      )}

      {(role === AllowedRoles.ADMIN && params?.companyId) || role !== AllowedRoles.ADMIN ?
        <div>
          <p className={css["sidebar__subsection"]}>
            { role === AllowedRoles.TEACHER ? 
              "" : 
              ( company?.name ? company.name : "Empresa" )
            }
          </p>
          {buildMenu(userMenu(company))}
        </div>
        : null
      }

      {role === AllowedRoles.ADMIN &&
        <div>
          <p className={css["sidebar__subsection"]}>Geral</p>
          {buildMenu(adminMenu)}
        </div>
      }			
    </Nav>
  )
}

export default Sidemenu