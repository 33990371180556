/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getPlans, deletePlan } from '../../../../http/plan'
import ModalPlan from '../Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import css from './styles.module.scss'
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"
import { useParams, Link } from "react-router-dom"
import Moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { getUser, AllowedRoles } from '../../../../services/auth'
import ModalImportSubscription from '../ModalImport'

const PlanList = () => {

  const size = 10
  const [currentPlan, setCurrentPlan] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
  const params = useParams()
	const [loading, setLoading] = useState(true)
  const [openModalImport, setOpenModalImport] = useState(false)
  const [planToImport, setPlanToImport] = useState(null)
  const companyId = params.companyId || getUser()?.companyId

  const fetchPlans = async () => {
    try{
      const plans = await getPlans({
        ...( searchable ? { name: searchable } : {}),
        ...(companyId ? { companyId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(plans)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  const handleOpenImport = (planId) => {
    setOpenModalImport(true)
    setPlanToImport(planId)
  }

  const closeModalImport = () => {
    setOpenModalImport(false)
    setPlanToImport(null)
  }

  const deleteItem = async (item) => {
    if (window.confirm("Tem certeza que deseja desativar esse plano? Essa operação não pode ser desfeita!")){
      try{
        setLoading(true)
        await deletePlan(item.id)
        setSearchable('')
        fetchPlans()
        setLoading(false)
        NotificationManager.success('Plano desativado com sucesso', 'Desativado com sucesso')
      }catch(e){
        setLoading(false)
        NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
      }
    }
  }
  
  useEffect(() => {
    fetchPlans()
  }, [searchable, page])

  const editPlan = (data) => {
    setCurrentPlan(data)
    setModalOpen(true)
  }

  const closeModal = () => {
    fetchPlans()
    setModalOpen(false)
    setSearchable('')
    setCurrentPlan({})
  }

  const addPlan = () => {
    setSearchable('')
    setCurrentPlan({})
    setModalOpen(true)
  }

  useEffect(() => {
    document.title = "Planos - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Cursos",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' }, 
          { 
            Header: 'Data', 
            accessor: d => Moment(d.date).local().format("DD/MM/YYYY hh:mm"), 
            sortType: 'basic'
          }, 
          { Header: 'Empresa', accessor: 'company.name' }, 
          { Header: 'Recorrente', accessor: 'recorrent', 
          Cell: ({row}) => {
            return (
            <div>{row.original.recorrent ? "Sim" : "Não"}</div>
          ) }}, 
          { Header: 'Getway', 
            Cell: ({row}) => (
              <div style={ !row.original?.paymentCompanyGateway?.current ? {color: "red" } : {}}>{row.original?.paymentCompanyGateway?.paymentGateway?.name}</div>
            )
          }, 
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                {getUser()?.role !== AllowedRoles.TEACHER ? 
                  <>
                    <Button variant="link" onClick={() => editPlan(row.original)}>
                      <FaEdit />
                    </Button>
                    <Button variant="link" onClick={() => deleteItem(row.original)}>
                      <FaTrash />
                    </Button>
                  </>
                : null }
              </div>
            )
          }
        ]
      }
    ], []
  );

  const breadcrumbs = !companyId ? [
    {name: 'Início', url: '/dashboard'},
    {name: 'Planos', url: '/dashboard/associacao'}
  ]: [
    {name: 'Início', url: '/dashboard'},
    {name: "Planos", url: `/dashboard/empresa/${companyId}/associacao`}
  ]

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-plan">
        <Row className={css['pg-plan__filter']}>
          <Col className={css['pg-plan__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
          {companyId && 
            <Col xs={3} className={css['pg-plan__filter__actions']}>
              <Button onClick={ addPlan }><FaPlus /> Nova</Button>
            </Col>
          }
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchPlans} 
          size={size}
          className={css["pg-plan__table"]}
          setPage={setPage}
        />
      </div>
      <ModalPlan 
        show={modalOpen}
        handleClose={closeModal}
        plan={currentPlan}
        companyId={companyId}
        setLoading={setLoading}
        handleOpenImport={handleOpenImport}
      />
      <ModalImportSubscription
        show={openModalImport}
        handleClose={closeModalImport}
        companyId={companyId}
        setLoading={setLoading}
        planToImport={planToImport}
      />
    </DashboradLayout>
  )
}

export default PlanList