import axios from './api'

const getTeachers = async ( params ) => {
  try {
    const teachers =  await axios.get(`api/teachers`, { params })
    return teachers.data
  } catch (error) {
    return error;
  }
}

const getTeacher = async ( id ) => {
  const teachers =  await axios.get(`api/teacher`, { params: { id } })
  return teachers.data.rows[0]
}

const saveTeacher = async ( data ) => {
  const teacher =  await axios.post(`api/teacher`, { data })
  return teacher.data
}

const updateTeacher = async ( id, data ) => {
  const teacher =  await axios.put(`api/teacher/${id}`, { data })
  return teacher.data
}

const deleteTeacher = async ( id, data ) => {
  const teacher =  await axios.delete(`api/course/disassociate/${id}`)
  return teacher.data
}


export { getTeachers, saveTeacher, updateTeacher, getTeacher, deleteTeacher }