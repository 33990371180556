import axios from './api'

const enrollStatus = {
  "pending": "Pendente",
  "waiting_payment": "Aguardando Pagamento",
  "analysing": "Em análise",
  "paid": "Pago",
  "disponible": "Disponível",
  "in_dispute": "Em disputa",
  "refunded": "Devolvido",
  "canceled": "Cancelado",
  "document_pending_approval": "Aprovação de documento pendente",
  "document_denied": "Documento negado",
  "failed": "Pagamento com falha"
}

const getEnrolls = async ( params ) => {
  try {
    const enrolls =  await axios.get(`api/enrolls`, { params })
    return enrolls.data
  } catch (error) {
    return error;
  }
}

const getEnrolledReport = async ( data ) => {
  try {
    const subscriptions =  await axios.post(`api/enrolls/report`, 
      { data, responseType: "blob" }
    )
    return subscriptions.data
  } catch (error) {
    return error;
  }
}

const getEnroll = async ( id ) => {
  const enrolls =  await axios.get(`api/enroll`, { params: { id } })
  return enrolls.data.rows[0]
}

const pdfEnroll = async ( id ) => {
  const enroll = await axios.get(`api/enroll/${id}/canDownloadPdfs`)
  return enroll.data 
}

const saveEnroll = async ( data ) => {
  const enroll =  await axios.post(`api/enroll`, { data })
  return enroll.data
}

const updateEnroll = async ( id, data ) => {
  const enroll =  await axios.put(`api/enroll/${id}`, { data })
  return enroll.data
}

const changeStatusEnroll = async ( id, data ) => {
  const enroll =  await axios.put(`api/enroll/${id}/changeStatus`, { data })
  return enroll.data
}

const deleteEnroll = async ( id ) => {
  const enroll =  await axios.delete(`api/enroll/${id}`)
  return enroll.data
}

const validateDocs = async ( params ) => {
  const enroll = await axios.get(`public/validateDocs`, { params })
  return enroll.data
}

const importEnrolls = async ( data ) => {
  const subscription =  await axios.post(`api/enrolls/import`, { data })
  return subscription.data
}

const resendWelcomeEmail = async (data) => {
  const subscription =  await axios.post(`api/enrolls/resend`, { data })
  return subscription.data
}

const cancelEnrollTransaction = async ( id ) => {
  const enroll =  await axios.delete(`api/enroll/cancelTransaction/${id}`)
  return enroll.data
} 

export { 
  getEnrolls,
  saveEnroll,
  updateEnroll,
  getEnroll,
  deleteEnroll,
  pdfEnroll,
  changeStatusEnroll,
  enrollStatus,
  importEnrolls,
  resendWelcomeEmail,
  validateDocs,
  getEnrolledReport,
  cancelEnrollTransaction
}