import React from 'react'
import { isAuthenticated, getUser, redirect } from '../../services/auth'

const DashboardHome = () => {
  const logged = isAuthenticated()
  if (!logged){
    window.location.href = `/`
    return 
  }
	
	const user = getUser()
  redirect(null, user.role)
}

export default DashboardHome