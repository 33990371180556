import queryString from 'query-string'

const TOKEN_KEY = '@chat-Token'
const USER_INFO = '@chat-User'

const AllowedRoles = {
  ADMIN: 'admin',
  TEACHER: 'teacher',
  COMPANY: 'company'
}

const redirectTo = {
  admin: '/dashboard/empresas',
  company: 'dashboard/cursos',
  teacher: 'dashboard/cursos'
}

const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null

const isAuthorized = roles => {
  if (!roles) return true
  let user = getUser()
  if (!user) return false
  return roles.some(role => user.role === AllowedRoles.ADMIN || role === user.role)
}

const hasDashboard = () => {
  let user = getUser()
  if (!user) return false
  return [AllowedRoles.ADMIN, AllowedRoles.TEACHER, AllowedRoles.COMPANY].some(role => user.role === role)
}

const getToken = () => localStorage.getItem(TOKEN_KEY)

const getUser = () => 
  localStorage.getItem(USER_INFO) ? JSON.parse(localStorage.getItem(USER_INFO)) : null

const setUser = (user) => {
  localStorage.setItem(USER_INFO, JSON.stringify(user))
}

const redirect = (query, role) => {
  const { redirect } = queryString.parse(query)
  
  if (redirect){
    window.location.href = redirect
    return true
  }

  if (!role){
    window.location.href = '/'
    return;
  }

  window.location.href = redirectTo[role]
}

const login = (token, user) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_INFO, JSON.stringify(user));
};

const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(USER_INFO)
}

export { 
  TOKEN_KEY,
  USER_INFO,
  AllowedRoles,
  redirectTo,
  isAuthenticated,
  isAuthorized,
  hasDashboard,
  getToken,
  getUser,
  setUser,
  redirect,
  login,
  logout
}