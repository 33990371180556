import req from 'axios'
import { getToken } from '../services/auth'

const apiURL = process.env.REACT_APP_API_URL

export const axios = req.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `Bearer ${getToken()}`,
    },
    withCredentials: true
});

export const formData = req.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json, text/plain, */*',
        'Authorization': `Bearer ${getToken()}`
    },
    withCredentials: true
});

export default axios