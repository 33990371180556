import React, {useEffect, useState} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../../layouts/default'
import { GET_USER_SUBSCRIPTIONS_MATERIALS } from '../../../graphql/queries/users';
import Collapse from '../../../components/common/Collapse'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { DateTime } from 'luxon'
import Container from 'react-bootstrap/Container'
import { useLazyQuery,  } from '@apollo/client'
import { BsChevronRight } from 'react-icons/bs'
import { stripHtml } from '../../../util/utils'
import { Link } from 'react-router-dom'
const server = process.env.REACT_APP_API_URL || null

const SubscriptionMaterials = () => {
	const [loading, setLoading] = useState(true)
	const [hasAnyAditionalContents, setHasAnyAditionalContents] = useState(true)

  const [getSubscriptionAditionalMaterials, { loading: loadingData, data }] = 
    useLazyQuery(GET_USER_SUBSCRIPTIONS_MATERIALS, {
      variables: {},
      fetchPolicy: 'network-only'
    });

	useEffect(() => {
    document.title = `Materiais de associações - Alissem`
    document.getElementsByTagName("META")[1].content=document.title
		setLoading(true)
		getSubscriptionAditionalMaterials()
	}, [])

  useEffect(() => {
    setLoading(loadingData)
  }, [loadingData])

	useEffect(() => {

		if (data) {
			setHasAnyAditionalContents(
				data?.user?.companies?.edges.some((value) => value.subscriptionAditionalContents)
			)
		}
	}, [data])

	return (
		<LayoutDefault loading={loading}>
			{data?.user?.companies?.edges?.length > 0 && hasAnyAditionalContents ? (
				<div className={css['pg-aditional-contents']}>
					<Container>
						<Row>
							<Col md={6} className={css['cp-aditional-contents']}>
								<div className={css['cp-aditional-contents__content']}>
									<div className={css['cp-aditional-contents__content__title']}>
										<h2>Meus Materiais de Associado</h2>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
					<div className={css['cp-aditional-contents']}>
						<Container >
							{data.user?.companies?.edges?.map((company) =>
								company.subscriptionAditionalContents && company.subscriptionAditionalContents.length > 0 ? (
									<Row className={css["cp-aditional-content-container"]}>
										<Col>
											<Collapse title={company.name} startOpen={true}>
												<ul className={css["cp-aditional-content"]}>
													{company.subscriptionAditionalContents?.map(aditionalContent => {
														let blocked = new Date(aditionalContent.date) > new Date()
														return (
															<li key={aditionalContent.id} className={`${css["aditional-content__item"]} ${css["blocked"]}`}>
																{ blocked ? 
																	<p>
																		{aditionalContent.name} 
																		<span className={`${css["aditional-content__item__unblock"]}`}>
																			Libera em {DateTime.fromISO(aditionalContent.date).toFormat("dd/MM/yyyy 'às' HH:mm")}
																		</span>
																	</p> :
																	<Link to={`/complementar/associacao/${aditionalContent.id}`}>
																		{aditionalContent.name} 
																	</Link>
																}
																<BsChevronRight />
															</li>
														)
													})}
												</ul>
											</Collapse>
										</Col>
									</Row>
								) : null
							)}
						</Container>
					</div>
				</div>				
			) : (
        <div className={css['pg-aditional-contents--nothing']}>
          <p>Não há materiais do associado disponíveis para você.</p>
					<p>Verifique se sua anuidade está ativa acessando a página da sua associação e verificando o status que aparece no topo da tela.</p>
					<p>OU</p>
					<p>Certifique-se de que a associação da qual faz parte tenha compartilhado materiais aos associados.</p>

					<p>OBS: Caso esteja procurando material complementar de algum evento, acesse a página do evento.</p>

					<Link className={css["pg-aditional-contents--nothing-button"]} to="/meus-cursos">Ir para meus eventos</Link>
        </div>
      )}
		</LayoutDefault>
	)
}

export default SubscriptionMaterials