import React, {useMemo, useEffect, useState} from 'react'
import { useParams, Link, useHistory } from "react-router-dom"
import { NotificationManager } from 'react-notifications'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import DashboradLayout from '../../../../layouts/dashboard'
import { useLazyQuery } from '@apollo/client'
import { GET_USER_BASIC_PROFILE, GET_USER_PROFILE_COMPANY } from '../../../../graphql/queries/users'
import { addAdmin, removeAdmin, removeCompany } from '../../../../http/user'
import { getUser } from '../../../../services/auth'
import AddToCompany from '../AddToCompany'
import ChangePassword from '../ChangePassword'
import ChangeUserData from '../ChangeUserData'
import DeleteModal from '../DeleteModal'
import css from './styles.module.scss'
import UserInfoCourse from '../../../../components/UserInfo/Courses'
import UserInfoAssociation from '../../../../components/UserInfo/Association'
import CompanyRequirementRead from '../../../../components/common/Metadata/companyRequirementRead'

const UserInfo = () => {
  const params = useParams()
  const history = useHistory()
  const currentUser = getUser()
  const companyId = params.companyId || getUser()?.companyId || getUser().companyId

  const [getUserProfile, { loading: loadingData, data: userData }] = 
    useLazyQuery(GET_USER_BASIC_PROFILE, {
      variables: {
        id: Number(params.userId),
        ...( companyId ? { companyId } : {})
      },
      fetchPolicy: 'network-only'
    });
  const [getUserProfileByCompany, { loading: loadingDataCompany, data: userCompanyData }] = 
    useLazyQuery(GET_USER_PROFILE_COMPANY, {
      fetchPolicy: 'network-only'
    });
  

	if (!params.userId)
		history.replace('/usuarios')
  const userId = params.userId

  const [loading, setLoading] = useState(false)
  const [openAddCompany, setOpenAddCompany] = useState(null)
  const [openChangePassword, setOpenChangePassword] = useState(null)
  const [openChangeUserData, setOpenChangeUserData] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(null)
  const [currentCompany, setCurrentCompany] = useState(null)

  const breadcrumbs = [
    {name: 'Início', url: '/dashboard'},
    {name: "Usuários", url: `/dashboard/usuarios`},
    {name: (userData?.user?.name ? userData?.user?.name?.substring(0, 15) : null), url: `/dashboard/usuario/${userId}`}
  ]

  useEffect(() => {
    document.title = `Meu perfil - Alissem`
    document.getElementsByTagName("META")[1].content=document.title

    getUserProfile()
  }, [])

  useEffect(() => {
    setLoading(loadingDataCompany)
  }, [loadingDataCompany])

  useEffect(() => {
    if(currentCompany)
      loadCompanyData()
  }, [currentCompany])

  const handleAddAdmin = async () => {
    setLoading(true)
    try{
      await addAdmin(userId)
      getUserProfile()
      NotificationManager.success('O usuário agora tem permissão de administrador')
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  const handleRemoveAdmin = async () => {
    setLoading(true)
    try{
      await removeAdmin(userId)
      getUserProfile()
      NotificationManager.success('O usuário não tem mais permissão de administrador')
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  const handleAddCompany = async () => {
    setLoading(true)
    try{
      setOpenAddCompany(true)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  const handleRemoveCompany = async () => {
    setLoading(true)
    try{
      await removeCompany(userId)
      getUserProfile()
      NotificationManager.success('O usuário não pertence a empresa')
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  const handleChangePassword = async () => {
    setLoading(true)
    try{
      setOpenChangePassword(true)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  const handleChangeUserData = async () => {
    setLoading(true)
    try{
      setOpenChangeUserData(true)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
    setLoading(false)
  }

  const changeCompany = async (e) => {
    if (e.target.value !== '') {
      setCurrentCompany(e.target.value)
      return
    }
    setCurrentCompany(null)
  }


  const loadCompanyData = async () => {
    getUserProfileByCompany({
      variables: {
        id: Number(params.userId),
        companyId: Number(currentCompany),
      }
    })
  }

  return (
    <DashboradLayout loading={loadingData || loadingDataCompany || loading} breadcrumbs={breadcrumbs}>
      {!loadingData && userData?.user && (
        <div className={css["pg-user"]}>
          {currentUser.role === 'admin' ? (
            <div className={css["pg-user__actions"]}>
              <Button onClick={handleChangePassword}>Alterar senha</Button>
              <Button onClick={handleChangeUserData}>Alterar dados</Button>
              {userData.user.role !== 'admin' && userData.user.role !== 'company' && 
                <Button onClick={handleAddCompany}>Adicionar à empresa</Button>
              }
              {userData.user.role !== 'admin' && 
                <Button onClick={handleAddAdmin}>Tornar admin</Button>
              }
              {userData.user.id !== 1 && userData.user.role === 'admin' && 
                <Button onClick={handleRemoveAdmin} variant="danger">Remover admin</Button>
              }
              {userData.user.role === 'company' && 
                <Button onClick={handleRemoveCompany} variant="danger">Remover da empresa</Button>
              }
              <Button 
                variant="danger"
                onClick={() => setOpenDeleteModal(true)}
              >
                Deletar usuário
              </Button>
              
            </div>) : null}
          <div className={css["pg-user__infos"]}>
            <h1>Dados do usuário</h1>
            <Row>
              <Col xs={6}>
                <p className={css["pg-user__info__title"]}>Nome</p>
                <p className={css["pg-user__info__data"]}>{userData.user.name || "Dado não informado"}</p>
              </Col>
              {userData.user.isForeign ? 
                <Col xs={6}>
                  <p className={css["pg-user__info__title"]}>Documento estrangeiro</p>
                  <p className={css["pg-user__info__data"]}>{userData.user.foreignDocument || "Dado não informado"}</p>
                </Col>
              : 
                <Col xs={6}>
                  <p className={css["pg-user__info__title"]}>CPF</p>
                  <p className={css["pg-user__info__data"]}>{userData.user.cpf || "Dado não informado"}</p>
                </Col>
              }
              <Col xs={6}>
                <p className={css["pg-user__info__title"]}>E-mail</p>
                <p className={css["pg-user__info__data"]}>{userData.user.email || "Dado não informado"}</p>
              </Col>
              <Col xs={6}>
                <p className={css["pg-user__info__title"]}>Celular</p>
                <p className={css["pg-user__info__data"]}>{userData.user.cellphone || "Dado não informado"}</p>
              </Col>
              <Col xs={6}>
                <p className={css["pg-user__info__title"]}>Data de nascimento</p>
                <p className={css["pg-user__info__data"]}>{userData.user.birthDate || "Dado não informado"}</p>
              </Col>
              <Col xs={6}>
                <p className={css["pg-user__info__title"]}>Endereço</p>
                {userData.user.address ? 
                  <p className={css["pg-user__info__data"]}> 
                    {`${userData.user.address}, ${userData.user.addressNumber}${userData.user.addressComplement ? `, ${userData.user.addressComplement}` : '' } - ${userData.user.addressNeighborhood} - `}
                    {`${userData.user.cep} - ${userData.user.city}/${userData.user.isForeign ? userData.user.country : userData.user.state}`}
                  </p> : 
                  <p className={css["pg-user__info__data"]}> 
                    Dado não encontrado
                  </p>
                }
              </Col>
              {userData.user.role === 'company' && userData?.user?.managedCompany &&
                <Col sm={6}>
                  <p className={css["pg-user__info__title"]}>Administrador da empresa</p>
                  <p className={css["pg-user__info__data"]}> 
                    {`${userData.user.managedCompany?.name}`}
                  </p>
                </Col>
              }
            </Row>
          </div>
          { userData?.user?.companies?.totalCount > 0 ? (
          <Row>
            <Col sm={12} className={css["pg-user__companySelector"]}>
              Dados relacionados a empresa:&nbsp;
              <select onChange={changeCompany}>
                <option value=''>Selecionar empresa</option>
                {userData?.user?.companies?.edges?.map((company) => (
                  <option value={company.id}>{company.name}</option>
                )) }
              </select>
            </Col>
          </Row>
          ) : null }
          {currentCompany ?
            <div>
              <Row>
                {userCompanyData?.user?.companies?.edges?.[0].enrolls?.edges?.length ? (
                  <Col sm={6}>
                    <UserInfoCourse 
                      enrolls={userCompanyData?.user?.companies?.edges?.[0].enrolls?.edges || []}
                      company={{ 
                        name: userCompanyData?.user?.companies?.edges?.[0].name
                      }}
                    >
                    </UserInfoCourse>
                  </Col>) : null }
                {userCompanyData?.user?.companies?.edges?.[0].subscriptions?.edges?.length ? (
                  <Col sm={6}>
                    <UserInfoAssociation 
                      subscriptions={userCompanyData?.user?.companies?.edges?.[0].subscriptions?.edges || []}
                      company={{ 
                        name: userCompanyData?.user?.companies?.edges?.[0].name,
                        id: userCompanyData?.user?.companies?.edges?.[0].id,
                        canceled_recurrence: userCompanyData?.user?.companies?.edges?.[0].canceled_recurrence,
                        logo: userCompanyData?.user?.companies?.edges?.[0].logo
                      }}
                      setLoading={setLoading}
                      editable={currentUser.role === 'admin'}
                      closeModal={() => loadCompanyData()}
                    >
                    </UserInfoAssociation>
                  </Col>) : null }
              </Row>
              {
                userCompanyData?.user?.companies?.edges?.[0] ? 
                <div className={css["pg-user__requirements"]}>
                  <h1>Informação adicionais da empresa</h1>
                  <Row>
                    <Col sm={12}>
                      <CompanyRequirementRead
                        company={userCompanyData?.user?.companies?.edges?.[0] || {}}
                        onSave={() => {}}
                      />
                    </Col>
                  </Row>
                </div> : null
              }
            </div>
          : null}
        </div>
      )}
      <AddToCompany 
        show={openAddCompany}
        handleClose={() => setOpenAddCompany(false)}
        setLoading={setLoading}
        userId={userId}
        callback={() => getUserProfile() }
      />
      <ChangePassword 
        show={openChangePassword}
        handleClose={() => setOpenChangePassword(false)}
        setLoading={setLoading}
        userId={userId}
        callback={() => getUserProfile() }
      />
      <ChangeUserData
        show={openChangeUserData}
        handleClose={() => setOpenChangeUserData(false)}
        setLoading={setLoading}
        userId={userId}
        userData={userData}
        callback={() => getUserProfile() }
      />
      <DeleteModal
        show={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        setLoading={setLoading}
        userId={userId}
        callback={() => getUserProfile() }
      />
    </DashboradLayout>
  )
}

export default UserInfo