import React, {useEffect, useState, useRef} from 'react'
import css from './styles.module.scss'
import LayoutDefault from '../../layouts/default'
import { useParams } from "react-router-dom"
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { getCoursePublic } from '../../http/course'
import { isAuthenticated } from '../../services/auth'
import PriceList from '../../components/PriceList'
import ModalCheckout from '../../components/Modal/Checkout'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import Day from '../../components/Schedule/Day'
import Partnerships from '../../components/Partnerships'
import { generateSession } from '../../http/payment'
import CourseContent from '../../components/Course/Content'
import AditionalContent from '../../assets/aditionalContent.png'
import Lessons from '../../assets/lessons.png'
import Certificate from '../../assets/certificate.png'
import CourseHeader from '../../components/Course/Header'
const server = process.env.REACT_APP_API_URL || null

const Course = () => {
  const [course, setCourse ] = useState(null)
  const [loading, setLoading ] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [sessionId, setSessionId] = useState(false)
  const [selectedVoucher, setSelectedVoucher] = useState(null)
  const params = useParams()
  if (!params.courseId)
    window.href = '/'

  const courseId = params.courseId

  const fetchCourse = () => {
    getCoursePublic(courseId).then(e => {
      setCourse(e)
      document.title = `${e.name} - Alissem`
      document.getElementsByTagName("META")[1].content=document.title
      setLoading(false)
    }).catch(e => {
      NotificationManager.error("Ocorreu um erro ao buscar o curso. Tente novamente")
    })
  }

  const getSession = async () => {
    const { session } = await generateSession({companyId: course.company.id})
    setSessionId(session.id)
  }

  const aboutRef = useRef(null)
  const pricesRef = useRef(null)
  const scheduleRef = useRef(null)
  const partnershipsRef = useRef(null)
  const lessonsRef = useRef(null)

  useEffect(() => {
    fetchCourse()
  }, [])

  const enrollMe = async (value, product, documents) => {
    if (isAuthenticated()){
      setLoading(true)
      if (course.company?.paymentCompanyGateways?.[0].paymentGatewayId === 1) {
        await getSession()
      }
      setModalOpen(true)
      setLoading(false)
    }else{
      window.location.href = `/login?redirect=/curso/${course.company.permalink}/${courseId}`
    }
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  const executeScroll = (ref) => {
    if (!ref || !ref.current) {
      return false
    }
    ref.current.scrollIntoView({behavior: 'smooth'})
  }

  const showPrices = course && (
    !course.enrollStatus ||
    (["in_dispute", "refunded", "canceled", "waiting_payment", "analysing", "failed"]).indexOf(course.enrollStatus) > -1
  )

  return (
    <LayoutDefault loading={loading} openTicket={true} companyId={course?.companyId} >
      {!loading && course && (
        <div className={css['pg-course']}>
          <div className={css['pg-course__menu']}>
            <Container>
              <ul>
                <li onClick={() => executeScroll(aboutRef)}>Sobre o evento</li>
                {showPrices && course.open ? <li onClick={() => executeScroll(pricesRef)}>Valores</li> : null }
                {course.speakers?.length ? 
                  <li onClick={() => executeScroll(scheduleRef)}>Programação</li> : null }
                {course.certificate || course.lessons?.length || course.aditionalContent?.length ? 
                  <li onClick={() => executeScroll(lessonsRef)}>Aulas</li> : null} 
                {course.partnerships?.length ?
                  <li onClick={() => executeScroll(partnershipsRef)}>Patrocinadores</li> : null }
              </ul>
            </Container>
          </div>
          <CourseHeader course={course}/>
          <div className={css['pg-course']}>

          {(["waiting_payment", "analysing"]).indexOf(course.enrollStatus) > -1 ?
            <div className={css['pg-course__alert']}>
              <Container>
                Você tem um pagamento pendente ou aguardando aprovação. <br/>
                Aguarde até a confirmação para ter acesso ao conteúdo ou faça uma nova compra.
                { course.paymentLink ?
                  <a href={course.paymentLink} target="_blank" rel="noreferrer" >
                    Ver Boleto
                  </a>: null 
                }
              </Container>
            </div>
            : null
          }
          { showPrices && course.open ? (
            <>
              <h1 className={css['pg-course__price-title']} >Valores</h1>
              <Container ref={pricesRef}>
                <Row>
                  <Col md={12}>
                    <PriceList 
                      course={course}
                      productId={course.id}
                      prices={course.vouchers}
                      normalPrice={course.price}
                      buyable={course.buyable}
                      priceName={course.priceName}
                      setPrice={setSelectedVoucher}
                      handleClick={enrollMe}
                    />
                  </Col>
                </Row>
                {course.onlyRecorrent ? <p>*Evento somente para associados da {course.company.name}</p>: null}
              </Container>
              
            </>
          ) : !course?.open && (
            <div className={css['pg-course__contents']}>
              <p className={css['pg-course__contents__alert']}>
                Incrições encerradas
              </p>
            </div>
          )}
          {["paid", "disponible"].indexOf(course.enrollStatus) > -1 ? 
              <div className={css['pg-course__contents']}>
                {['pending', 'denied'].indexOf(course.documentApproved) > -1 ? 
                  <div>
                    {course.documentApproved === 'pending' &&
                      <p className={css['pg-course__contents__alert']}>
                        O seu documento ainda não foi aprovado. Aguarde liberação ou entre em contato.
                      </p>
                    }
                    {course.documentApproved === 'denied' &&
                      <p className={css['pg-course__contents__error']}>
                        O seu documento não foi aprovado. Entraremos em contato.
                      </p>
                    }
                  </div> :
                  <div>
                    <p className={css['pg-course__contents__buyed']}>Você já está inscrito neste evento!</p>
                    <h2>Meus conteúdos</h2>
                    
                    <Container ref={pricesRef}>
                    { !course.lessons && !course.aditionalContent && !course.certificate ? 
                      <p className={css['pg-course__contents__empty']}>Não há conteúdos disponíveis</p>
                      : null
                    }
                    <div className={css['pg-couse__contents']}  ref={lessonsRef}>
                      {course.lessons ? 
                        <CourseContent
                          image={Lessons}
                          text="Clique aqui e acesse as aulas já realizadas e links de acesso às próximas aulas"
                          button={{ text: "Acesso às aulas", link: `/curso/${course.company.permalink}/${course.id}/conteudo`}}
                        /> : null 
                      }
                      {course.aditionalContent ? 
                        <CourseContent
                          image={AditionalContent}
                          text="Clique aqui e acesse as aulas conteúdos adicionais disponibilizados"
                          button={{ text: "Conteúdos adicionais", link: `/curso/${course.company.permalink}/${course.id}/conteudo`}}
                        /> : null
                      }
                      { course.certificate ? 
                        <CourseContent
                          image={Certificate}
                          text="Emita aqui o seu certificado"
                          button={{ text: "Baixar Certificado", link: course.certificate, newTab: true}}
                        /> : null 
                      }
                    </div>
                  </Container>
                  </div> 
                }
              </div> : null
          }
          {course.speakers?.length ? 
            <div className={css["cp-schedule"]} ref={scheduleRef}>
              <span className={css["cp-schedule__decorator"]}></span>
              <Container>
                <h1>Programação</h1>
                { course.speakers.map((day, index) => <Day key={index} day={day} />) }
              </Container>
            </div>
          : null }
          {course.partnerships?.length ?
            <div ref={partnershipsRef}>
              <Partnerships partnerships={course.partnerships} />
            </div>
            : null
          }				
          <ModalCheckout
            show={modalOpen}
            paymentGateway = {course.company?.paymentCompanyGateways?.[0]}
            handleClose={closeModal}
            product={{
              id: course.id,
              name: course.name,
              logo: `${server}${course.logo}`,
              price: course.price,
              voucher: selectedVoucher,
              company: course.company,
              repeat: false,
              installments: (selectedVoucher?.id ? selectedVoucher.installments : course.installments),
            }}
            sessionId={sessionId}
            setSelectedVoucher={setSelectedVoucher}
          />
        </div>
        </div>
      )}
    </LayoutDefault>
  )
}

export default Course