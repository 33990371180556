import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import TableView from 'react-bootstrap/Table'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import {parse, format, isValid } from 'date-fns'
import { ptBR } from 'date-fns/locale';
import { 
  validateName,
  validateEmail, 
  validateCpf,
  validateBirthDate
} from '../../../../util/validate'
import { resendWelcomeEmail } from '../../../../http/enroll' 
import { importEnrolls } from '../../../../http/enroll' 
import './styles.module.scss'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null

export default function ModalImport({ show, handleClose, courseToImport, setLoading }) {
  const [columns, setColumns] = useState([])
  const [disableSend, setDisableSend] = useState(true)
  const [data, setData] = useState([])
  const [validatedData, setValidatedData] = useState([])
  const { handleSubmit, reset, formState: { errors }} = useForm()
  const [emailsNotSended,setEmailsNotSended] = useState([])

  const formatDate = (date) => {
    if (/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/.test(date))
      return date
    
    if (/[0-9]{2}\/[0-9]{2}\/[0-9]{2}/.test(date)) {
      return format(parse(date, 'dd/MM/yy', new Date(), { locale: ptBR }), 'dd/MM/yyyy')
    }

    return null
  }

  const onSubmit = async (values) => {

    if (validatedData.some(({ errors }) => errors && Object.keys(errors).length)) {
      NotificationManager.error("Existem dados com erros")
      return
    }

    let dataToSend = data.map(user => ({
      name: user.nome,
      email: user.email,
      courseId: courseToImport, 
      cpf: user['cpf'] && user['cpf'] !== '' ? user['cpf'] : null,
      password: user['senha'] && user['senha'] !== '' ? user['senha'] : null,
      address: user['endereco'] && user['endereco'] !== '' ? user['endereco'] : null,
      addressNumber: user['numero_endereco'] && user['numero_endereco'] !== '' ? user['numero_endereco'] : null,
      addressNeighborhood: user['bairro'] && user['bairro'] !== '' ? user['bairro'] : null,
      cep: user['cep'] && user['cep'] !== '' ? user['cep'] : null,
      city: user['cidade'] && user['cidade'] !== '' ? user['cidade'] : null,
      state: user['estado'] && user['estado'] !== '' ? user['estado'] : null,
      cellphone: user['telefone'] && user['telefone'] !== '' ? user['telefone'] : null,
      addressComplement: user['complemento'] && user['complemento'] !== '' ? user['complemento'] : null,
      birthDate: user['data_nascimento'] && user['data_nascimento'] !== '' ? user['data_nascimento'] : null,
      includeEmptyDocument: user['adicionar_documento_vazio']?.toUpperCase().replace(/\s+/g, '') === 'SIM',
      voucherId: user['voucher_id'] && user['voucher_id'] !== '' ? user['voucher_id'] : null,
    }))

    setLoading(true)
    importEnrolls({
      users: dataToSend
    }).then(e => {
      NotificationManager.success('Importação realizada com sucesso')
      close()
    }).catch((e) => {
      let description = 'Ocorreu um erro'
      if (e.response.status === 400) {
        description = e.response?.data?.error?.message
      }
      if (e.response.status === 406) {
        description = 'Ocorreu um erro ao enviar o email de alguns usuários'
        setEmailsNotSended(e.response?.data?.error || [])
      }
      NotificationManager.error(description)
    }).finally(e=>{
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    setEmailsNotSended([])
    setData([])
    setValidatedData([])
    handleClose()
  }

  const processData = dataString => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j].trim();
          if (d.length > 0) {
            if (d[0] === '"')
              d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"')
              d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }
        if (Object.values(obj).filter(x => x).length > 0) {
          list.push(obj);
        }
      }
    }
    setDisableSend(list.length === 0)
    if (list.length === 0) {
      NotificationManager.error("Arquivo vazio ou com formato inválido")
    }

    const columns = headers.map(c => ({
      name: c,
      selector: c,
    }));
    setData(list);
    setColumns(columns);
  }

  const handleFileUpload = e => {
    setLoading(true)
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (evt) => {
      processData(reader.result.replace(/;/g, ","))
    };
    reader.readAsText(file)
    setLoading(false)
  }

  const onInputClick = (event) => {
    event.target.value = ''
    setData([])
  }

  const validateRequiredFields = (data) => {
    const finalData = {...data}
    const errors = {}
    const requiredFields = ['nome', 'email']
    for (let field of requiredFields) {
      if (!data[field]){
        errors[field] = `O campo ${field} é obrigatório`
      }
    } 

    if (data['email'] && !validateEmail(data['email'])) {
      errors['email'] = `Email inválido`
    }

    if (data['nome'] && !validateName(data['nome'])) {
      errors['nome'] = `Nome completo inválido`
    }

    if (data['data_nascimento'] && !validateBirthDate(data['data_nascimento'])) {
      errors['data_nascimento'] = `Data de nascimento inválida`
    } 

    if (data['cpf'] && !validateCpf(data['cpf'])) {
      errors['cpf'] = `CPF inválido`
    }

    finalData.errors = errors
    return finalData

  }

  const onResendClick = () => {
    setLoading(true)
    resendWelcomeEmail({
      emails: emailsNotSended.map(({emailId}) => emailId)
    }).then(e => {
      NotificationManager.success('Emails enviados com sucesso')
      close()
    }).catch((e) => {
      let description = 'Ocorreu um erro'
      if (e.response.status === 400) {
        description = e.response?.data?.message
      }
      if (e.response.status === 406) {
        description = 'Ocorreu um erro ao enviar o email de alguns usuários'
        setEmailsNotSended(e.response?.data?.error || [])
      }
      NotificationManager.error(description)
    }).finally(e=>{
      setLoading(false)
    })
  }

  useEffect(() => {
    if (data.length){
      setValidatedData(data.map(element => validateRequiredFields(element)))
    }
  }, [data])

  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['modal-dialog']}
    >
      <Modal.Header closeButton>
        <Modal.Title>{emailsNotSended.length === 0 ? 'Importar inscritos' : 'Emails não enviados'}</Modal.Title>
      </Modal.Header>
      {emailsNotSended.length === 0 ? 
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            
            <Row>
              <Col xs={12} md={6}>
                Utilize o seguinte modelo para importar inscritos: <br />
                <a href={`${server}/defaultAssets/example_course.csv`} target="_blank" download="Example.csv" rel="noreferrer">Baixar modelo</a>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Selecione um arquivo CSV</Form.Label>
                  <Form.Control
                    type='file'
                    onChange={handleFileUpload}
                    onClick={onInputClick}
                    accept='.csv'
                  >
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
                </Form.Group> 
              </Col>
            </Row> 
            
            {validatedData.length ? (
              <Row>
                <TableView striped bordered hover responsive >
                  <thead>
                    <tr>
                      <th>Nome*</th>
                      <th>Email*</th>
                      <th>Data de nascimento</th>
                      <th>CPF</th>
                      <th>Senha</th>
                      <th>Endereço</th>
                      <th>Número</th>
                      <th>Bairro</th>
                      <th>CEP</th>
                      <th>Cidade</th>
                      <th>Estado</th>
                      <th>Telefone</th>
                      <th>Id do voucher</th>
                      <th>Criar documento vazio aprovado?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validatedData.map((row, i) => (
                      <tr key={i}>
                        <td className={row.errors && row.errors['nome'] ? css['error'] : null }>{row['nome']}</td>
                        <td className={row.errors && row.errors['email'] ? css['error'] : null }>{row['email']}</td>
                        <td className={row.errors && row.errors['data_nascimento'] ? css['error'] : null }>{row['data_nascimento']}</td>
                        <td className={row.errors && row.errors['cpf'] ? css['error'] : null }>{row['cpf']}</td>
                        <td className={row.errors && row.errors['senha'] ? css['error'] : null }>{row['senha']}</td>
                        <td className={row.errors && row.errors['endereco'] ? css['error'] : null }>{row['endereco']}</td>
                        <td className={row.errors && row.errors['numero_endereco'] ? css['error'] : null }>{row['numero_endereco']}</td>
                        <td className={row.errors && row.errors['bairro'] ? css['error'] : null }>{row['bairro']}</td>
                        <td className={row.errors && row.errors['cep'] ? css['error'] : null }>{row['cep']}</td>
                        <td className={row.errors && row.errors['cidade'] ? css['error'] : null }>{row['cidade']}</td>
                        <td className={row.errors && row.errors['estado'] ? css['error'] : null }>{row['estado']}</td>
                        <td className={row.errors && row.errors['telefone'] ? css['error'] : null }>{row['telefone']}</td>
                        <td className={row.errors && row.errors['voucher_id'] ? css['error'] : null }>{row['voucher_id']}</td>
                        <td className={row.errors && row.errors['adicionar_documento_vazio'] ? css['error'] : null }>{row['adicionar_documento_vazio']}</td>
                      </tr>
                    ))}
                  </tbody>
                </TableView>
              </Row>
            ) : null}
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Fechar
            </Button>
            <Button 
              type="submit" 
              variant="success"
              disabled={disableSend || validatedData.some(({ errors }) => errors && Object.keys(errors).length)}
            >
              Importar dados
            </Button>
          </Modal.Footer>
        </Form> 
      :
      <div>
        <Modal.Body>
          <div className={css['email-errors']}>
            {emailsNotSended.map(({email}) => 
              <p className={css['email-error']}>{email}</p>
            )}
          </div>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Fechar
            </Button>
            <Button 
              type="submit" 
              variant="success"
              onClick={onResendClick}
            >
              Reenviar email de cadastro
            </Button>
          </Modal.Footer>
        </div>
      }
    </Modal>
  )
}
