import { gql } from '@apollo/client'

export const GET_COMPANY_REQUIREMENTS = gql`
query frontGetCompanyRequirements ($companyId: Int){
  company(id: $companyId) {
    requirements {
      id
      title
    }
  }
}
`