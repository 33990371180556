import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import css from './styles.module.scss'
const server = process.env.REACT_APP_API_URL || null

const Partnerships = ({ partnerships }) => {
  const addScheme = (url) => 
    !/^(?:f|ht)tps?\:\/\//.test(url) ? "http://" + url : url

  return (
    <div>
      <Container fluid className={css["cp-partnership-title"]}>
        <h1>Patrocinadores:</h1>
      </Container>
      <Container fluid className={css["cp-partnership"]}>
        <Container className={css["cp-partnership"]}>
          <Row>
            {partnerships.map(partnership => (
              <Col xs={12} sm={6} md={3} key={partnership.id} className={css['cp-partnership']}>
                <a href={partnership.link ? addScheme(partnership.link ) : "#"} target={partnership.link ? "_blank" : ''} rel="noreferrer">
                  <div className={css['cp-partnership__logo']}>
                    <img src={`${server}${partnership.logoUrl}`} alt={partnership.name} />
                  </div>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
    </div>
  )
}

export default Partnerships