import { axios, formData } from './api'

const getPlans = async ( params ) => {
  try {
    const plans =  await axios.get(`api/plans`, { params })
    return plans.data
  } catch (error) {
    return error;
  }
}

const getPlan = async ( id ) => {
  const plans =  await axios.get(`api/plan/${id}`)
  return plans.data
}

const getPlanPublic = async ( id ) => {
  const plans =  await axios.get(`public/plan/${id}`)
  return plans.data
}

const listPlanPublic = async ( id ) => {
  const plans =  await axios.get(`public/plans`)
  return plans.data
}

const savePlan = async ( data ) => {
  const plan =  await formData.post(`api/plans`, data)
  return plan.data
}

const updatePlan = async ( id, data ) => {
  const plan =  await formData.put(`api/plan/${id}`, data)
  return plan.data
}

const associateTeacher = async (id, data) => {
  const plan =  await axios.post(`api/plan/${id}/associate`, { data })
  return plan.data
}

const getMyPlans = async () => {
  const plans =  await axios.get(`api/plan/my-plans`)
  return plans.data
}

const getMyCerts = async () => {
  const plans =  await axios.get(`api/plan/my-certs`)
  return plans.data
}

const deletePlan = async ( id ) => {
  const plan =  await axios.delete(`api/plan/${id}`)
  return plan.data
}

export { 
  getPlans,
  deletePlan,
  savePlan, 
  updatePlan, 
  getPlan, 
  associateTeacher,
  getMyPlans,
  getMyCerts,
  getPlanPublic,
  listPlanPublic
}