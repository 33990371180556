import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useForm, Controller } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { saveAditional, updateAditional } from '../../../../http/subscriptionAditionalContent'
import css from './styles.module.scss'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import { TextField, Select, MenuItem, FormHelperText, FormControl, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import WYSIWYGEditor from '../../../../components/common/WYSIWYG'

const server = process.env.REACT_APP_API_URL || null
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: '8px 0',
    },
    '& .MuiFormControl-root': {
      margin: '8px 0',
    },
  },
}));

export default function ModalAditional({ show, handleClose, aditional, companyId, setLoading }) {
  const classes = useStyles()
  const [file, setFile] = useState(null)
  const [filePreview, setFilePreview] = useState(null)

  const {
    register, handleSubmit, setValue, getValues, reset, control, watch, formState: { errors },
  } = useForm()

  useEffect(() => {
    const fields = ['name', 'content', 'date', 'type']
    if (aditional.id) {
      fields.forEach((field) => setValue(field, aditional[field]))
      if (aditional.linkAttachment)
        setFilePreview(`${server}${aditional.linkAttachment}`)
      return
    }
    fields.forEach((field) => setValue(field, null))
  }, [aditional, setValue])

  const onSubmit = async (data) => {
    setLoading(true)
    let result = null
    
    var formData = new FormData()
    formData.append("name", data.name)
    formData.append("content", data.content)
    formData.append("type", data.type)
    formData.append("date", data.date)
    formData.append("companyId", companyId)
    if (['youtube', 'vimeo', 'text'].indexOf(data.type) === -1)
      formData.append("file", file)

    if (aditional.id) result = updateAditional(aditional.id, formData)
    else result = saveAditional(formData)

    result.then(e => {
      NotificationManager.success('O material complementar foi salvo com sucesso', 'Salvo com sucesso')
      close()
    }).catch((e) => {
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }).finally(e => {
      setLoading(false)
    })
  }

  const close = () => {
    reset()
    setFile(null)
    setFilePreview(null)
    handleClose()
  }

  const onFileChange = (event) => {
    setFilePreview(URL.createObjectURL(event.target.files[0]))
    setFile(event.target.files[0])
  }

  const validateLink = (value) => {
    if (watch('type') === 'youtube') {
      let expression = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/gi
      let regex = new RegExp(expression)
      
      if (!value.match(regex)) return false
      let video_id = value.split('v=')[1]
      let ampersandPosition = video_id ? video_id.indexOf('&') : -1

      if(ampersandPosition !== -1)
        video_id = video_id.substring(0, ampersandPosition)

      if (!video_id || video_id === '')
        return false
    }

    return true
  }
  
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
      dialogClassName={css['dialog']}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Modal.Header closeButton>
          <Modal.Title>{aditional.id ? 'Editar ' : 'Cadastrar '}material complementar</Modal.Title>
        </Modal.Header>
        <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ 
                required: { value: true, message: 'O nome da aula é obrigatório' },
                minLength: { value: 3, message: 'O nome deve ter pelo menos 3 caracteres' },
                maxLength: { value: 255, message: 'O nome não pode ser maior que 255 caracteres' },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Nome"
                  variant="outlined"
                  value={value || ''}
                  onChange={onChange}
                  fullWidth
                  error={errors.name}
                  helperText={errors.name?.message || ''}
                />
              )}
            />
            <Row>
              <Col md={6}>
               <Controller
                  name="date"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      label="Data de liberação"
                      value={value}
                      onChange={onChange}
                      onError={console.log}
                      format="dd/MM/yyyy HH:mm"
                      fullWidth={true}
                      inputVariant="outlined"
                      autoOk={true}                   
                      invalidDateMessage="Data inválida"
                      error={errors.date}
                      helperText={errors.date?.message || ''}
                    />
                  )}
                />
              </Col>
              <Col>
                <Controller
                  name="type"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'O tipo da aula é obrigatório' },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl 
                      className={classes.formControl} 
                      error={errors.type}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel htmlFor="type">Tipo de conteúdo</InputLabel>
                      <Select
                        label="Tipo de conteúdo"
                        value={value || ''}
                        fullWidth
                        onChange={onChange}
                        inputProps={{
                          id: 'type',
                        }}
                      >
                        <MenuItem value='youtube'>Youtube</MenuItem>
                        <MenuItem value='document'>Documento</MenuItem>
                        <MenuItem value='image'>Imagem</MenuItem>
                        <MenuItem value='vimeo'>Vimeo</MenuItem>
                        <MenuItem value='text'>Texto</MenuItem>
                      </Select>
                      <FormHelperText>{errors.type?.message || ''}</FormHelperText>
                    </FormControl>
                  )}
                />                
              </Col>
            </Row>
            {(/^youtube|vimeo$/gi).test(watch('type')) ?
              <Controller
                name="content"
                control={control}
                defaultValue=""
                rules={{ 
                  required: { value: true, message: 'O link da aula é obrigatório' },
                  minLength: { value: 3, message: 'O link deve ter pelo menos 3 caracteres' },
                  maxLength: { value: 255, message: 'O link não pode ser maior que 255 caracteres' },
                  validate: { value: validateLink }
                }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Link"
                    variant="outlined"
                    value={value || ''}
                    onChange={onChange}
                    fullWidth
                    error={errors.content}
                    helperText={errors.content?.message || (errors.content?.type === 'value' ? 'Link de youtube inválido' : '') }
                  />
                )}
              /> : (/^text$/gi).test(watch('type')) ? 
                <Controller
                  name="content"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { value: true, message: 'O conteúdo da aula é obrigatório' },
                  }}
                  render={({ field: { onChange, value } }) => (
                  <WYSIWYGEditor 
                    value={getValues('content')}
                    onChange={value => {
                      setValue('content', value)
                    }} 
                  />
                  )}
                /> 
              :
              watch('type') ?
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Anexar arquivo</Form.Label>
                <Controller
                  name="file"
                  control={control}
                  defaultValue=""
                  rules={{ 
                    required: { 
                      value: !filePreview && watch('type') !== 'youtube', 
                      message: 'O anexo da aula é obrigatório'
                    }
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Form.Control type="file"
                      accept={watch('type') === 'document' ? 
                        "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf" : 
                        "image/*"
                      }
                      onChange={value => {
                        onFileChange(value)
                        setValue('file', value)
                      }} 
                      isInvalid={errors.file}
                    />
                  )}
                />
                {filePreview && watch('type') === 'image' &&
                  <div className={css['cp-modal-aditional__preview']}>
                    <img src={ filePreview } alt="" />
                  </div>
                }
                <Form.Control.Feedback type="invalid">{errors.file?.message}</Form.Control.Feedback>
              </Form.Group>
              :null
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={close}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">{aditional.id ? 'Salvar' : 'Cadastrar'}</Button>
          </Modal.Footer>
        </Form>
      </MuiPickersUtilsProvider>
    </Modal>
  )
}
