import { axios, formData } from './api'

const getAditionals = async ( params ) => {
  try {
    const aditionals =  await axios.get(`api/subscription-aditionals`, { params })
    return aditionals.data
  } catch (error) {
    return error;
  }
}

const getAditional = async ( id ) => {
  const aditionals =  await axios.get(`api/subscription-aditional/${id}`)
  return aditionals
}

const saveAditional = async ( data ) => {
  const company =  await formData.post(`api/subscription-aditional`, data)
  return company.data
}

const updateAditional = async ( id, data ) => {
  const company =  await formData.put(`api/subscription-aditional/${id}`, data)
  return company.data
}

const deleteAditional = async ( id ) => {
  const partnership =  await axios.delete(`api/subscription-aditional/${id}`)
  return partnership.data
}

const generateViewReportData = async (data) => {
  const viewReport =  await axios.get(`api/subscription-aditional/${data.id}/viewReport`, {params: data})
  return viewReport.data
}

export { getAditionals, saveAditional, updateAditional, getAditional, deleteAditional, generateViewReportData }