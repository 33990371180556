/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react'
import DashboradLayout from '../../../../layouts/dashboard'
import Table from '../../../../components/common/Table'
import { getUsers } from '../../../../http/user'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import css from './styles.module.scss'
import { FaArrowRight } from "react-icons/fa"
import { getUser } from '../../../../services/auth'
import { useParams, Link } from "react-router-dom"
import { NotificationManager } from 'react-notifications'

const UserList = () => {
  const size = 10
  const [data, setData] = useState({rows: [], count: 0})
  const [searchable, setSearchable] = useState('')
  const [page, setPage] = useState(0)
	const [loading, setLoading] = useState(true)
  const params = useParams()
  const companyId = params.companyId || getUser()?.companyId || getUser().companyId

  const fetchUsers = async () => {
    try{
      const users = await getUsers({
        ...( searchable ? { searchable } : {}),
        ...(companyId ? { companyId } : {}),
        size,
        start: ( page || 0 ) * size
      })
      setData(users)
      setLoading(false)
    }catch(e){
      NotificationManager.error('Erro no servidor', 'Ocorreu um erro')
    }
  }

  useEffect(() => {
    document.title = "Usuários - Alissem"
    document.getElementsByTagName("META")[1].content=document.title
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [searchable, page])

  const columns = useMemo(
    () => [
      {
        Header: "Usuários",
        columns: [
          { Header: 'Id', accessor: 'id', sortType: 'basic' }, 
          { Header: 'Nome', accessor: 'name', sortType: 'basic' },
          { Header: 'Ações', 
            Cell: ({row}) => (
              <div>
                <Link to={`/dashboard/usuario/${row.original.id}`}>
                  <FaArrowRight />
                </Link>
              </div>
            )
          }
        ]
      }
    ], []
  );

  const breadcrumbs = [
    {name: 'Início', url: '/dashboard'},
    {name: "Usuários", url: `/dashboard/usuarios`}
  ]

  return (
    <DashboradLayout loading={loading} breadcrumbs={breadcrumbs}>
      <div className="pg-user">
        <Row className={css['pg-user__filter']}>
          <Col className={css['pg-user__filter__searchable']}>
            <Form.Control 
              type="text" 
              placeholder='Busque por nome ou CPF' 
              value={searchable} 
              onChange={e => setSearchable(e.target.value)}
            />
          </Col>
        </Row>
        <Table 
          columns={columns} 
          data={data} 
          fetch={fetchUsers} 
          size={size}
          className={css["pg-user__table"]}
          setPage={setPage}
        />
      </div>
    </DashboradLayout>
  )
}

export default UserList