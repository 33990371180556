import Container from 'react-bootstrap/Container'
import css from './styles.module.scss'
import ReactHtmlParser from 'react-html-parser'
import CountdownTimer from "react-component-countdown-timer"
import { Link, useHistory } from "react-router-dom"

const server = process.env.REACT_APP_API_URL || null

const CourseHeader = ({ course }) => {
  const { name, logo, company, informations, date } = course
  const history = useHistory()
  const now = (new Date()).getTime()
  const dateInTime = new Date(date).getTime()
  const secondsToEnd = date && now < dateInTime ? (dateInTime - now)/1000 : null

  return (
    <div className={css['cp-course-header']}>
      <h1>{name}</h1>
      <Container>
        <div className={css['cp-course-header__logo']}>
          <img src={`${server}${logo}`} alt={name} />
        </div>
        <div className={css['cp-course-header__info__description']}>
          <div className={css['cp-course-header__info__countdown']}>
            {secondsToEnd > 0 &&
              <CountdownTimer
                count={secondsToEnd}
                showTitle
                noPoints
                dayTitle='Dias'
                hourTitle='Horas'
                minuteTitle="Minutos"
                secondTitle="Segundos"
                backgroundColor="#808080"
                responsive
                labelSize={20}
                size={30}
                direction= 'right'
                onEnd={() => history.go(0)}
              />
            }
          </div>
          <p className={css['cp-course-header__info__offeredby']}>
            Oferecido por: <Link to={`/empresa/${company.permalink}`}>{company.name}</Link>
          </p>
          
          <h2>Sobre o Evento:</h2>
          {ReactHtmlParser(informations)}
        </div>
      </Container>
    </div>
  )
}

export default CourseHeader