import React, { useEffect } from 'react'
import CustomFieldRead from './customFieldRead'
import css from './styles.module.scss'

export default function CompanyRequirementRead({
  company,
}) {

  return company?.requirements?.edges?.map((requirement) => (
      <CustomFieldRead
        id={requirement.id}
        title={requirement.title}
        type={requirement.type}
        required={requirement.required}
        options={requirement.options}
        value={requirement.metadata?.value}
      />
  ))
}