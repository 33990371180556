import React, { useEffect } from 'react'
import CompanyPage from './pages/Company'
import CoursePage from './pages/Course'
import AuthPage from './pages/Auth'
import HomePage from './pages/Home'
import ValidatePage from './pages/Validate'
import Dashboard from './pages/Dashboard'
import CompanyList from './pages/Dashboard/Company/List'
import MyCoursesPage from './pages/UserArea/Course'
import SubscriptionMaterialsPage from './pages/UserArea/SubscriptionsMaterials'
import MyCertsPage from './pages/UserArea/Certificates'
import MyTicketsPage from './pages/UserArea/Tickets'
import MyProfilePage from './pages/UserArea/MyProfile'
import LessonPage from './pages/UserArea/Lesson'
import AditionalContentPage from './pages/UserArea/AditionalContent'
import SubscriptionAditionalContentPage from './pages/UserArea/SubscriptionAditionalContent'
import CourseList from './pages/Dashboard/Course/List'
import PlansList from './pages/Dashboard/Plan/List'
import SettingHome from './pages/Dashboard/Setting/Home'
import SubscribersList from './pages/Dashboard/Subscribers/List'
import LessonList from './pages/Dashboard/Lesson/List'
import AditionalContentList from './pages/Dashboard/AditionalContent/List'
import SubscriptionAditionalList from './pages/Dashboard/SubscriptionAditionalContent/List'
import TeacherList from './pages/Dashboard/Teacher/List'
import VoucherList from './pages/Dashboard/Voucher/List'
import UsersList from './pages/Dashboard/User/List'
import UserInfoPage from './pages/Dashboard/User/Infos'
import EnrolledList from './pages/Dashboard/Enrolled/List'
import SpeakersList from './pages/Dashboard/Speaker/List'
import TicketsList from './pages/Dashboard/Ticket/List'
import EmailsList from './pages/Dashboard/Emails/List'
import RecoverPassword from './pages/RecoverPassword'
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import PrivateRoute from './route/PrivateRoute'
import 'bootstrap/dist/css/bootstrap.min.css'
import { AllowedRoles } from './services/auth'
import PartnershipList from './pages/Dashboard/Partnership/List'
import CourseContent from './pages/Course/Content'
import MyOrdersPage from './pages/Orders/'
import Terms from './pages/Terms'
import ApolloProvider from './graphql/Provider'
import Analytics from 'react-router-ga'
		
const allRoles = [AllowedRoles.ADMIN, AllowedRoles.COMPANY, AllowedRoles.TEACHER]
const manager = [AllowedRoles.ADMIN, AllowedRoles.COMPANY]

const App = () => {

	return (
		<ApolloProvider>
			<BrowserRouter>
				<Analytics 
					id={process.env.REACT_APP_GA_TRACKING_ID} 
					debug={process.env.REACT_APP_GA_DEBUG}
				>
					<Switch>
						<Route path="/" exact component={HomePage} /> 
						<Route path="/login" exact component={AuthPage} /> 
						<Route path="/empresa/:companyId" exact component={CompanyPage} /> 
						<Route path="/curso/:companyPermaLink/:courseId" exact component={CoursePage} /> 
						<Route path="/validar-documento" exact component={ValidatePage} /> 
						<Route path="/recuperar" exact component={RecoverPassword} />
						<Route path="/termos" exact component={Terms} />

						<PrivateRoute path="/curso/:companyPermaLink/:courseId/conteudo" exact component={CourseContent} />

						<PrivateRoute path="/meus-cursos" exact component={MyCoursesPage} /> 
						<PrivateRoute path="/materiais-assinaturas" exact component={SubscriptionMaterialsPage} /> 
						<PrivateRoute path="/meus-pedidos" exact component={MyOrdersPage} /> 
						<PrivateRoute path="/meus-certificados" exact component={MyCertsPage} /> 
						<PrivateRoute path="/meus-tickets" exact component={MyTicketsPage} />
						<PrivateRoute path="/perfil" exact component={MyProfilePage} />
						<PrivateRoute path="/aula/:companyPermaLink/:lessonId" exact component={LessonPage} />
						<PrivateRoute path="/complementar/associacao/:aditionalContentId" exact component={SubscriptionAditionalContentPage} />
						<PrivateRoute path="/complementar/:companyPermaLink/:aditionalContentId" exact component={AditionalContentPage} />

						<PrivateRoute path="/dashboard/empresas" exact component={CompanyList} roles={[AllowedRoles.ADMIN]} />
						<PrivateRoute path="/dashboard/cursos" exact component={CourseList} roles={allRoles} />
						<PrivateRoute path="/dashboard/associacao/planos" exact component={PlansList} roles={manager} />
						<PrivateRoute path="/dashboard/configuracoes" exact component={SettingHome} roles={manager} />
						<PrivateRoute path="/dashboard/associacao/associados" exact component={SubscribersList} roles={manager} />
						<PrivateRoute path="/dashboard/associacao/complementar" exact component={SubscriptionAditionalList} roles={manager} />

						<PrivateRoute path="/dashboard/empresa/:companyId/curso/:courseId/aulas" exact component={LessonList} />
						<PrivateRoute path="/dashboard/empresa/:companyId/curso/:courseId/complementar" exact component={AditionalContentList} />
						<PrivateRoute path="/dashboard/empresa/:companyId/curso/:courseId/inscritos" exact component={EnrolledList} roles={allRoles} />

						<PrivateRoute path="/dashboard/empresa/:companyId/cursos" exact component={CourseList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/configuracoes" exact component={SettingHome} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/associacao/planos" exact component={PlansList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/associacao/associados" exact component={SubscribersList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/associacao/complementar" exact component={SubscriptionAditionalList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/curso/:courseId/professores" exact component={TeacherList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/curso/:courseId/vouchers" exact component={VoucherList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/curso/:courseId/parceiros" exact component={PartnershipList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/curso/:courseId/palestrantes" exact component={SpeakersList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/usuarios" exact component={UsersList} roles={manager} />
						<PrivateRoute path="/dashboard/empresa/:companyId/tickets" exact component={TicketsList} roles={manager} />
						<PrivateRoute path="/dashboard/tickets" exact component={TicketsList} roles={manager} />
						<PrivateRoute path="/dashboard/associacoes" exact component={PlansList} roles={manager} />
						<PrivateRoute path="/dashboard/emails" exact component={EmailsList} roles={[AllowedRoles.ADMIN]} />
						<PrivateRoute path="/dashboard/usuarios" exact component={UsersList} roles={manager} />
						<PrivateRoute path="/dashboard/usuario/:userId" exact component={UserInfoPage} roles={manager} />
						<PrivateRoute path="/dashboard" exact component={Dashboard} roles={[manager]} />
						
						<Route path="/:permalink" exact component={CompanyPage} /> 
						<Route render={() => <Redirect to="/" />} />
					</Switch>
				</Analytics>
			</BrowserRouter>
		</ApolloProvider>
	);
}

export default App
